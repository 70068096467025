import { getInstance } from "./index";
import store from "../store/index";
import axios from "axios";
export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = async () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      const token = await authService.getTokenSilently();
      const usertoken = parseJwt(token);
      localStorage.setItem("tokenInfo", JSON.stringify(usertoken))

      const res = await axios.get(`${process.env.VUE_APP_API}/category`);

      localStorage.setItem("themeChanger", JSON.stringify(res.data[0]));

      const role = usertoken.permissions[usertoken.permissions.length - 1].split(":")[1];
      let assignCountry;

      if (usertoken.permissions[0].split(":")[0] == "country") {
        assignCountry = usertoken.permissions[0].split(":")[1];
      } else {
        assignCountry = "null";
      }

      store.commit("setRole", role);
      store.commit("setCountry", assignCountry);

      const user = await authService.user;

      store.commit("setuserInfo", user);
      localStorage.setItem("user", JSON.stringify(user));
      // setTimeout(() => {
      //   if (user.email == "natasja@splintt.nl.dj") {
      //     window.location.reload();
      //   }
      // }, 50);
      return next();
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  const parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }
  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
