var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"my-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Organisations")])])],1),_c('v-card',[_c('v-row',{staticClass:"no-gutters"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"4"}},[_c('download-excel',{attrs:{"data":_vm.json_data,"fields":_vm.json_fields,"worksheet":"My Worksheet","name":"filename.xls"}},[_c('v-btn',{staticClass:"primary mr-4",on:{"click":function($event){return _vm.downloadFile()}}},[_vm._v(" Export ")])],1),_c('v-form',[_c('v-text-field',{staticClass:"serch-filed serch-bg-light",attrs:{"placeholder":"Search","elevation":_vm.n - 1,"solo":"","light":"","append-icon":"mdi-magnify","dense":"","height":"40","flat":"","hide-details":""}})],1)],1)],1)])],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"items":_vm.filterDataa,"headers":_vm.headers,"custom-filter":_vm.customFilter,"items-per-page":10,"options":_vm.options,"item-key":"name","footer-props":{
        prevIcon: 'chevron_left',
        nextIcon: 'chevron_right',
        'items-per-page-options': [10, 20, 30, 40, 50],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Account",fn:function(ref){
      var item = ref.item;
return [_c('strong',{staticClass:"pointer",on:{"click":function($event){return _vm.nameClicked(item)}}},[_vm._v(" "+_vm._s(item.Account)+" ")])]}},{key:"item.Tier",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"pointer ml-2",attrs:{"label":"","color":item.Tier === 'N/A' ? 'orange' : 'green',"dark":"","small":""}},[_c('span',[_vm._v(_vm._s(item.Tier))])])]}},{key:"item.ID_Veeva",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"light":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.viewDetails(item)}}},[_c('v-list-item-title',[_c('i',{staticClass:"bi bi-eye-fill mr-2"}),_vm._v(" View Details")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.nameClicked(item)}}},[_c('v-list-item-title',[_c('i',{staticClass:"bi bi-eye-fill mr-2"}),_vm._v(" View Profile")])],1),_c('v-list-item',[_c('v-list-item-title',[_c('i',{staticClass:"bi bi-trash mr-2"}),_vm._v(" Delete")])],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }