


































































































































































































































import Vue from "vue";
import usersData from "../../account-data.json";

export default Vue.extend({
  name: "PersonDetails2",
  data() {
    return {
      id: "",
      data: usersData,
      newarr: {},
      n: "",
    };
  },
  mounted() {
    // console.log(this.data);
    this.idEva = this.$route.params;

    this.data.map((data) => {
      if (data.ID_Veeva == this.idEva.id) {
        return (this.newarr = data);
      }
    });
  },
});
