var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-3 mt-3"},[_c('v-data-table',{staticClass:"mx-2",attrs:{"items":_vm.items,"headers":_vm.headers,"item-key":"date","footer-props":{
      prevIcon: 'chevron_left',
      nextIcon: 'chevron_right',
    }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_c('v-dialog',{attrs:{"max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text text--darken-1",attrs:{"color":"gray","dark":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.attributes.import_start_time)+" ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.dialogHeaders,"items":_vm.dialogItems},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text text--darken-1",attrs:{"color":"gray","dark":"","to":"/accounts"}},'v-chip',_vm.attrs,false),_vm.on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)})],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Close")])],1)],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }