

























































































































































import { Individual } from "../classes/individual/Individual";
import Vue from "vue";
import store from "../store/index";

export default Vue.extend({
  name: "AccountsOverview",
  data() {
    return {
      n: 0,
      options: {},
    };
  },
  methods: {
    nameClicked(item) {
      this.$router.push("/details/" + item.id);
    },
  },
  computed: {
    headers() {
      return [
        { text: "Name", value: "attributes.first_name" },
        { text: "Organisation", value: "attributes.individual_status_code" },
        { text: "Country", value: "attributes.country_code" },
        {
          text: "Specialty 1",
          value: "attributes.individual_specialities[0].speciality_label",
        },
        {
          text: "Specialty 2",
          value: "attributes.individual_specialities[0].speciality_label",
        },
        {
          text: "Specialty 3",
          value: "attributes.individual_specialities[0].speciality_label",
        },
        { text: "TA", value: "attributes.professional_type_label" },
        { text: "Ranking", value: "attributes.rank" },
        {
          text: "Last Engagement",
          value: "attributes.email",
        },
        {
          text: "Next Engagement",
          value: "attributes.visit",
        },
        { text: "Date", value: "attributes.date_of_last_individual_update" },
        { text: "Action", value: "attributes.action" },
      ];
    },
    individuals(): Array<Individual> {
      return this.$store.getters.getIndividuals;
    },
    totalRecords(): number {
      return this.$store.getters.getTotalRecords;
    },
  },
  watch: {
    options: {
      handler() {
        store.dispatch("getIndividuals", {
          offset: (this.options.page - 1) * this.options.itemsPerPage,
          pageSize: this.options.itemsPerPage,
        });
      },
    },
  } /* 
  beforeRouteEnter(to, from, next) {
    store.dispatch("getIndividuals", 0)
    next()
  } */,
});
