







































import Vue from "vue";

export default Vue.extend({
  name: "JourneyBox",
  props: {
    data: Object,
    showDisplayType: Boolean,
    type: String,
  },
  data: () => ({
    showProfileCard: false,
  }),
  methods: {
    showEmailpopup(event) {
      this.$emit("showEmailpopup", event);
    },
    deleteJourny(event) {
      this.$emit("deleteJourny", event);
    },
  },
});
