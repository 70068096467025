<template>
    <div class="loginWrapper">
        <v-card class="pa-5 loginCard" width="400px">
            <h2 class="mb-8" v-if="isTenant">Create New Tenant</h2>
            <h2 class="mb-8" v-if="isLogin">Login</h2>
            <h2 class="mb-8" v-if="isLoginUser">Login</h2>

            <span v-if="isTenant">Sign Up</span>
            <v-text-field outlined class="mt-2" placeholder="Tenant Name" v-model="tenantName" required v-if="isTenant">
            </v-text-field>
            <v-text-field outlined class="mt-2" :placeholder="loginCheck ? 'Username' : 'Email'" v-model="email"
                required>
            </v-text-field>
            <v-text-field outlined class="mt-2" placeholder="Password" v-if="isLoginUser" v-model="password" required>
            </v-text-field>
            <v-btn rounded block class="mb-5" color="#0d60a5" v-if="isTenant" dark @click="goDashboard">
                SignUp
            </v-btn>
            <v-btn rounded block class="mb-5" color="#0d60a5" dark v-if="isLogin" @click="loginCheck">
                Login
            </v-btn>
            <v-btn rounded block class="mb-5" color="#0d60a5" dark v-if="isLoginUser" @click="userLoginCheck">
                login
            </v-btn>
            <!-- <p>By continuing, you agree to the DJ Technology Solutions Agreement or other agreement for DJ Technology
              Solutions, and the Privacy Notice. This site uses essential cookies. See our Cookie Notice for more
              information.</p> -->
            <h5 class="text-center pointer" @click="signUp" v-if="isLogin">Sign Up</h5>
            <h5 class="text-center pointer" @click="showLoginElement" v-if="isTenant">Login</h5>
            <h5 class="text-center pointer" @click="signUp" v-if="isLoginUser">Sign Up</h5>
            <p class="text-center" v-if="isLoginUser">if you are a Tenant <strong @click="showLoginElement"
                    class="pointer">Login</strong> </p>
            <p class="text-center" v-if="!isLoginUser">If you are a user <strong @click="userLogin"
                    class="pointer">Login</strong> </p>
        </v-card>
        <!-- <v-card class="pa-5 loginCard" width="400px" v-if="!formShow">
          <v-alert outlined type="warning" prominent> 
              Your Request submitted wait for admin approval
          </v-alert>
      </v-card> -->
        <v-alert v-if="alert" id="alert" type="success">Request submitted wait for admin approval</v-alert>
        <v-alert v-if="isApproved" id="alert" type="error">Your request not approved yet</v-alert>
        <v-alert v-if="isLoginSuccess" id="alert" type="error">Invalid credential</v-alert>
    </div>
</template>

<script>
import Vue from "vue";
import axios from 'axios';
export default Vue.extend({
    name: "NewCom",
    data() {
        return {
            login: false,
            tenantName: "",
            email: "",
            alert: false,
            isApproved: false,
            userFiled: false,
            password: "",
            isTenant: true,
            isLogin: false,
            isLoginUser: false,
            isLoginSuccess: false,
        };
    },
    methods: {
        showLoginElement() {
            this.isTenant = false;
            this.isLogin = true;
            this.isLoginUser = false;
        },
        async goDashboard() {
            if(this.tenantName !== '' && this.email !==''){
                const data = {
                    tenantName: this.tenantName,
                    email: this.email,
                    status: "Pending"
                }
                var res = await axios.post(`${process.env.VUE_APP_API}/reqtenant`, data);
    
                this.username = "";
                if (res.status == '200') {
                    this.alert = true;
                }
                setTimeout(() => {
                    this.alert = false;
                }, 5000);
            }
            this.tenantName = "";
            this.email = "";
        },
        async loginCheck() {
            var res = await axios.get(`${process.env.VUE_APP_API}/alltenant`);
            for (let item of res.data) {
                if (item.email == this.email && item.status == 'Approved') {
                    const person = {
                        tenant: item.email
                    }
                    localStorage.setItem(
                        "isTenant",
                        JSON.stringify(person)
                        );
                    this.$router.push('/tenantDashboard');
                    this.isApproved = true;
                    setTimeout(() => {
                        this.isApproved = false;
                    }, 50)
                }
                this.isApproved = true;
                setTimeout(() => {
                    this.isApproved = false;
                }, 4000)
            }
        },
        signUp() {
            this.isTenant = true;
            this.isLogin = false;
            this.isLoginUser = false;
        },
        async userLogin() {
            this.isLoginUser = true;
            this.isTenant = false;
            this.isLogin = false;

        },
        async userLoginCheck() {
            var res = await axios.get(`${process.env.VUE_APP_API}/getAllCreatedUser`);
            for (let item of res.data) {
                if (item.username === this.email && item.password === this.password) {

                    const person = {
                        username: this.email,
                        role: item.role,
                        password:item.password,
                        id:item._id
                    }
                    localStorage.setItem(
                        "loggedUser",
                        JSON.stringify(person)
                    );
                    this.$router.push('/userDashboard');
                    this.isLoginSuccess = true;
                    setTimeout(() => {
                        this.isLoginSuccess = false;
                    }, 50)
                }
                this.isLoginSuccess = true;
                setTimeout(() => {
                    this.isLoginSuccess = false;
                }, 4000)
            }
        }
    },
});
</script>