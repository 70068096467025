


































































































































































































































































































































































































import Vue from "vue";
import axios from "axios";
import moment from "moment";
import CreateTouchpointModal from "../components/CreateTouchpointPopup.vue";
import SwtichconnectionPopup from "../components/SwitchConnectionPopup.vue";
export default Vue.extend({
  name: "TouchPoint",
  components: {
    SwtichconnectionPopup,
    CreateTouchpointModal,
  },
  data() {
    return {
      jj: "FFFFFFF",
      active: false,
      dialog: false,
      n: "",
      toucpointTiles: [],
      AlltoucpointTiles: [],
      switchFilterValue: [],
      unique: "",
      show: false,
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
      showbranch: true,
      showbranchId: 0,
      showListId: 0,
      allFilteredJourneyName: [],
      filterJourney: [],
      journeyValue: "",
      openSwicthConnection: false,
      touchpointdata: "",
      switchValue: "",
      openCreationTouchpoint: false,
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      listOption: false,
    };
  },
  methods: {
    async openConnectionPopup(item) {
      this.listOption = false;
      this.openSwicthConnection = true;
      this.touchpointdata = item;
      var res = await axios.get(
        `${process.env.VUE_APP_API}/touchpointTiles/` + item._id
      );

      this.switchValue = res.data.data.isActivate;
    },
    async showBranchFiled(id) {
      this.showbranch = !this.showbranch;
      this.showbranchId = id;
      let response = await axios.get(
        `${process.env.VUE_APP_API}/journeyData/get-all`
      );
      this.allFilteredJourneyName = response.data;
      this.filterJourney = this.allFilteredJourneyName;
    },
    openOPtion(id) {
      this.listOption = !this.listOption;
      this.showListId = id;
    },
    toggleNav() {
      this.active = !this.active;
    },
    branchClicked(item) {
      this.$router.push(
        "/emailtouchpoint2?masterDataId=" +
          item.masterDataId +
          "&journeyName=" +
          item.journeyName
      );
    },
    getTouchpointTile() {
      axios
        .get(`${process.env.VUE_APP_API}/touchpointTiles`)
        .then((response) => {
          this.toucpointTiles = response.data;
          this.AlltoucpointTiles = response.data;
          this.activateSwitch();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    touchpointFilter(data) {
      this.toggleNav();
      if (data == "all") {
        this.switchFilterValue = this.toucpointTiles;
        return;
      }
      this.switchFilterValue = this.toucpointTiles.filter((action) => {
        return (
          action.touchpointName === data ||
          action.tag === data ||
          action.status === data
        );
      });
    },

    dynamicSort(property) {
      var sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a, b) {
        var result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    },
    touchpointSort() {
      this.toggleNav();
      this.switchFilterValue = this.toucpointTiles.sort(
        this.dynamicSort("touchpointName")
      );
    },
    statusSort() {
      this.toggleNav();
      this.switchFilterValue = this.toucpointTiles.sort(
        this.dynamicSort("status")
      );
    },
    dateShort() {
      this.toggleNav();
      this.show = true;
    },
    closeDate() {
      this.show = false;
      var date = this.picker.split("-");
      date = date.reverse();
      date = date.join(".");

      this.switchFilterValue = this.toucpointTiles.filter((action) => {
        return action.date === date;
      });
    },
    searchJourney() {
      this.filterJourney = this.allFilteredJourneyName;
      if (this.journeyValue)
        this.filterJourney = this.allFilteredJourneyName.filter((item) => {
          return item.journeyName
            .toLowerCase()
            .includes(this.journeyValue.toLowerCase());
        });
    },

    async activateSwitch() {
      var arrayData = JSON.parse(localStorage.getItem("themeChanger"));
      var pharma = arrayData.pharma;
      var travel = arrayData.travel;
      var finance = arrayData.finance;

      this.toucpointTiles = this.AlltoucpointTiles.filter((item) => {
        if (pharma && travel) {
          return item.category == "pharma" || item.category == "travel";
        } else if (pharma) {
          return item.category == "pharma";
        } else if (travel) {
          return item.category == "travel";
        }
      });
      this.switchFilterValue = this.toucpointTiles;
    },
    openCreateTouchpointModal() {
      this.openCreationTouchpoint = true;
    },
    format(date) {
      return moment(String(date)).format("DD/MM/YYYY");
    },
  },
  computed: {
    uniqueStatus() {
      return this.toucpointTiles.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.status]: current,
        });
      }, {});
    },
    uniqueTag() {
      return this.toucpointTiles.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.tag]: current,
        });
      }, {});
    },
    uniqueName() {
      return this.toucpointTiles.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.touchpointName]: current,
        });
      }, {});
    },
  },
  created() {
    this.getTouchpointTile();
  },
});
