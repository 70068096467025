<template>
  <div>

    <v-app class="main-body" v-if="info">
        <SideBar />
        <Header />
        <v-main>
          <router-view />
        </v-main>
    </v-app>

    <v-app class="main-body" v-else>
      <Sidebar2 v-if="showSidebar2"/>
        <v-main>
          <router-view />
        </v-main>
    </v-app>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import SideBar from '@/components/SideBar.vue';
import Sidebar2 from '@/components/Sidebar2.vue';

export default {
  name: 'App',
  components: {
    Header,
    SideBar,
    Sidebar2
  },
  data() {
    return {
      loggedIn: true,
      unsubscribeAuth: undefined,
      showSidebar2:false,
    };
  },
  methods: {
    onRouteChange(){
      let showUrl = ['/tenantDashboard','/adminDashboard'];
    let currentPageUrl = this.$route.path
    this.showSidebar2 = showUrl.includes(currentPageUrl)? true : false;
    }
    
  },
  computed: {
    user: {
      get() {
        return this.$store.state.organisation;
      },
      set(value) {
        this.$store.commit('setUser', value);
      },
    },
    info(){
      return this.$store.getters.getInfo;
    }
  },
  mounted(){
    this.onRouteChange()
  },
  watch:{
    $route (to, from){
      this.onRouteChange()
    }
} 
};


</script>

<style>
@import "./assets/css/style.css";
</style>