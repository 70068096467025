
























































































































































































































































import Vue from "vue";

import { ParentOption } from "../classes/person/ParentOption";
import { Accountowner } from "../classes/person/AccountOwner";
import { PreferredLanguageOption } from "../classes/person/LanguageOption";
import { Individual } from "@/classes/individual/Individual";

import store from "../store/index";
import { Workplace } from "@/classes/individual/Workplace";
import { Activity } from "@/classes/individual/Activity";

export default Vue.extend({
  name: "PersonDetails",
  data() {
    return {
      n: 0,
      items: [],
      primary: 0,
      id: "",
    };
  },
  computed: {
    languages(): string[] {
      return Object.values(PreferredLanguageOption);
    },
    parents(): string[] {
      return Object.values(ParentOption);
    },
    owners(): string[] {
      return Object.values(Accountowner);
    },
    person(): Individual {
      return this.$store.getters.getPersonDetails;
    },
    workplaces(): Workplace[] {
      return this.$store.getters.getWorkplaces;
    },
    activities(): Activity[] {
      return this.$store.getters.getActivities;
    },
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch("getWorkplaces", to.params.id);
    store.dispatch("getActivities", to.params.id);
    store.dispatch("getIndividual", to.params.id).then(() => {
      next();
    });
  },
});
