



















import Vue from "vue";
export default Vue.extend({
  name: "awareSwitch",
  data: function () {
    return {
      aware: false,
      trialist: true,
    };
  },
  methods: {
      chageValue(data){
          console.log(data);
          localStorage.setItem('getAwareData',data)
      }
  },
  computed: {},
});
