
























































import Vue from 'vue'
import { DataUpdate } from '@/classes/dataUpdates/DataUpdate'
import store from "../store/index";

export default Vue.extend({
  name: "DataUpdates",
  data() {
    return {
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'date',
        },
        { text: 'Run ID', value: 'id' },
        { text: 'Total records', value: 'attributes.records' },
        { text: 'New', value: 'attributes.inserts' },
        { text: 'Updated', value: 'attributes.updates' },
      ],
      dialogHeaders: [
        {
          text: 'Name',
          align: 'start',
          filterable: true,
          value: 'name',
        },
        { text: 'Organisation', value: 'Organisation' },
        { text: 'City', value: 'City' },
        { text: 'Country', value: 'Country' },
        { text: 'Specialty', value: 'Specialty' },
      ],
      dialogItems: [
        {
          name: 'Herman Reeves',
          Organisation: 'Schuman Health Care Center',
          City: 'London',
          Country: 'United Kingdom',
          Specialty: 'Internal Medicine',
        },
        {
          name: 'Cecelia Schmidt',
          Organisation: 'North General Hospital',
          City: 'Brussels',
          Country: 'Belgium',
          Specialty: 'Pediatrics',
        },
        {
          name: 'Irene Coleman',
          Organisation: 'Schuman Health Care Center',
          City: 'London',
          Country: 'United Kingdom',
          Specialty: 'Nephrogoly',
        },
        {
          name: 'Herman Reeves',
          Organisation: 'Schuman Health Care Center',
          City: 'London',
          Country: 'United Kingdom',
          Specialty: 'Internal Medicine',
        },
        {
          name: 'Cecelia Schmidt',
          Organisation: 'North General Hospital',
          City: 'Brussels',
          Country: 'Belgium',
          Specialty: 'Pediatrics',
        },
      ],
    };
  },
  computed: {
    items(): DataUpdate[] {
      return this.$store.getters.getDataUpdates
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch("getDataUpdates").then(() => { next() })
  }
})
