








































































import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  name: "SideBar",
  data() {
    return {
      menus: [],
      admins: [
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"],
      ],
      cruds: [
        ["Create", "mdi-plus-outline"],
        ["Read", "mdi-file-outline"],
        ["Update", "mdi-update"],
        ["Delete", "mdi-delete"],
      ],
    };
  },
  computed: {
    getRole(): any {
      return this.$store.getters.getRole;
    },
    getInfo(): any {
      return this.$store.getters.getInfo;
    },
    metaData(){
      let data = this.getInfo["https://engagementmanager.digital/user_authorization"]
      return data?.user_metadata?.user_type
    }
  },
  methods: {
    setRoute(route: string) {
      this.$router.push(route);
    },
    async getMenu() {
      var res = await axios.get(`${process.env.VUE_APP_API}/menus`);
      return (this.menus = res.data);
    },
  },
  created() {
    this.getMenu();
  },
});
