











import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  name: "DashboardViewPage",
  data() {
    return {
      allData: [],
    };
  },
  created() {
    this.getDashboard();
  },
  methods: {
    async getDashboard() {
      var name = this.$route.params.id;
      let res = await axios.get(`${process.env.VUE_APP_API}/dashboradUrl/get-all`);
      this.allData = res.data.filter((i) => {
        if (i.name === name) {
          return i;
        }
      });
    },
  },
});
