import { DataUpdate } from "@/classes/dataUpdates/DataUpdate";
import { apiV1 } from "./api";
import { DataUpdateResponse } from "./models/DataUpdateResponse";

class DataUpdatesService {
    async getDataUpdates(authToken: string): Promise<DataUpdate[]> {
        return apiV1.get(
            '/importfiles',
            {
                headers: {
                    Authorization: 'Bearer ' + authToken
                }
            }
        ).then(response => {
            const data: DataUpdateResponse[] = response.data.data
            return data.map((dataUpdate) => (this.mapDataUpdate(dataUpdate)))
        })
    }

    mapDataUpdate(dataUpdate: DataUpdateResponse): DataUpdate {
        return {
            type: dataUpdate.type,
            id: dataUpdate.id,
            attributes: {
                importfile_id: dataUpdate.attributes.importfile_id,
                filename: dataUpdate.attributes.filename,
                inserts: dataUpdate.attributes.inserts,
                updates: dataUpdate.attributes.updates,
                import_start_time: dataUpdate.attributes.import_start_time,
                import_end_time: dataUpdate.attributes.import_end_time,
                has_errors: dataUpdate.attributes.has_errors,
                records: dataUpdate.attributes.records
            }
        }
    }
}

export const dataUpdatesService = new DataUpdatesService()