<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="webpageName"
                  label="URL production"
                  type="text"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="webpageConetent"
                  label="URL sandbox"
                  type="text"
                  required
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn min-width="150" color="#0d60a5" dark depressed class="btn" @click="webpageSubmit()">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "WebpageTouchpointPopup",
  data() {
    return {
      webpageName: "",
      webpageConetent: "",
    };
  },
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    webpageSubmit() {
      var data = {
        webpageName: this.webpageName,
        webpageConetent: this.webpageConetent,
      };
      this.$emit("webpageData", data);
      this.$emit("input", false);
    },
  },
});
</script>
