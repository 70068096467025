<template>
  <v-dialog v-model="show" max-width="1000px">
    <v-card class="pa-4">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-icon @click="show = false" class="pointer">mdi-window-close</v-icon>
      </v-card-actions>
      <v-row>
        <v-col cols="6">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="#0d60a5" dark>
              {{ form_status }}
            </v-btn>
          </v-card-actions>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Name"
                    type="text"
                    required
                    v-model="form_name"
                  ></v-text-field>
                  <v-text-field
                    label="Description"
                    type="text"
                    required
                    v-model="form_description"
                  ></v-text-field>
                  <v-text-field
                    label="Start Date"
                    type="date"
                    required
                    v-model="form_startDate"
                  ></v-text-field>
                  <v-text-field
                    label="Expected completion date"
                    type="date"
                    required
                    v-model="form_expectedCompletionDate"
                  ></v-text-field>
                  <v-text-field
                    label="Actual Publication Date"
                    type="date"
                    required
                    v-model="form_actualPublicationDate"
                  ></v-text-field>
                  <v-select label="Email Template" :items="status"> </v-select>
                  <v-select label="Status" :items="status" v-model="form_status"> </v-select>
                  <tags-input
                    element-id="tags"
                    v-model="selectedTags"
                    :typeahead="false"
                    placeholder="Add Tags..."
                  ></tags-input>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              min-width="150"
              color="#0d60a5"
              dark
              depressed
              class="btn"
              @click="editTouchpointSave()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-col>
        <v-col cols="6">
          <div class="history-area">
            <i class="bi bi-clock-history"></i>
            <span class="history-title">History</span>
          </div>

          <!-- Timeline -->
          <v-timeline dense class="mt-4" v-if="paginated">
            <v-timeline-item v-for="(n, index) in paginated" :key="index" small color="#0d60a5">
              <v-expansion-panels focusable>
                <v-expansion-panel>
                  <v-expansion-panel-header>{{
                    convertDate(n.startDate)
                  }}</v-expansion-panel-header>
                  <v-card class="history-content" @click="geHistory(n)">
                    <v-expansion-panel-content id="fgd">
                      <div v-if="n.name === item.name">
                        <v-btn depressed x-small class="mr-1 mb-1">
                          <p class="ma-0">Name : {{ n.name }}</p>
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-btn depressed x-small class="mr-1 mb-1" dark color="#0d60a5">
                          <p class="ma-0">Name : {{ n.name }}</p>
                        </v-btn>
                      </div>
                      <div v-if="n.description === item.description">
                        <v-btn depressed x-small class="mr-1 mb-1">
                          <p class="ma-0">Description : {{ n.description }}</p>
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-btn depressed x-small class="mr-1 mb-1" dark color="#0d60a5">
                          <p class="ma-0">Description : {{ n.description }}</p>
                        </v-btn>
                      </div>
                      <div v-if="n.startDate === item.startDate">
                        <v-btn depressed x-small class="mr-1 mb-1">
                          <p class="ma-0">Start Date : {{ n.startDate }}</p>
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-btn depressed x-small class="mr-1 mb-1" dark color="#0d60a5">
                          <p class="ma-0">Start Date : {{ n.startDate }}</p>
                        </v-btn>
                      </div>

                      <div v-if="n.expectedCompletionDate === item.expectedCompletionDate">
                        <v-btn depressed x-small class="mr-1 mb-1">
                          <p class="ma-0">
                            Expected Completion Date : {{ n.expectedCompletionDate }}
                          </p>
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-btn depressed x-small class="mr-1 mb-1" dark color="#0d60a5">
                          <p class="ma-0">
                            Expected Completion Date : {{ n.expectedCompletionDate }}
                          </p>
                        </v-btn>
                      </div>

                      <div v-if="n.actualPublicationDate === item.actualPublicationDate">
                        <v-btn depressed x-small class="mr-1 mb-1">
                          <p class="ma-0">Actual Completion Date : {{ n.actualPublicationDate }}</p>
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-btn depressed x-small class="mr-1 mb-1" dark color="#0d60a5">
                          <p class="ma-0">Actual Completion Date : {{ n.actualPublicationDate }}</p>
                        </v-btn>
                      </div>
                      <!-- <div v-if="n.awspinpointId === item.awspinpointId">
                        <v-btn depressed x-small class="mr-1 mb-1">
                          <p class="ma-0">AWS Pinpoint : {{ n.awspinpointId }}</p>
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-btn depressed x-small class="mr-1 mb-1" dark color="#0d60a5">
                          <p class="ma-0">AWS Pinpoint : {{ n.awspinpointId }}</p>
                        </v-btn>
                      </div> -->
                      <div v-if="n.status === item.status">
                        <v-btn depressed x-small class="mr-1 mb-1">
                          <p class="ma-0">Status : {{ n.status }}</p>
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-btn depressed x-small class="mr-1 mb-1" dark color="#0d60a5">
                          <p class="ma-0">Status : {{ n.status }}</p>
                        </v-btn>
                      </div>
                      <v-btn depressed x-small class="mr-1 mb-1">
                        <p class="ma-0">Owner ID : DEMO74682346JDJKSD</p>
                      </v-btn>
                    </v-expansion-panel-content>
                  </v-card>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-timeline-item>
          </v-timeline>
        </v-col>
        <v-col class="paginatio-history">
          <v-btn depressed small class="mr-4" @click="prev()" :disabled="this.current == 1">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn depressed small @click="next()" :disabled="nextdisabled()">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row> </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { nanoid } from "nanoid";
import VoerroTagsInput from "@voerro/vue-tagsinput";
Vue.component("tags-input", VoerroTagsInput);
export default Vue.extend({
  name: "EditTouchpoint",
  props: {
    value: Boolean,
    item: Object,
  },
  data() {
    return {
      form_name: "",
      form_description: "",
      form_startDate: "",
      form_expectedCompletionDate: "",
      form_actualPublicationDate: "",
      // form_awspinpointId: "",
      form_status: "",
      status: ["Draft", "Review", "Published", "Archived"],
      selectedTags: [],
      current: 1,
      pageSize: 5,
    };
  },
  computed: {
    show: {
      get() {
        this.setDefault();
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    indexStart() {
      return (this.current - 1) * this.pageSize;
    },
    indexEnd() {
      return this.indexStart + this.pageSize;
    },
    paginated() {
      if (!this.item.history) {
        return;
      }
      let data = [];
      for (let x of this.item.history) {
        data.push(x);
      }
      data = data.reverse();
      return data.slice(this.indexStart, this.indexEnd);
    },
  },
  methods: {
    nextdisabled() {
      if (!this.item.history) {
        return;
      }
      return this.item.history.length / 5 < this.current;
    },
    prev() {
      this.current--;
    },
    next() {
      this.current++;
    },

    setDefault() {
      this.form_name = this.item.name;
      (this.form_description = this.item.description),
        (this.form_startDate = this.item.startDate),
        (this.form_expectedCompletionDate = this.item.expectedCompletionDate),
        (this.form_actualPublicationDate = this.item.actualPublicationDate);
      // (this.form_awspinpointId = this.item.awspinpointId),
      this.form_status = this.item.status;
      this.selectedTags = this.item.tags;
    },

    async editTouchpointSave() {
      var data = {
        name: this.form_name,
        description: this.form_description,
        startDate: this.form_startDate,
        expectedCompletionDate: this.form_expectedCompletionDate,
        actualPublicationDate: this.form_actualPublicationDate,
        // awspinpointId: this.form_awspinpointId,
        status: this.form_status,
        type: "email",
        displayType: "email",
        tags: this.selectedTags,
        historyId: nanoid(),
      };
      var FinalData = {
        masterDataId: this.item.masterDataId,
        Parent: this.item.Parent,
        name: this.form_name,
        description: this.form_description,
        startDate: this.form_startDate,
        expectedCompletionDate: this.form_expectedCompletionDate,
        actualPublicationDate: this.form_actualPublicationDate,
        // awspinpointId: this.form_awspinpointId,
        status: this.form_status,
        type: "email",
        displayType: "email",
        tags: this.selectedTags,
        history: [...this.item.history, data],
        // ...(this.item.history) && {history: [...this.item.history, data]},
      };
      await axios.put(`${process.env.VUE_APP_API}/journeyData/` + this.item._id, FinalData);
      this.setDefault();
      this.$emit("input", false);
      window.location.reload();
    },
    geHistory(data) {
      this.form_name = data.name;
      (this.form_description = data.description),
        (this.form_startDate = data.startDate),
        (this.form_expectedCompletionDate = data.expectedCompletionDate),
        (this.form_actualPublicationDate = data.actualPublicationDate);
      // (this.form_awspinpointId = data.awspinpointId),
      this.form_status = data.status;
      this.selectedTags = data.tags;
    },
    convertDate(date = "") {
      var startDate = date.split("-");
      startDate = startDate.reverse();
      startDate = startDate.join(".");
      return startDate;
    },
  },

});
</script>
<style scoped>
.history-tile-title {
  font-size: 15px;
  padding: 5px 0px 5px 10px;
  line-height: 20px;
}
.v-timeline-item__body > .v-card:not(.v-card--flat):before,
.v-timeline-item__body > .v-card:not(.v-card--flat):after {
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
}
.history-content {
  font-size: 13px;
  color: #5d5d5d !important;
}
.v-timeline-item {
  padding-bottom: 25px;
}
.v-timeline:before {
  height: 87%;
  top: 26px;
}
.v-application .mt-4 {
  margin-top: 0px !important;
}
.history-title {
  font-size: 20px;
  font-weight: 500;
}
.history-area {
  display: flex;
  margin-left: 15px;
}
.history-area i {
  font-size: 19px;
  margin-right: 10px;
}
.paginatio-history {
  display: flex;
  justify-content: center;
}
.v-btn__content p {
  text-transform: capitalize !important;
  letter-spacing: 0px;
}
.v-expansion-panel-header {
  padding: 10px 10px;
  min-height: 39px;
}
.v-expansion-panel {
  border: 1px solid #ddd;
}
.v-expansion-panel::before {
  box-shadow: none;
}
#fgd {
  display: flex;
}
</style>
