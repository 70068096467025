<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-text>
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  type="text"
                  required
                  v-model="name"
                ></v-text-field>
                <v-text-field
                  label="Description"
                  type="text"
                  required
                  v-model="description"
                ></v-text-field>
                <v-text-field
                  label="Start Date"
                  type="date"
                  required
                  v-model="startDate"
                ></v-text-field>
                <v-text-field
                  label="Expected completion date"
                  type="date"
                  required
                  v-model="expectedCompletionDate"
                ></v-text-field>
                <v-text-field
                  label="Actual Publication Date"
                  type="date"
                  required
                  v-model="actualPublicationDate"
                ></v-text-field>
                <v-row class="d-flex align-center">
                  <v-col cols="10">
                    <v-select
                      label="Email Template"
                      :items="awspinpointId"
                      v-model="template"
                      @change="goCustom()"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      color="#e7e7e7"
                      depressed
                      class="btn"
                      small
                      @click="goCustom()"
                    >
                      ADD
                    </v-btn>
                  </v-col>
                </v-row>
                <v-select
                  label="Status"
                  :items="status"
                  v-model="userStatus"
                ></v-select>

                <tags-input
                  element-id="tags"
                  v-model="selectedTags"
                  :typeahead="false"
                  placeholder="Add Tags..."
                ></tags-input>
              </v-col>
              <v-col cols="12">
                <h5 class="mb-3"><i class="bi bi-tag-fill"></i> Recent Tags</h5>
                <v-btn
                  v-for="(item, index) in allTags"
                  :key="index"
                  small
                  class="mr-2 mb-2"
                  >{{ item.value }}</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          min-width="150"
          color="#0d60a5"
          dark
          depressed
          class="btn"
          @click="emailPost()"
        >
          Send Now
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VoerroTagsInput from "@voerro/vue-tagsinput";
Vue.component("tags-input", VoerroTagsInput);
export default Vue.extend({
  name: "EmailTouchpointPopup",
  data() {
    return {
      name: "",
      description: "",
      startDate: "",
      expectedCompletionDate: "",
      actualPublicationDate: "",
      // awspinpointId: ["Custom Template","Nerve Connect Email", "Follow-up Cerebrum"],
      userStatus: "",
      selectedTags: [],
      status: ["Draft", "Review", "Published", "Archived"],
      allTags: "",
      template: "",
    };
  },
  props: {
    value: Boolean,
    getFun: { type: Function },
  },
  computed: {
    show: {
      get() {
        return this.value && this.getTags();
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    goCustom() {
      this.$router.push("/Stripo");
    },
    emailPost() {
      var data = {
        name: this.name,
        description: this.description,
        startDate: this.startDate,
        expectedCompletionDate: this.expectedCompletionDate,
        actualPublicationDate: this.actualPublicationDate,
        status: this.userStatus,
        awspinpointId: this.awspinpointId,
        tags: this.selectedTags,
      };
      this.$emit("input", false);
      this.$emit("emailContent", data);
    },
    async getTags() {
      var response = await axios.get(`${process.env.VUE_APP_API}/tags`);
      this.allTags = response.data.data;
    },
    async getTemplate() {
      var res = await axios.get(`${process.env.VUE_APP_API}/templateSave`);
      this.awspinpointId = res.data.map((item) => item.name);
    },
  },
  created() {
    this.getTemplate();
  },
});
</script>
