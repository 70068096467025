import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        option: { customProperties: true },
        themes: {
            default: {
                header: '#0081C6',
                menu: '#37ADDC',
                primary: '#1976D2',
                secondary: '#424242',
                chip: '#E4E4E4',
                iconmenu: '#21478B',
            },
            YJ: {
                header: '#dcb000',
                menu: '#37ADDC',
                primary: '#dcb000',
                secondary: '#e49a0c',
                chip: '#E4E4E4',
                iconmenu: '#21478B',
            },
        }
    }
});
