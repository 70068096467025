<template>
  <div>
    <v-card class="pa-3 viewOption">
      <div class="row mb-3">
        <v-col class="d-flex justify-center">
          <span>Doctor Assigned</span>
          <div class="chip">
            <i
              class="bi bi-diagram-3-fill pointer"
              :class="{ colorView: nodeColorView }"
              @click="nodeView"
            ></i>
            <i
              class="bi bi-card-list pointer"
              :class="{ colorView: !nodeColorView }"
              @click="listView"
            ></i>
          </div>
        </v-col>
      </div>
      <div v-if="list">
        <v-data-table
          :items="person"
          :headers="headers"
          :custom-filter="customFilter"
          :items-per-page="10"
          :options.sync="options"
          item-key="name"
          class="elevation-0 mt-2"
          :footer-props="{
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right',
            'items-per-page-options': [10, 20, 30, 40, 50],
          }"
        >
        </v-data-table>
      </div>
      <v-row class="no-gutters" v-if="node">
        <v-col cols="12" class="d-flex justify-center mb-5 firstBox">
          <v-card
            class="upper-section upper-line pa-2"
            style="border-top: 2px solid blue !important; width: auto"
            v-for="(item, index) in trust"
            :key="index"
          >
            <p v-if="trust">
              <i class="bi bi-people mr-2"></i>{{ item.Account }}
            </p>
          </v-card>
        </v-col>
        <br />
        <v-col cols="12" class="d-flex justify-center mb-5 firstBox">
          <v-card
            class="upper-section upper-line pa-2"
            style="border-top: 2px solid blue !important"
            v-if="getHospital"
          >
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-hospital mr-2"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1h1ZM13.25 9a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM13 11.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Zm.25 1.75a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5Zm-11-4a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 3 9.75v-.5A.25.25 0 0 0 2.75 9h-.5Zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM2 13.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Z"
                />
                <path
                  d="M5 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 1 1v4h3a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3V3a1 1 0 0 1 1-1V1Zm2 14h2v-3H7v3Zm3 0h1V3H5v12h1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm0-14H6v1h4V1Zm2 7v7h3V8h-3Zm-8 7V8H1v7h3Z"
                />
              </svg>
              {{ getHospital }}
            </p>
          </v-card>
        </v-col>
        <v-row class="d-flex justify-center">
          <v-col
            cols="4"
            v-for="(item, index) in department"
            :key="index"
            class="mb-5"
          >
            <v-card
              class="upper-section pa-2"
              style="border-top: 2px solid blue !important"
            >
              <p><i class="bi bi-people mr-2"></i> {{ item.Account }}</p>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-space-around flex-wrap">
          <v-col cols="auto" v-for="(items, index) in this.person" :key="index">
            <v-card
              class="upper-section pa-2 mb-3"
              style="border-top: 2px solid blue !important; width: 250px"
            >
              <v-row class="no-gutters">
                <v-col cols="auto">
                  <i class="bi bi-people mr-2"></i>
                </v-col>
                <v-col>
                  <p @click="goDashboard(items.ID_Veeva)" class="pointer">
                    {{ items.Name }}
                  </p>
                  <span>GP Registrar</span>
                </v-col>
                <v-col cols="auto">
                  <template v-if="items.cns">
                    <v-chip x-small>
                      CNS
                      <div class="internal-chip ml-1">Tier 1</div>
                    </v-chip>
                    <br />
                    <v-chip x-small>
                      AHP
                      <div
                        style="background: #e78027"
                        class="internal-chip ml-1"
                      >
                        N/A
                      </div>
                    </v-chip>
                  </template>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import usersData from "../../account-data.json";
import { Department, Trust } from "../../src/organization.json";

export default Vue.extend({
  name: "FusionChartSvg2",
  data: function () {
    return {
      users: usersData,
      select: "",
      options: {},
      node: true,
      list: false,
      nodeColorView: true,
      department: "",
      trust: "",
      person: "",
    };
  },
  created() {
    this.getSegment();
    this.customFilter();
    this.getDepartment();
    this.getTrust();
  },
  methods: {
    goDashboard(id) {
      this.$router.push("/persondashboard/" + id);
    },
    async getSegment() {
      var res = await axios.get(`${process.env.VUE_APP_API}/segment`);
      this.segments = res.data;
    },
    customFilter() {
      this.users = this.users.filter((item) => {
        return item.OrganisationID == this.getId;
      });
    },
    listView() {
      this.list = true;
      this.node = false;
      this.nodeColorView = false;
    },
    nodeView() {
      this.list = false;
      this.node = true;
      this.nodeColorView = true;
    },
    getDepartment() {
      this.department = Department.filter((item) => {
        if (item.ParentAccountID == this.getId) {
          return item;
        }
      });

      let newuser = {};
      let alluser = [];

      for (const iterator of this.department) {
        newuser[iterator.ID] = [];
        for (const user of this.users) {
          if (
            iterator.ID === user.PrimaryParent &&
            iterator.ParentAccountID === user.OrganisationID
          ) {
            newuser[iterator.ID].push(user);
            alluser.push(user);
          }
        }
      }
      this.person = alluser;
    },
    getTrust() {
      this.trust = Trust.filter((item) => {
        if (item.ID == this.getParenId) {
          return item;
        }
      });
    },
  },
  computed: {
    headers() {
      return [
        { text: "Name", value: "Name" },
        { text: "Country", value: "CountryCode" },
        {
          text: "Specialty 1",
          value: "Specialty_1",
        },
        {
          text: "Specialty 2",
          value: "Specialty_2",
        },
        { text: "TA", value: "TA1" },
      ];
    },
    getHospital() {
      return this.$route.query.name;
    },
    getParenId() {
      return this.$route.query.parenID;
    },
    getId() {
      return this.$route.query.id;
    },
  },
  watch: {
    options: {
      handler() {
        this.$store.dispatch("getIndividuals", {
          offset: (this.options.page - 1) * this.options.itemsPerPage,
          pageSize: this.options.itemsPerPage,
        });
      },
    },
  },
});
</script>
<style scoped>
.sankeyChart div {
  padding: 1px;
  padding-left: 3px;
}

.indicator {
  display: flex;
  padding: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
}
.indicator div {
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}
.indicator div > span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: block;
  margin-right: 10px;
}
.sankeyContainer span {
  visibility: hidden;
}
.sankeyContainer:hover .sankeyContainer span {
  visibility: visible !important;
}
.journey-chart-title {
  position: relative;
}
.default {
  position: absolute;
  top: 50%;
  left: 35%;
  z-index: 1;
  color: #b0b2b8;
  text-transform: capitalize;
}
.upper-section {
  font-size: 13px;
  width: 300px;
  border-radius: 8px;
  position: relative;
}
.upper-section p {
  margin: 0px;
  font-weight: bold;
}
.upper-section span {
  font-weight: 300;
}
.firstBox {
  margin-bottom: 60px !important;
}
.upper-line::before {
  position: absolute;
  content: "";
  height: 80px;
  width: 1px;
  left: 50%;
  background: #c6c6c6;
  transform: translateX(-50%);
  top: 35px;
}
.viewOption {
  position: relative;
}
.chip {
  position: absolute;
  top: 8px;
  right: 16px;
}
.chip i {
  width: 40px;
  background: #e9e9e9;
  display: inline-block;
  margin-right: 1px;
  text-align: center;
  height: 28px;
  line-height: 28px;
  color: #535353;
}
.chip i:first-child {
  border-radius: 5px 0px 0px 5px;
}
.chip i:nth-child(2) {
  border-radius: 0px 5px 5px 0px;
}
.colorView {
  color: blue !important;
}
.internal-chip {
  background: #7ab725;
  color: #fff;
  border-radius: 4px;
  height: 18px;
  width: 28px;
  text-align: center;
  line-height: 18px;
}
.v-chip {
  padding: 0 7px !important;
}
</style>
