import navigationItem from "@/classes/NavigationItem";
import VueI18n from "../i18n";

const state: State = {
  role: 'default',
  availableroutes: [
    // {
    //   title: VueI18n.t('navigation.home'),
    //   icon: 'groups',
    //   path: '/',
    // },
    {
      title: VueI18n.t('navigation.accounts'),
      icon: '',
      path: '/',
    },
    // {
    //   title: VueI18n.t('navigation.dashboard'),
    //   icon: 'insights',
    //   path: '/dashboard',
    // },
    {
      title: VueI18n.t('navigation.DasshBoard'),
      icon: 'insights',
      path: '/dasshboard',
    },
  ]
}

const navigationConfigs = [
  // {
  //   role: "default",
  //   config: [
  //     // {
  //     //   title: VueI18n.t('navigation.home'),
  //     //   icon: 'bi bi-people',
  //     //   path: '/'
  //     // },
  //     {
  //       title: VueI18n.t('navigation.accounts'),
  //       icon: 'group.png',
  //       path: '/',
  //     },
  //     // {
  //     //   title: VueI18n.t('navigation.dashboard'),
  //     //   icon: 'insights',
  //     //   path: '/dashboard',
  //     // },
  //     {
  //       title: VueI18n.t('navigation.DasshBoard'),
  //       icon: 'mdi-view-dashboard-edit-outline',
  //       path:'/commercial',
  //         children: [
  //         {
  //           title: VueI18n.t('navigation.commercial'),
  //           icon: 'manager.png',
  //           path: '/commercial'
  //         },
  //         {
  //           title: VueI18n.t('navigation.marketing'),
  //           icon: 'marketing.png',
  //           path: '/marketing'
  //         },
  //         // {
  //         //   title: VueI18n.t('navigation.management'),
  //         //   icon: 'manager.png',
  //         //   path: '/management'
  //         // },
  //         {
  //           title: VueI18n.t('navigation.medical'),
  //           icon: 'doctor.png',
  //           path: '/medical'
  //         },
  //       ] 
  //     },
  //     {
  //       title: VueI18n.t('navigation.engagement'),
  //       icon: 'mdi-chemical-weapon',
  //       path: '/TouchPoint',
  //         children: [
  //         {
  //           title: VueI18n.t('navigation.touchpoints'),
  //           icon: 'touchpoint.png',
  //           path: '/TouchPoint'
  //         },
  //         {
  //           title: VueI18n.t('navigation.segments'),
  //           icon: 'segment.png',
  //           path: '/segment'
  //         },
  //         {
  //           title: VueI18n.t('navigation.journeys'),
  //           icon: 'journey.png',
  //           path: '/journeyoverview'
  //         },
  //         // {
  //         //   title: VueI18n.t('navigation.campaigns'),
  //         //   icon: 'manager.png',
  //         //   path: '/persondashboard'
  //         // },
  //       ] 
  //     }

  //   ]
  // },
  {
    role: "admin",
    config: [
      // {
      //   title: VueI18n.t('navigation.home'),
      //   icon: 'bi bi-people',
      //   path: '/'
      // },
      {
        title: VueI18n.t('navigation.accounts'),
        icon: 'group.png',
        path: '/',
      },
      {
        title: VueI18n.t('navigation.organization'),
        icon: 'hospital2.png',
        path: '/organization',
      },
      // {
      //   title: VueI18n.t('navigation.dashboard'),
      //   icon: 'insights',
      //   path: '/dashboard',
      // },
      {
        title: VueI18n.t('navigation.DasshBoard'),
        icon: 'mdi-view-dashboard-edit-outline',
        path:'/commercial',
          children: [
          {
            title: VueI18n.t('navigation.commercial'),
            icon: 'manager.png',
            path: '/commercial'
          },
          {
            title: VueI18n.t('navigation.marketing'),
            icon: 'marketing.png',
            path: '/marketing'
          },
          // {
          //   title: VueI18n.t('navigation.management'),
          //   icon: 'manager.png',
          //   path: '/management'
          // },
          {
            title: VueI18n.t('navigation.medical'),
            icon: 'doctor.png',
            path: '/medical'
          },
        ] 
      },
      {
        title: VueI18n.t('navigation.engagement'),
        icon: 'mdi-chemical-weapon',
        path: '/TouchPoint',
          children: [
          {
            title: VueI18n.t('navigation.touchpoints'),
            icon: 'touchpoint.png',
            path: '/TouchPoint'
          },
          {
            title: VueI18n.t('navigation.segments'),
            icon: 'segment.png',
            path: '/segment'
          },
          {
            title: VueI18n.t('navigation.journeys'),
            icon: 'journey.png',
            path: '/journeyoverview'
          },
        ] 
      },
      {
        title: VueI18n.t('navigation.administration'),
        icon: 'admin_panel_settings',
        path: '/Connection',
          children: [
          {
            title: VueI18n.t('navigation.connection'),
            icon: 'connections.png',
            path: '/Connection'
          },
          {
            title: 'Dashboard',
            icon: 'connections.png',
            path: '/dashboardadmin'
          }
        ] 
      },
      {
        title: 'Create Tenant',
        icon: 'admin_panel_settings',
        path: '/login',
          children: [
          {
            title:'Admin',
            icon: 'connections.png',
            path: '/admin'
          },
          {
            title: 'Tenant',
            icon: 'connections.png',
            path: '/tenant'
          }
        ] 
      },

    ]
  },
  {
    role: "commercial",
    config: [
      {
        title: VueI18n.t('navigation.accounts'),
        icon: 'group.png',
        path: '/',
      },
      {
        title: VueI18n.t('navigation.organization'),
        icon: 'hospital2.png',
        path: '/organization',
      },
      {
        title: VueI18n.t('navigation.DasshBoard'),
        icon: 'mdi-view-dashboard-edit-outline',
        path:'/commercial',
          children: [
          {
            title: VueI18n.t('navigation.commercial'),
            icon: 'manager.png',
            path: '/commercial'
          },
          {
            title: VueI18n.t('navigation.marketing'),
            icon: 'marketing.png',
            path: '/marketing'
          },
        ] 
      },
      {
        title: VueI18n.t('navigation.engagement'),
        icon: 'mdi-chemical-weapon',
        path: '/TouchPoint',
          children: [
          {
            title: VueI18n.t('navigation.touchpoints'),
            icon: 'touchpoint.png',
            path: '/TouchPoint'
          },
          {
            title: VueI18n.t('navigation.segments'),
            icon: 'segment.png',
            path: '/segment'
          },
          {
            title: VueI18n.t('navigation.journeys'),
            icon: 'journey.png',
            path: '/journeyoverview'
          },
        ] 
      },
      {
        title: VueI18n.t('navigation.engagement'),
        icon: 'mdi-chemical-weapon',
        path: '/TouchPoint',
          children: [
          {
            title: VueI18n.t('navigation.touchpoints'),
            icon: 'touchpoint.png',
            path: '/TouchPoint'
          },
          {
            title: VueI18n.t('navigation.segments'),
            icon: 'segment.png',
            path: '/segment'
          },
          {
            title: VueI18n.t('navigation.journeys'),
            icon: 'journey.png',
            path: '/journeyoverview'
          },
        ] 
      },
      

    ]
  },
  {
    role: "medical",
    config: [
      {
        title: VueI18n.t('navigation.accounts'),
        icon: 'group.png',
        path: '/',
      },
      {
        title: VueI18n.t('navigation.organization'),
        icon: 'hospital2.png',
        path: '/organization',
      },
      {
        title: VueI18n.t('navigation.DasshBoard'),
        icon: 'mdi-view-dashboard-edit-outline',
        path:'/marketing',
          children: [
          {
            title: VueI18n.t('navigation.marketing'),
            icon: 'marketing.png',
            path: '/marketing'
          },
          {
            title: VueI18n.t('navigation.medical'),
            icon: 'doctor.png',
            path: '/medical'
          },
        ] 
      },
      {
        title: VueI18n.t('navigation.engagement'),
        icon: 'mdi-chemical-weapon',
        path: '/TouchPoint',
          children: [
          {
            title: VueI18n.t('navigation.touchpoints'),
            icon: 'touchpoint.png',
            path: '/TouchPoint'
          },
          {
            title: VueI18n.t('navigation.segments'),
            icon: 'segment.png',
            path: '/segment'
          },
          {
            title: VueI18n.t('navigation.journeys'),
            icon: 'journey.png',
            path: '/journeyoverview'
          },
        ] 
      }

    ]
  },
]

const data = JSON.parse(localStorage.getItem("tokenInfo"))
const permission = data?.permissions[0].includes('role') ? data?.permissions[0] : data?.permissions[1]
const getRole =  permission?.split(':')[1]

const permissionManager = {
  state:{
    role:getRole
  },
  getters: {
    getRole(state: State): string {
      return state.role
    },
    getAvailableRoutes(state: State): Array<navigationItem> {
      let config = navigationConfigs.find(config => config.role == state.role)?.config;
      if(config == null) {
        config = navigationConfigs[0].config;
      }
      return config;
    }
  },
  mutations: {
    setRole(state: State, role: string): void{
      state.role = role;
    }
  },
  action: {
  },
  modules: {
  },
};
export default permissionManager;

interface State {
  role: string,
  availableroutes: Array<navigationItem>
}

interface NavigationConfig {
  role: string,
  config: Array<navigationItem>
}