<template>
    <div>
        <div class="extra-navbar">
            <div class="extra-logo" @click="openEditUser">U</div>
            <div class="extra-content d-flex align-items-center">
                <div class="user-state">

                </div>
                <h4 v-if="getInfo">{{ getInfo.username }}</h4>
                <span class="ml-3">{{ getInfo.role }}</span>
                <i class="bi bi-power ml-3 pointer" style="font-size: 19px;" @click="goLogout"></i>
            </div>
        </div>
        <div class="secondary-sidebar"><v-icon color="#008a93">mdi-finance</v-icon>

        </div>
        <div class="container">
            <v-card class="pa-3" style="margin-top: 80px;" v-if="getInfo.role == 'medical'">
                <iframe title="Demo dashboard EM" style="
            display: block;
            width: 100%;
            height: 1068px;
            margin: 0px;
            padding: 0px;
            border: none;
          " src="https://public.tableau.com/views/FIFA18_16445625194300/FIFA18?:language=en-US&:display_count=n&:origin=viz_share_link:showVizHome=no&:embed=true"
                    frameborder="0" allowFullScreen="true"></iframe>
            </v-card>
            <v-card class="pa-3" style="margin-top: 80px;" v-if="getInfo.role == 'commercial'">
                <iframe title="Demo dashboard EM" style="
            display: block;
            width: 100%;
            height: 1068px;
            margin: 0px;
            padding: 0px;
            border: none;
            
          " src="https://app.powerbi.com/reportEmbed?reportId=edd43fd0-cf8c-49f3-a926-f3d8d9af5c91&autoAuth=true&ctid=af26eb46-d51d-4113-8acb-a7367715305a"
                    frameborder="0" allowFullScreen="true"></iframe>
            </v-card>
        </div>
        <v-dialog v-model="addUserModal" max-width="300px" transition="dialog-top-transition">
            <v-card class="pa-3">
                <v-form ref="form">
                    <v-text-field label="Username" v-model="username" class="mb-4" required></v-text-field>
                    <v-text-field label="Password" v-model="password" class="mb-4" required></v-text-field>
                    <v-select label="Role" dense solo :items="roles" v-model="role" required class="mb-3"></v-select>
                    <v-card-actions class="pa-0 mt-5">
                        <v-spacer></v-spacer>
                        <v-btn color="#0d60a5" dark depressed @click="closeModal">
                            Close
                        </v-btn>
                        <v-btn color="#0d60a5" dark depressed class="btn" @click="saveEditUser()">
                            Save
                        </v-btn>

                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
import Vue from "vue";
import axios from 'axios';
export default Vue.extend({
    name: "UserDashboard",
    data() {
        return {
            addUserModal: false,
            username: "",
            password: "",
            role: [],
            roles: ['admin', 'commercial', 'medical'],
            id: ""
        };
    },
    components: {},
    computed: {
        getInfo() {
            return JSON.parse(localStorage.getItem("loggedUser"));
        }
    },
    methods: {
        goLogout() {
            localStorage.removeItem("loggedUser");
            this.$router.push('/tenantLogin')

        },
        openEditUser() {
            let data = JSON.parse(localStorage.getItem("loggedUser"));
            this.addUserModal = !this.addUserModal;
            this.username = data.username,
                this.password = data.password,
                this.role = data.role,
                this.id = data.id
        },
        async saveEditUser() {
            let local = JSON.parse(localStorage.getItem("loggedUser"));
            let data = {
                username: this.username,
                password: this.password,
                role: this.role,
                id: local.id
            }
            await axios.put(`${process.env.VUE_APP_API}/editAdduser/` + local.id, data);
            this.addUserModal = !this.addUserModal;
            localStorage.setItem(
                "loggedUser",
                JSON.stringify(data)
            );
            window.location.reload()
        },
        closeModal() {
            this.username = '';
            this.password = '';
            this.role = '';
            this.addUserModal = !this.addUserModal;
        }
    },

});
</script>
<style>
.user-header-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.secondary-sidebar {
    width: 60px;
    position: fixed;
    top: 80px;
    left: 0px;
    height: 100%;
    z-index: 1;
    background-color: #fff;
    text-align: center;
    border-top: 1px solid #e7e7e7;
    padding-top: 10px;
}

.extra-navbar {
    position: fixed;
    z-index: 1;
}
</style>