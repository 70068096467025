































































































































import Vue from "vue";
import axios from "axios";
import SegmentPopup from "../components/CreateSegment.vue";
export default Vue.extend({
  name: "Segment",
  data() {
    return {
      active: false,
      segments: "",
      openSegmentModal: false,
    };
  },
  components: {
    SegmentPopup,
  },
  created() {
    this.getSegment();
  },
  methods: {
    toggleNav() {
      this.active = !this.active;
    },
    async getSegment() {
      var res = await axios.get(`${process.env.VUE_APP_API}/segment`);
      this.segments = res.data;
    },
    openCreateSegmentModal() {
      this.openSegmentModal = true;
    },
    fetchSegment() {
      this.getSegment();
    },
  },
  // computed: {
  //   unaware() {
  //     return this.$store.getters.getUnaware;
  //   },
  //   aware() {
  //     return this.$store.getters.getAware;
  //   },
  //   brandAverse() {
  //     return this.$store.getters.getBrandAverse;
  //   },
  //   firsttimeprescriber() {
  //     return this.$store.getters.getFirstTimePrescriber;
  //   },
  //   frequentprescriber() {
  //     return this.$store.getters.getFrequentPrescriber;
  //   },
  //   brandadvocate() {
  //     return this.$store.getters.getBrandAdvocate;
  //   },
  //   emailtemplate(){
  //     return this.$store.getters.getEtemplate;
  //   }
  // },
  // mounted() {
  //   this.$store.dispatch("getUnaware", {
  //     id: "0810a254308b4b67a92da8d392d13198",
  //   });
  //   this.$store.dispatch("getAware", {
  //     id: "9939231663df404db9c4525048b7b771",
  //   });
  //   this.$store.dispatch("getBrandAverse", {
  //     id: "dc873c6d614c4d71858abb80def17bb7",
  //   });
  //   this.$store.dispatch("getFirstTimePrescriber", {
  //     id: "ab9e5227128c4ff189225a2b04c8dc22",
  //   });
  //   this.$store.dispatch("getFrequentPrescriber", {
  //     id: "afca5be3a07d4268aa1227aea7d390c1",
  //   });
  //   this.$store.dispatch("getBrandAdvocate", {
  //     id: "4beecb26679a44219ea4e7ac861f8dc6",
  //   });
  //   this.$store.dispatch("getEtemplate");
  // },
});
