


































































































































































































import mapboxgl from "mapbox-gl";
import mapdata from "../../pointer.json";
export default {
  name: "Pointer",
  // data() {
  //   return {
  //     name: "",
  //     areapin: "",
  //     lat: "",
  //     long: "",
  //     finalMap: undefined,
  //     dropdownData: [],
  //     selectedDoctorList: [],
  //     data: mapdata,
  //   };
  // },
  // methods: {
  //   customFilter(data) {
  //     this.styleCode = data;
  //   },
  //   addInArea() {
  //     this.selectedDoctorList.push({
  //       type: "Feature",
  //       properties: {
  //         description: `<p>${this.areapin} and Doctor Name <strong> ${this.name} </strong></p>`,
  //         icon: "theatre",
  //         finalData: {
  //           name: this.name,
  //           zipcode: this.areapin,
  //           lat: this.lat,
  //           long: this.long,
  //         },
  //       },
  //       geometry: {
  //         type: "Point",
  //         coordinates: [Number(this.long), Number(this.lat)],
  //       },
  //     });

  //     this.name = "";
  //     this.areapin = "";
  //     this.lat = "";
  //     this.long = "";
  //     this.finalMap.getSource("places").setData({
  //       type: "FeatureCollection",
  //       features: this.selectedDoctorList,
  //     });
  //   },
  // },
  // mounted() {
  //   let thisComponent = this as any;
  //   const myHeaders = new Headers({
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   });
  //   fetch("/polygon.geojson", {
  //     headers: myHeaders,
  //   })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((poligondata) => {
  //       let mainPoligon = poligondata;
  //       let filterdPoligon = mainPoligon;
  //       let clickedStateId = null;

  //       mapboxgl.accessToken =
  //         "pk.eyJ1IjoianNtYjEyMSIsImEiOiJjbGFrdmxjdXkwNDM3M3ZvMDhpZnV6cG9oIn0.vKBIP9qrTUV2esADVho1Hw";

  //       const map = new mapboxgl.Map({
  //         container: "map",
  //         style: "mapbox://styles/mapbox/streets-v9",
  //         projection: "globe",
  //         zoom: 8,
  //         center: [5.269958103948596, 51.75467495503423],
  //       });

  //       const layerList = document.querySelector(".wrapper");
  //       const inputs = layerList.getElementsByTagName("input");

  //       for (const input of inputs) {
  //         input.onclick = (layer) => {
  //           const layerId = layer.target.id;
  //           map.setStyle("mapbox://styles/mapbox/" + layerId);
  //         };
  //       }

  //       this.finalMap = map;

  //       map.on("load", async () => {
  //         //Zipcode
  //         map.addSource("earthquakes", {
  //           type: "geojson",
  //           // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
  //           // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.

  //           data: this.data[0],
  //           cluster: true,
  //           clusterMaxZoom: 14, // Max zoom to cluster points on
  //           clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
  //         });

  //         map.addLayer({
  //           id: "clusters",
  //           type: "circle",
  //           source: "earthquakes",
  //           filter: ["has", "point_count"],
  //           paint: {
  //             // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
  //             // with three steps to implement three types of circles:
  //             //   * Blue, 20px circles when point count is less than 100
  //             //   * Yellow, 30px circles when point count is between 100 and 750
  //             //   * Pink, 40px circles when point count is greater than or equal to 750
  //             "circle-color": [
  //               "step",
  //               ["get", "point_count"],
  //               "#51bbd6",
  //               10,
  //               "#f1f075",
  //               30,
  //               "#f28cb1",
  //               40,
  //               "yellow",
  //             ],
  //             "circle-radius": [
  //               "step",
  //               ["get", "point_count"],
  //               20,
  //               10,
  //               30,
  //               30,
  //               40,
  //             ],
  //           },
  //         });

  //         map.addLayer({
  //           id: "cluster-count",
  //           type: "symbol",
  //           source: "earthquakes",
  //           filter: ["has", "point_count"],
  //           layout: {
  //             "text-field": ["get", "point_count_abbreviated"],
  //             "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
  //             "text-size": 12,
  //           },
  //         });

  //         map.addLayer({
  //           id: "unclustered-point",
  //           type: "circle",
  //           source: "earthquakes",
  //           filter: ["!", ["has", "point_count"]],
  //           paint: {
  //             "circle-color": "#11b4da",
  //             "circle-radius": 4,
  //             "circle-stroke-width": 1,
  //             "circle-stroke-color": "#fff",
  //           },
  //         });

  //         // inspect a cluster on click
  //         map.on("click", "clusters", (e) => {
  //           const features = map.queryRenderedFeatures(e.point, {
  //             layers: ["clusters"],
  //           });
  //           const clusterId = features[0].properties.cluster_id;
  //           map
  //             .getSource("earthquakes")
  //             .getClusterExpansionZoom(clusterId, (err, zoom) => {
  //               if (err) return;

  //               map.easeTo({
  //                 center: features[0].geometry.coordinates,
  //                 zoom: zoom,
  //               });
  //             });
  //         });

  //         // When a click event occurs on a feature in
  //         // the unclustered-point layer, open a popup at
  //         // the location of the feature, with
  //         // description HTML from its properties.
  //         map.on("click", "unclustered-point", (e) => {
  //           const coordinates = e.features[0].geometry.coordinates.slice();
  //           const mag = e.features[0].properties.mag;
  //           const tsunami =
  //             e.features[0].properties.tsunami === 1 ? "yes" : "no";

  //           // Ensure that if the map is zoomed out such that
  //           // multiple copies of the feature are visible, the
  //           // popup appears over the copy being pointed to.
  //           while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
  //             coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
  //           }

  //           new mapboxgl.Popup()
  //             .setLngLat(coordinates)
  //             .setHTML(`magnitude: ${mag}<br>Was there a tsunami?: ${tsunami}`)
  //             .addTo(map);
  //         });

  //         map.on("mouseenter", "clusters", () => {
  //           map.getCanvas().style.cursor = "pointer";
  //         });
  //         map.on("mouseleave", "clusters", () => {
  //           map.getCanvas().style.cursor = "";
  //         });

  //         //   const timer = setInterval(() => {
  //         //     let num = Math.floor(Math.random() * 3);
  //         //     if (this.data) {
  //         //       map.getSource("earthquakes").setData(this.data[num]);
  //         //     } else {
  //         //       window.clearInterval(timer);
  //         //     }
  //         //   }, 2000);
  //       });
  //     });
  // },
};
