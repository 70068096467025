







































































































































































































































































































































































































































































































































































































import Vue from "vue";
export default Vue.extend({
  name: "JourneySubscribe2",
  data: () => ({
    dialog: false,
    action: true,
    nervehide: true,
    regmail: true,
    showTrial: true,
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
  }),
  computed: {
    emailtemplate() {
      return this.$store.getters.getEtemplate;
    },
  },
  methods: {
    showEmailpopup() {
      this.action = false;
    },
    showNerve() {
      this.action = true;
      this.nervehide = false;
    },
    nerveEmail() {
      this.regmail = false;
    },
    showRegistration() {
      this.regmail = true;
      this.showTrial = false;
    },
  },
  mounted() {
    this.$store.dispatch("getEtemplate");
  },
});
