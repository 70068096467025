




























import Vue from "vue";

export default Vue.extend({
  name: "viewPDF",
  data: () => ({
  }),
  props: {
    value: Boolean,
    template: Object,
    showNerve: Object,
  },
  methods: {
    openNerve(data) {
      this.$emit("saveNerve", data);
      
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value : boolean) {
        (this as any).$emit("input", value);
      },
    },
  },
});
