import { DataUpdate } from "@/classes/dataUpdates/DataUpdate";
import { dataUpdatesService } from "@/remote/dataUpdatesService";

import { authToken } from "@/auth/authToken";

const state: State = {
    dataUpdates: []
}

const dataUpdatesStore = {
    state,
    getters: {
        getDataUpdates(state: State): DataUpdate[] {
            return state.dataUpdates
        }
    },
    mutations: {
        setDataUpdates(state: State, dataUpdates: DataUpdate[]) {
            state.dataUpdates = dataUpdates
        }
    },
    actions: {
        getDataUpdates(context) {
            authToken().then(token => {
                dataUpdatesService.getDataUpdates(token)
                    .then(response => {
                        context.commit('setDataUpdates', response)
                    })
            })

        }
    }
}
export default dataUpdatesStore

interface State {
    dataUpdates: DataUpdate[]
}