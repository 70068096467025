


























































































































































































































































































































































































































































































import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  name: "JourneyOverview",
  data() {
    return {
      active: false,
      dialog: false,
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
      openTriggerBox: false,
      segments: [
        "Unaware",
        "Aware",
        "Trialist",
        "Frequent Prescriber",
        "Brand Advocate",
        "Brand Averse",
      ],
      category: ["pharma", "travel"],
      triggerJourneyList: ["Aware to Trialist", "Unsubscribe Cerebrum"],
      segmentValue: "",
      masterData: "",
      row: null,
      ssraja: "",
      segmentSection: true,
      triggerSection: true,
      customSection: true,
      journeyName: "",
      listOption: false,
      showSharepopup: false,
      confirmationAlert: false,
      protection: false,
      passd: "",
      users: "",
      categoryValue: "",
      user: [
        "commercial@curapharmaceuticals.com",
        "john.servaes@dj-technologysolutions.com",
        "france@clovis.demo",
        "pankaj@enowick.com",
        "commercial@curapharmaceuticals.com",
      ],
      email: "",
      popup_masterDataId: "",
      isChecked1: false,
      isChecked2: false,
      canEdit: false,
      canView: true,
      sharedDetails: false,
      sharedUser: "",
      adminEmail: JSON.parse(localStorage.getItem("user")),
      passwordPopUp: false,
      confirmPasswd: "",
      accessData: "",
      showShareId: 0,
    };
  },
  methods: {
    emailRadio() {
      this.segmentSection = !this.segmentSection;
      this.triggerSection = true;
      this.isChecked1 = true;
      this.isChecked2 = false;
    },
    userRadio() {
      this.triggerSection = !this.triggerSection;
      this.segmentSection = true;
      this.isChecked1 = false;
      this.isChecked2 = true;
    },
    segmentRadio() {
      this.segmentSection = !this.segmentSection;
      this.triggerSection = true;
      this.customSection = true;
      this.journeyName = "";
    },
    triggerRadio() {
      this.triggerSection = !this.triggerSection;
      this.segmentSection = true;
      this.customSection = true;
      this.journeyName = "";
    },
    customRadio() {
      this.triggerSection = true;
      this.segmentSection = true;
      this.customSection = false;
    },
    triggerJourney() {
      this.openTriggerBox = true;
    },
    async closeTriggerBox() {
      var loginUserEmail = JSON.parse(localStorage.getItem("user"));
      this.openTriggerBox = false;
      let data = {
        name: this.segmentValue,
        journeyName: this.journeyName,
        description: "",
        startDate: "25.01.2022",
        expectedCompletionDate: "",
        ActualPublicationDate: "",
        status: "Published",
        tags: "Cerebrum",
        owner: "",
        developingCompany: "",
        category: this.categoryValue,
        changeHistory: "",
        username: loginUserEmail.email,
      };
      await axios.post(`${process.env.VUE_APP_API}/masterData`, data);
      // this.$router.push("/emailtouchpoint2?masterDataId=" + masterDataId.data.id);
      window.location.reload();
    },
    toggleNav() {
      this.active = !this.active;
    },

    goJourney(item) {
      var name = JSON.parse(localStorage.getItem("user"));
      axios
        .get(
          `${process.env.VUE_APP_API}/masterData/` +
            item._id +
            "?username=" +
            name.email
        )
        .then((response) => {
          this.accessData = response.data.access;
          if (!response.data?.access?.canProtected) {
            this.$router.push(
              "/emailtouchpoint2?masterDataId=" +
                item._id +
                "&journeyName=" +
                item.journeyName
            );
            let data = {};
            data[item.journeyName] = item.journeyName;
            var oldData = localStorage.getItem("YourItem");
            localStorage.setItem(
              "YourItem",
              JSON.stringify({ ...JSON.parse(oldData), ...data })
            );
          } else {
            this.passwordPopUp = true;
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });

      // window.location.reload();
    },
    // goSubscribe() {
    //   this.$router.push("/journeysubscribe");
    // },

    async getMasterJourney() {
      var userData = JSON.parse(localStorage.getItem("user"));
      var response = await axios.get(
        `${process.env.VUE_APP_API}/masterData?username=` + userData.email
      );
      this.masterData = response.data;

      var arrayData = JSON.parse(localStorage.getItem("themeChanger"));
      var pharma = arrayData.pharma;
      var travel = arrayData.travel;
      // var finance = arrayData.finance

      this.masterData = this.masterData.filter((item) => {
        if (pharma && travel) {
          return item.category == "pharma" || item.category == "travel";
        } else if (pharma) {
          return item.category == "pharma";
        } else if (travel) {
          return item.category == "travel";
        }
      });
    },
    openListOption(id) {
      this.listOption = !this.listOption;
      this.showShareId = id;
    },
    openShaepopUp(data) {
      this.showSharepopup = true;
      this.listOption = false;
      this.popup_masterDataId = data._id;
      var user = JSON.parse(localStorage.getItem("user"));
      this.user = this.user.filter((item) => {
        if (user.email !== item) {
          return item;
        }
      });
    },
    changeSwitch() {
      if (this.protection) {
        this.customSection = false;
      } else if (!this.protection) {
        this.customSection = true;
      }
    },
    async actionShare() {
      this.confirmationAlert = true;
      this.showSharepopup = false;
      var id = JSON.parse(localStorage.getItem("user"));

      var data = {
        shareViaEmail: this.isChecked1,
        mail: this.email,
        shareViaUser: this.isChecked2,
        username: this.users,
        ownUserId: id.email,
        canEdit: this.canEdit,
        canView: this.canView,
        canProtected: this.protection,
        password: this.passd,
        masterDataId: this.popup_masterDataId,
        url: "http://localhost:8080/LoginPage",
      };
      await axios.post(`${process.env.VUE_APP_API}/send-mail`, data);
    },
    async openShare() {
      this.sharedDetails = !this.sharedDetails;
      var userData = JSON.parse(localStorage.getItem("user"));
      var res = await axios.get(
        `${process.env.VUE_APP_API}/masterData/share-user-list/` + userData.email
      );
      this.sharedUser = res.data.data;
    },
    async revokeItem(item, user) {
      let data = { masterDataId: item._id, username: user };
      axios.put(`${process.env.VUE_APP_API}/masterData/revoke`, data);
      this.sharedDetails = false;
    },
    async permissionJourney(item) {
      if (this.accessData.password === this.confirmPasswd) {
        this.$router.push(
          "/emailtouchpoint2?masterDataId=" +
            item._id +
            "&journeyName=" +
            item.journeyName
        );
        let data = {};
        data[item.journeyName] = item.journeyName;
        var oldData = localStorage.getItem("YourItem");
        localStorage.setItem(
          "YourItem",
          JSON.stringify({ ...JSON.parse(oldData), ...data })
        );
      } else {
        alert("Wrong Password");
      }
    },
  },
  mounted() {
    this.getMasterJourney();
    localStorage.removeItem("YourItem");
  },
});
