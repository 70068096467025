<template>
  <div class="container">
    <v-row>
      <v-col cols="8">
        <div id="map"></div>
      </v-col>
      <v-col cols="4">
        <v-card class="mb-2 pa-1">
          <!-- <v-slider
            v-model="fruits"
            :tick-labels="ticksLabels"
            :max="3"
            step="1"
            ticks="always"
            tick-size="4"
            @change="sliderInput"
          ></v-slider> -->
        </v-card>
        <!-- <v-card v-for="item in pointerData.features" :key="item" class="pa-2">
          <h5>Name - {{ item.customData.name }}</h5>
          <h5>Total UniqueUsers - {{ item.customData.totalUniqueUsers }}</h5>
          <h5>Total Logins - {{ item.customData.totalLogins }}</h5>
          <div class="timeframe pointer" @click="showTimeframe">Timeframe</div>
          <v-chip-group
            active-class="primary--text"
            class="content"
            column
            :class="{ showframe: showframe }"
          >
            <v-chip v-for="tag in item.customData.timeframe" :key="tag">
              UsersOnline : {{ tag.usersOnline }}
            </v-chip>
          </v-chip-group>
        </v-card> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import data from "../../pointer.json";

export default Vue.extend({
  name: "MapPointer",
  data() {
    return {
      map: "",
    };
  },
  components: {},
  methods: {
    createPointer() {
      if (this.map) {
        const element = document.getElementsByClassName("marker");
        for (let item of element) {
          item.innerHTML = "";
          item.removeAttribute("style");
        }
        for (const marker of data.features) {
          const el = document.createElement("div");
          const htmlNumbers = Math.floor(1000 + Math.random() * 3500);
          el.className = "marker";
          el.style.borderRadius = "50%";
          el.style.color = "#fff";
          el.style.textAlign = "center";
          if (htmlNumbers >= 1000 && htmlNumbers <= 1500) {
            el.style.lineHeight = "40px";
            el.style.backgroundColor = "#f28cb1";
            el.style.width = "40px";
            el.style.height = "40px";
          } else if (htmlNumbers >= 1500 && htmlNumbers <= 2000) {
            el.style.backgroundColor = "#333333";
            el.style.lineHeight = "50px";
            el.style.width = "50px";
            el.style.height = "50px";
          } else if (htmlNumbers >= 2000 && htmlNumbers <= 2500) {
            el.style.backgroundColor = "#832479";
            el.style.width = "60px";
            el.style.height = "60px";
            el.style.lineHeight = "60px";
          } else {
            el.style.backgroundColor = "#832479";
            el.style.width = "70px";
            el.style.height = "70px";
            el.style.lineHeight = "70px";
          }
          el.style.backgroundSize = "100%";
          el.innerHTML = String(htmlNumbers);

          // Add markers to the map.
          new mapboxgl.Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .addTo(this.map);
        }
      }
    },
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoianNtYjEyMSIsImEiOiJjbGFrdmxjdXkwNDM3M3ZvMDhpZnV6cG9oIn0.vKBIP9qrTUV2esADVho1Hw";
    this.map = new mapboxgl.Map({
      container: "map",
      // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
      style: "mapbox://styles/mapbox/streets-v12",
      center: [4.893495958806193, 52.297761435487445],
      zoom: 8,
    });

    // Map Fullscreen
    this.map.addControl(new mapboxgl.FullscreenControl());

    // this.map.on("load", () => {
    this.createPointer();
    // });

    this.map.on("click", "map", (e) => {
      console.log("XXX", e);
    });
  },
});
</script>
<style>
#map {
  width: 100%;
  height: 85vh;
}
</style>