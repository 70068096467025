const UserInfo = {
    state:{
        userInfo:JSON.parse(localStorage.getItem("user")),
        country:[]
    },
    getters: {
        getInfo(state){
            return state.userInfo
        },
        getCountry(state){
            return state.country
        }
    },
    mutations:{
        setuserInfo(state, userInfo){
            state.userInfo = userInfo
        },
        setCountry(state, country){
            state.country = country
        }
    },
    action:{
         setuseraction(context, data) {
             context.commit('setuserInfo', data)
        },
        setCountryaction(context, data) {
            context.commit('setCountry', data)
       }
    }
}
export default UserInfo