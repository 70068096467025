








































































































































































// import { Individual } from "@/classes/individual/Individual";
import Vue from "vue";
import store from "../store/index";
import usersData from "../../account-data.json";
import filterData from "../../src/Filter.json";
export default Vue.extend({
  name: "UserAccount",
  data() {
    return {
      n: 0,
      options: {},
      users: usersData,
      allusers: usersData,
      filterDataa: filterData,
      select: "",
    };
  },
  methods: {
    nameClicked(item) {
      this.$router.push("/persondashboard/" + item.ID_Veeva);
    },
    viewDetails(item) {
      this.$router.push("/details2/" + item.ID_Veeva);
    },
    customFilter(filtername) {
      if (this.select !== "") {
        this.users = this.allusers;
        this.users = this.users.filter(
          (item) => item[filtername] === this.select
        );
      }
    },
  resetFilter(){
    this.users=this.allusers
    this.select = "";
  }
  },
  computed: {
    headers() {
      return [
        { text: "Name", value: "Name" },
        { text: "Organisation", value: "Organisation" },
        { text: "Country", value: "CountryCode" },
        {
          text: "Specialty 1",
          value: "Specialty_1",
        },
        {
          text: "Specialty 2",
          value: "Specialty_2",
        },
        { text: "TA", value: "TA1" },
        { text: "Ranking", value: "Ranking" },
        { text: "Action", value: "ID_Veeva" },
      ];
    },
  },
  watch: {
    options: {
      handler() {
        store.dispatch("getIndividuals", {
          offset: (this.options.page - 1) * this.options.itemsPerPage,
          pageSize: this.options.itemsPerPage,
        });
      },
    },
  },
});
