<template>
    <div class="loginWrapper">
        <v-card class="pa-5 loginCard" width="400px">
            <span class="mb-4">Login</span>
            <v-text-field outlined class="mt-2" placeholder="username" v-model="username" required>
            </v-text-field>
            <v-text-field outlined class="mt-2" placeholder="password" v-model="password" required> </v-text-field>

            <v-btn rounded block class="mb-5" color="#0d60a5" dark @click="loginCheck">
                Login
            </v-btn>
        </v-card>
        <v-alert v-if="isInvalid" id="alert" type="error">Invalid Credential</v-alert>
    </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
    name: "AdminLogin",
    data() {
        return {
            username: "",
            password: "",
            isInvalid: false
        };
    },
    components: {},
    methods: {
        async loginCheck() {
            if (this.username == 'john.servaes@dj-technologysolutions.com' && this.password == 'kwm0mbe5rxt6RCP.evc') {
                const person = {
                    adminName: 'john.servaes@dj-technologysolutions.com'
                }
                localStorage.setItem(
                    "isAdminLogged",
                    JSON.stringify(person)
                );
                this.$router.push('/adminDashboard');
            } else {
                this.username = "",
                    this.password = "",
                    this.isInvalid = true;
                setTimeout(() => {
                    this.isInvalid = false;
                }, 4000)

            }
        }
    },
});
</script>