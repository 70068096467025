<template>
  <v-card class="pa-3 mt-2">
    <div class="journey-chart-title text-center">
      <span>Customer Journey - Cerebrum</span>
      <span v-if="this.node.length == 0" class="default">
        No coustomer journey available yet
      </span>
      <div id="chart-container"></div>
    </div>
  </v-card>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import FusionCharts from "fusioncharts";

const dataSource = {
  chart: {
    theme: "fusion",
    linkalpha: 40,
    linkhoveralpha: 60,
    nodelabelposition: "start",
    showLegend: 0,
    borderColor: "#fff",
    bgColor: "#ffffff",
    nodeWidth: "5",
    labelFontColor: "#8d8e91",
    // "labelFontBold": "1",
    // "labelBgColor": "ffffff",
    "showToolTipShadow": "0"
  },

  nodes: [
    {
      label: "Unaware",
    },
    {
      label: "Aware",
    },
    {
      label: "Trialist",
    },
    {
      label: "Frequent Prescriber",
    },
    {
      label: "Brand Advocate",
    },
    {
      label: "Email Unsubscribe",
    },
  ],
  links: [],
};
export default Vue.extend({
  name: "FusionChartSvg",
  data: function () {
    return {
      node: [],
    };
  },
  created() {
    this.getSegment();
    this.getNode();
  },
  methods: {
    async getSegment() {
      var res = await axios.get(`${process.env.VUE_APP_API}/segment`);
      this.segments = res.data;
    },
    async getNode() {
      var idEva = this.$route.params;
      var res = await axios.get(`${process.env.VUE_APP_API}/node`);
      this.node = res.data.filter((item) => {
        if (item.ID_Veeva == idEva.id) {
          return item;
        }
      });
      dataSource.links = this.node;
      FusionCharts.ready(function () {
        new FusionCharts({
          type: "sankey",
          renderAt: "chart-container",
          width: "100%",
          dataFormat: "json",
          dataSource,
        }).render();
      });
    },
  },
});
</script>
<style scoped>
.sankeyChart div {
  padding: 1px;
  padding-left: 3px;
}

.indicator {
  display: flex;
  padding: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
}
.indicator div {
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}
.indicator div > span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: block;
  margin-right: 10px;
}
.sankeyContainer span {
  visibility: hidden;
}
.sankeyContainer:hover .sankeyContainer span {
  visibility: visible !important;
}
.journey-chart-title {
  position: relative;
}
.default {
  position: absolute;
  top: 50%;
  left: 35%;
  z-index: 1;
  color: #b0b2b8;
  text-transform: capitalize;
}
</style>
