













































































import Vue from "vue";
import VueFullscreen from "vue-fullscreen";
Vue.use(VueFullscreen);
export default Vue.extend({
  name: "Sliders",
  data() {
    return {
      slider: true,
      counterNumber: "1",
      fullscreen: false,
      teleport: true,
      pageOnly: false,
      mb: false,
      sliders: [
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_1/index.html",
          src: "assets/thumbnails/Lonsurf_slide_1.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_18/index.html",
          src: "assets/thumbnails/Lonsurf_slide_18.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_19/index.html",
          src: "assets/thumbnails/Lonsurf_slide_19.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_20/index.html",
          src: "assets/thumbnails/Lonsurf_slide_20.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_2/index.html",
          src: "assets/thumbnails/Lonsurf_slide_2.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_3/index.html",
          src: "assets/thumbnails/Lonsurf_slide_3.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Onivyde_slide_1/index.html",
          src: "assets/thumbnails/Onyvide_slide_1.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Oncology_slide_2/index.html",
          src: "assets/thumbnails/Oncology_slide_2.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_1/index.html",
          src: "assets/thumbnails/Lonsurf_slide_1.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_18/index.html",
          src: "assets/thumbnails/Lonsurf_slide_18.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_19/index.html",
          src: "assets/thumbnails/Lonsurf_slide_19.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_20/index.html",
          src: "assets/thumbnails/Lonsurf_slide_20.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_2/index.html",
          src: "assets/thumbnails/Lonsurf_slide_2.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_3/index.html",
          src: "assets/thumbnails/Lonsurf_slide_3.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Onivyde_slide_1/index.html",
          src: "assets/thumbnails/Onyvide_slide_1.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Oncology_slide_2/index.html",
          src: "assets/thumbnails/Oncology_slide_2.png",
        },
      ],
    };
  },
  methods: {
    fulscreen() {
      this.fullscreen = !this.fullscreen;
      this.slider = false;
      this.mb = true;
    },
    counter(index) {
      console.log("hbhjjh", index);
      this.counterNumber = index + 1;
    },
  },
});
