import { Activity } from "@/classes/individual/Activity";
import { Individual, IndividualPayload } from "@/classes/individual/Individual";
import { Workplace } from "@/classes/individual/Workplace";
import { apiV1 } from "./api";
import { ActivityResponse } from "./models/AcitivtyResponse";
import { IndividualResponse, IndividualResponseData } from "./models/IndividualResponse";
import { WorkplaceResponse } from "./models/WorkplaceResponse";

class IndividualsService {
    async getIndividuals(offset: number, pageSize: number, authToken: string): Promise<IndividualPayload> {
        return apiV1.get(
            '/individuals',
            {
                params: {
                    'page[offset]': offset,
                    'page[limit]': pageSize
                },
                headers: {
                    Authorization: 'Bearer ' + authToken
                }
            }).then(response => {
                const data: IndividualResponse = response.data
                const indiviualPayload: IndividualPayload = {
                    offset: offset,
                    totalRecords: data.meta.totalRecords,
                    individuals: data.data.map((individual) => (this.mapIndividual(individual)))
                }
                return indiviualPayload
            }

            )
    }

    async getIndividual(id: string, authToken: string): Promise<Individual> {
        return apiV1.get(
            '/individuals/' + id,
            {
                headers: {
                    Authorization: 'Bearer ' + authToken
                }
            }
        ).then(response => {
            return this.mapIndividual(response.data.data)
        })
    }

    async getWorkplaces(id: string, authToken: string): Promise<Workplace[]> {
        return apiV1.get(
            '/individuals/' + id + '/workplaces',
            {
                headers: {
                    Authorization: 'Bearer ' + authToken
                }
            }
        ).then(response => {
            const data: WorkplaceResponse[] = response.data.data
            return data.map((workplace) => (this.mapWorkplace(workplace)))
        })
    }

    async getActivities(id: string, authToken: string): Promise<Activity[]> {
        return apiV1.get(
            '/individuals/' + id + '/activities',
            {
                headers: {
                    Authorization: 'Bearer ' + authToken
                }
            }
        ).then(response => {
            const data: ActivityResponse[] = response.data.data
            return data.map((activity) => (this.mapAcitivty(activity)))
        })
    }

    mapAcitivty(activity: ActivityResponse): Activity {
        return {
            type: activity.type,
            id: activity.id,
            attributes: {
                activity_id: activity.attributes.activity_id,
                country_code: activity.attributes.country_code,
                activity_survivor_id: activity.attributes.activity_survivor_id,
                activity_sequence_number: activity.attributes.activity_sequence_number,
                primary_activity: activity.attributes.primary_activity,
                start_date_of_activity: activity.attributes.start_date_of_activity,
                end_date_of_activity: activity.attributes.end_date_of_activity,
                working_status_code: activity.attributes.working_status_code,
                working_status_label: activity.attributes.activity_state_label,
                activity_telephone_number: activity.attributes.activity_telephone_number,
                activity_telephone_extension: activity.attributes.activity_telephone_extension,
                activity_status_code: activity.attributes.working_status_code,
                activity_status_label: activity.attributes.working_status_label,
                activity_state_code: activity.attributes.activity_state_code,
                activity_state_label: activity.attributes.activity_state_label,
                activity_creation_date: activity.attributes.activity_creation_date,
                date_of_last_activity_update: activity.attributes.date_of_last_activity_update,
                reason_for_last_activity_update_code: activity.attributes.reason_for_last_activity_update_code,
                reason_for_last_activity_update_label: activity.attributes.reason_for_last_activity_update_label,
                activity_general_stop_flag: activity.attributes.activity_general_stop_flag,
                activity_stop_flag_for_visit: activity.attributes.activity_stop_flag_for_visit,
                activity_stop_flag_for_fax: activity.attributes.activity_stop_flag_for_fax,
                activity_stop_flag_for_tel: activity.attributes.activity_stop_flag_for_tel
            }
        }
    }

    mapWorkplace(workplace: WorkplaceResponse): Workplace {
        return {
            type: workplace.type,
            id: workplace.id,
            attributes: {
                workplace_id: workplace.attributes.workplace_id,
                country_code: workplace.attributes.country_code,
                workplace_survivor_id: workplace.attributes.workplace_survivor_id,
                workplace_name: workplace.attributes.workplace_name,
                official_workplace_name: workplace.attributes.official_workplace_name,
                workplace_location_code: workplace.attributes.workplace_location_code,
                workplace_location_label: workplace.attributes.workplace_location_label,
                workplace_type_code: workplace.attributes.workplace_type_code,
                workplace_type_label: workplace.attributes.workplace_type_label,
                workplace_structure_type_code: workplace.attributes.workplace_structure_type_code,
                workplace_structure_type_label: workplace.attributes.workplace_structure_type_label,
                workplace_telephone_number: workplace.attributes.workplace_telephone_number,
                workplace_fax: workplace.attributes.workplace_fax,
                workplace_status_code: workplace.attributes.workplace_status_code,
                workplace_status_label: workplace.attributes.workplace_status_label,
                workplace_state_code: workplace.attributes.workplace_state_code,
                workplace_state_label: workplace.attributes.workplace_state_label,
                workplace_creation_date: workplace.attributes.workplace_creation_date,
                date_of_last_workplace_update: workplace.attributes.date_of_last_workplace_update,
                reason_for_last_workplace_update_code: workplace.attributes.reason_for_last_workplace_update_code,
                reason_for_last_workplace_update_label: workplace.attributes.reason_for_last_workplace_update_label,
                workplace_stop_flag_for_visit: workplace.attributes.workplace_stop_flag_for_visit,
                workplace_stop_flag_for_fax: workplace.attributes.workplace_stop_flag_for_fax,
                workplace_stop_flag_for_tel: workplace.attributes.workplace_stop_flag_for_tel,
                p_address_label: workplace.attributes.p_address_label,
                p_long_postal_code: workplace.attributes.p_long_postal_code,
                p_postal_code: workplace.attributes.p_postal_code,
                p_city_name: workplace.attributes.p_city_name,
                p_postal_city_name: workplace.attributes.p_postal_city_name,
                p_county_code: workplace.attributes.p_county_code,
                p_county_label: workplace.attributes.p_county_label,
                parent_workplace_id: workplace.attributes.parent_workplace_id,
                parent_workplace_name: workplace.attributes.parent_workplace_name,
                main_workplace_id: workplace.attributes.main_workplace_id,
                main_workplace_name: workplace.attributes.main_workplace_name,
                bricks: workplace.attributes.bricks.map((brick) => ({
                    code: brick.code,
                    label: brick.label
                })),
                workplace_specialities: workplace.attributes.workplace_specialities.map((speciality) => ({
                    code: speciality.code,
                    label: speciality.label
                }))
            }
        }
    }

    mapIndividual(individual: IndividualResponseData): Individual {
        return {
            type: individual.type,
            id: individual.id,
            attributes: {
                individual_id: individual.attributes.individual_id,
                country_code: individual.attributes.country_code,
                individual_survivor_id: individual.attributes.individual_survivor_id,
                first_name: individual.attributes.first_name,
                last_name: individual.attributes.last_name,
                gender_code: individual.attributes.gender_code,
                gender_label: individual.attributes.gender_label,
                courtesy_title_code: individual.attributes.courtesy_title_code,
                courtesy_title_label: individual.attributes.courtesy_title_label,
                title_code: individual.attributes.title_code,
                title_label: individual.attributes.title_label,
                professional_type_code: individual.attributes.professional_type_code,
                professional_type_label: individual.attributes.professional_type_label,
                individual_status_code: individual.attributes.individual_status_code,
                individual_status_label: individual.attributes.individual_status_label,
                individual_state_code: individual.attributes.individual_state_code,
                individual_state_label: individual.attributes.individual_state_label,
                individual_creation_date: individual.attributes.individual_creation_date,
                date_of_last_individual_update: individual.attributes.date_of_last_individual_update,
                reason_for_last_individual_update_code: individual.attributes.reason_for_last_individual_update_code,
                reason_for_last_individual_update_label: individual.attributes.reason_for_last_individual_update_label,
                spoken_language: individual.attributes.spoken_language,
                individual_general_stop_flag: individual.attributes.individual_general_stop_flag,
                individual_stop_flag_for_visit: individual.attributes.individual_stop_flag_for_visit,
                individual_stop_flag_for_fax: individual.attributes.individual_stop_flag_for_fax,
                individual_stop_flag_for_tel: individual.attributes.individual_stop_flag_for_tel,
                thesyear: individual.attributes.thesyear,
                birth_year: individual.attributes.birth_year,
                birth_day: individual.attributes.birth_day,
                roles: individual.attributes.roles.map((item) => ({
                    role_code: item.role_code,
                    role_label: item.role_label
                })),
                individual_specialities: individual.attributes.individual_specialities.map((item) => ({
                    speciality_code: item.speciality_code,
                    speciality_label: item.speciality_label
                }))
            }
        }
    }
}

export const individualsService = new IndividualsService()