import axios from "axios";
import { aws4Interceptor } from "aws4-axios";

const instanceV1 = axios.create({
  baseURL: "" + process.env.VUE_APP_BASE_URL_V1,
  timeout: 4000,
});

instanceV1.interceptors.request.use((request) => {
  return request;
});

instanceV1.interceptors.response.use((response) => {
  return response;
});

const AWS_Instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_LOCAL,
  timeout: 4000,
});

const interceptor = aws4Interceptor(
  {
    region: process.env.VUE_APP_AWS_REGION,
    service: process.env.VUE_APP_AWS_SERVICE_NAME,
  },
  {
    accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
  }
);

const AWS_instanceses = axios.create({
  baseURL: "" + process.env.VUE_APP_AWS_ENDPOINT,
  timeout: 8000,
});

const interceptor_ses = aws4Interceptor(
  {
    region: process.env.VUE_APP_AWS_REGION,
    service: "ses",
  },
  {
    accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
  }
);

AWS_instanceses.interceptors.request.use(interceptor_ses);

AWS_instanceses.interceptors.response.use((response) => {
  return response;
});

AWS_Instance.interceptors.request.use(interceptor);

export const apiV1 = instanceV1;
export const AWS_api = AWS_Instance;
export const AWS_api_ses = AWS_instanceses;
