<template>
  <div :class="item.mainIndex ? 'box start-box' : 'box'">
    <v-card
      class="pa-1 mb-1 registration-box progress"
      style="max-width: 200px"
    >
      <div class="plusTwo" @click="showEmailpopup(item)">
        <i class="bi bi-plus"></i>
      </div>
      <v-row class="no-gutters box-status-content">
        <v-col cols="12">
          <div
            class="box-icon-color"
            :class="[
              item.status == 'Published' ? 'backGreen' : '',
              item.status == 'Review' ? 'backYellow' : '',
              item.status == 'Draft' ? 'backOrange' : '',
              item.status == 'Error' ? 'backRed' : '',
            ]"
          >
            <!-- <span class="small backOrange">Invalid</span> -->
            <v-spacer></v-spacer>
            <span v-if="item && item.status" class="small">{{
              item.status
            }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row class="no-gutters">
        <div class="hr-line"></div>
        <div class="plus" @click="showEmailpopup(item)" v-if="access.canEdit">
          <i class="bi bi-plus"></i>
        </div>
        <div class="plus" v-if="!access.canEdit">
          <i class="bi bi-plus"></i>
        </div>

        <div class="arrow-nerve">
          <i class="bi bi-play-fill"></i>
        </div>
        <v-col>
          <h4 class="main-title pointer" @click="pushToJourney(item)">
            {{ item.name ? item.name : "" }}
          </h4>
        </v-col>
      </v-row>
      <v-row class="no-gutters box-Content">
        <v-col class="box-icon-color dark-blue">
          <span v-if="item && item.displayType" class="small text-muted">{{
            item.displayType
          }}</span>
        </v-col>
        <v-col cols="auto">
          <span
            class="action-btn action-btn1 mr-1 pointer"
            @click="openDeletePopup()"
          >
            <i class="bi bi-trash"></i>
          </span>
          <span
            class="action-btn action-btn2 pointer"
            @click="openEditTouchpointDialog()"
          >
            <i class="bi bi-pencil-square"></i>
          </span>
        </v-col>
      </v-row>
    </v-card>
    <TreeItem
      :access="access"
      :items="item.children"
      @clickitempopup="showEmailpopup($event)"
      :getData="getData"
    />
    <EditTouchpoint :item="item" v-model="editTouchpoint" />
    <v-dialog v-model="deleteConfirmPopup" max-width="350px">
      <v-card class="pa-4 pt-5">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-icon @click="deleteConfirmPopup = false" class="pointer"
            >mdi-window-close</v-icon
          >
        </v-card-actions>
        <v-card-text>
          <v-radio-group v-model="ex7">
            <v-radio
              label="Link to existing tile in journey"
              value="linkChild"
              @change="linkChild = false"
            ></v-radio>
            <div :class="{ linkChild: linkChild }">
              <v-select
                solo
                class="option my-4"
                dense
                height="40"
                :items="allJourneys"
                item-text="name"
                item-value="_id"
                hide-details
                placeholder="Select Child"
                :v-model="child"
                @change="($event) => (this.child = $event)"
              ></v-select>
            </div>
            <v-radio
              label="Remove all child tiles"
              value="removeAll"
              @change="linkChild = true"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            min-width="150"
            color="#0d60a5"
            dark
            depressed
            class="btn"
            @click="actionDeleteBehaviour(item)"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TreeItem from "./TreeItem.vue";
import EditTouchpoint from "../components/EditTouchpoint.vue";
import axios from "axios";
import Vue from "vue";
export default Vue.extend({
  name: "Tree",
  components: {
    TreeItem,
    EditTouchpoint,
  },
  props: {
    item: { type: Object },
    getData: { type: Function },
    access: { type: Object },
  },
  data: () => ({
    showProfileCard: false,
    editTouchpoint: false,
    deleteConfirmPopup: false,
    linkChild: true,
    ex7: "",
    allJourneys: [],
    child: "",
    breadCrumb: "",
  }),
  methods: {
    async pushToJourney(item) {
      if (item.displayType === "Journey") {
        var parameters = this.$route.query;
        this.$router.push(
          "/emailtouchpoint2?masterDataId=" +
            this.item.journeyId +
            "&journeyName=" +
            this.item.journeyName +
            "&previousId=" +
            this.item.masterDataId +
            "&previousName=" +
            parameters.journeyName
        );
      } else {
        this.$router.push(
          "/currentSegment?parent=" + item.Parent + "&journyId=" + item._id
        );
      }
      let data = {};
      data[this.item.name] = this.item.journeyId;
      var oldData = localStorage.getItem("YourItem");
      localStorage.setItem(
        "YourItem",
        JSON.stringify({ ...JSON.parse(oldData), ...data })
      );
      oldData = localStorage.getItem("YourItem");
      Object.keys(JSON.parse(oldData));
      window.location.reload();
    },
    showEmailpopup(event) {
      this.$emit("showEmailpopup", event);
    },
    openEditTouchpointDialog() {
      this.editTouchpoint = true;
    },
    async openDeletePopup() {
      this.deleteConfirmPopup = true;
      var response = await axios.get(
        `${process.env.VUE_APP_API}/journeyData/get-all`
      );
      this.allJourneys = response.data;
    },
    async actionDeleteBehaviour(event) {
      if (this.ex7 == "removeAll") {
        await axios.delete(`${process.env.VUE_APP_API}/journeyData/` + event._id);
        this.getData();
        this.deleteConfirmPopup = false;
      }
      if (this.ex7 == "linkChild") {
        await axios.delete(`${process.env.VUE_APP_API}/journeyData/` + event._id);
        const ALLDATA = await axios.get(
          `${process.env.VUE_APP_API}/journeyData/get-uisng-parent?Parent=` +
            event._id
        );
        var Parent = this.child;
        ALLDATA.data.map(async (item) => {
          await axios.put(`${process.env.VUE_APP_API}/journeyData/` + item._id, {
            Parent: Parent,
          });
        });
        this.getData();
        this.deleteConfirmPopup = false;
        window.location.reload();
      }
    },
  },
});
</script>
<style>
.box {
  display: flex;
  margin-left: 50px;
}

.relative {
  position: relative;
}

.relative::before {
  content: "";
  width: 2px;
  height: 100%;
  height: calc(100% - 221px);
  background: #30aac0;
  position: absolute;
  top: 45px;
  left: 22px;
}
.child-box {
  position: relative;
}
.relative .child-box:not(:first-child):after {
  content: "";
  position: absolute;
  top: 50px;
  height: 2px;
  width: 29px;
  background: #30aac0;
  left: 22px;
}
.relative .child-box:not(:first-child):last-child:before {
  content: "";
  position: absolute;
  top: -70px;
  height: 120px;
  width: 2px;
  background: #30aac0;
  left: 22px;
}
/* .relative .child-box:not(:first-child):before {
  content: "";
  position: absolute;
  top: -70px;
  height: 100%;
  width: 2px;
  background: #30aac0;
  left: 22px;
} */
.registration-box {
  width: 200px;
}
.registration-box {
  position: relative;
}
.plusTwo {
  position: absolute;
  left: -42px;
  background: #fff;
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 21px;
  border-radius: 50% !important;
  font-size: 23px;
  color: #30aac0;
  cursor: pointer;
  z-index: 1;
  top: 46%;
  transform: translateY(-50%);
}

/* .box{
  position: relative;
}
.child-box {
  width: 200px;
  height: auto;
  padding: 5px;
  margin-left: 281px;
  position: relative;
}

.box-status-content {
  display: flex;
  justify-content: right;
}


.hr-line::after {
  content: "";
  position: absolute;
  top: 50px;
  height: 2px;
  width: 48px;
  background: #30aac0;
  left: -48px;
}

.box > .registration-box + .relative {
  top: -119px;
  position: relative;
}

.box > .registration-box + .relative::before {
      content: "";
    width: 2px;
    height: 100%;
    height: calc( 100% - 118px);
    background: #30aac0;
    position: absolute;
    top: 53px;
    left: 240px;
} */
.text-green {
  color: #55c343;
}
.box-back span {
  background: #e9ffee;
}
.linkChild {
  display: none;
}
.box.start-box > .registration-box::after {
  content: "START";
  position: absolute;
  left: -121px;
  text-align: center;
  border-radius: 5px 5px 0px 0px;
  padding: 2px 0px;
  display: block;
  top: 50%;
  background: #72d2bd;
  width: 110px;
  transform: translateY(-50%) translateX(50%) rotate(-90deg);
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1px;
}
.box.start-box > .registration-box .plusTwo,
.box.start-box > .registration-box .box-Content {
  display: none;
}
.box.start-box > .registration-box {
  display: block;
}
</style>
