






























































































































import Vue from "vue";
import VoerroTagsInput from "@voerro/vue-tagsinput";
import EmailTouchpointPopup from "../components/EmailTouchpointPopup.vue";
import SurveyTouchpointPopup from "../components/SurveyTouchpointPopup.vue";
import WebpageTouchpointPopup from "../components/WebpageTouchpointPopup.vue";
import AlljourneyList from "../components/AlljourneyList.vue";
import axios from "axios";
Vue.component("tags-input", VoerroTagsInput);
export default Vue.extend({
  name: "EmailPopup",
  data: () => ({
    n: "",
    valid: false,
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
    openEmailTouchpoitAddPopup: false,
    openSurveyTouchpoitAddPopup: false,
    openWebpageTouchpoitAddPopup: false,
    openListOfJourney: false,
    search: "",
    Searchtoucpoint: [],
    isActive: true,
    alert: false,
  }),
  components: {
    EmailTouchpointPopup,
    SurveyTouchpointPopup,
    WebpageTouchpointPopup,
    AlljourneyList,
  },
  props: {
    value: Boolean,
    toucpoint: Array,
    getFun: { type: Function },
    parentId: String,
    masterData: Object,
  },
  computed: {
    show: {
      get() {
        this.searchTouchpoint();
        return this.value;
      },
      set(value: boolean) {
        (this as any).$emit("input", value);
      },
    },
  },
  methods: {
    openAllJourneyList() {
      this.openListOfJourney = true;
      this.$emit("input", false);
    },
    async addEmailTemplte(name) {
      this.$emit("showPdf", name);
      this.$emit("input", false);
    },
    trialistPop() {
      this.openEmailTouchpoitAddPopup = true;
      this.$emit("input", false);
    },
    sendEmail() {
      this.openSurveyTouchpoitAddPopup = true;
      this.$emit("input", false);
    },
    website() {
      this.openWebpageTouchpoitAddPopup = true;
      this.$emit("input", false);
    },
    emitEmailContent(event) {
      this.$emit("emailContent", event);
    },
    emitSurveyContent(event) {
      this.$emit("submitSurvey", event);
    },
    emitWebpageContent(event) {
      this.$emit("webpageData", event);
    },
    searchTouchpoint() {
      this.Searchtoucpoint = this.toucpoint;
      if (this.search) {
        this.Searchtoucpoint = this.toucpoint.filter((item) => {
          return item.touchpointName.toLowerCase().includes(this.search.toLowerCase());
        });
      }
    },
  },
  mounted() {
    this.getFun();
  },
});
