

























































import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  name: "TouchpointModal",
  data: () => ({
    touchpointName: "",
    category: ["pharma", "travel"],
    status: ["Invalid", "Archived", "Published"],
    product: ["N/A", "Cerebrum"],
    date: "",
    appCategory: "",
    appStatus: "",
  }),
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        (this as any).$emit("input", value);
      },
    },
  },
  methods: {
    async createTouchpoint() {
      var data = {
        touchpointName: this.touchpointName,
        category: this.appCategory,
        tag: this.appCategory,
        status: this.appStatus,
        date: "25.01.2021",
        isActivate: false,
        displayType: "Email Template",
        type: "Email",
        customouchpoint: false,
      };
      await axios.post(`${process.env.VUE_APP_API}/touchpointTiles`, data);
      this.$emit("input", false);
    },
  },
});
