import { Activity } from "@/classes/individual/Activity"
import { Individual } from "@/classes/individual/Individual"
import { Workplace } from "@/classes/individual/Workplace"
import { individualsService } from "@/remote/IndividualsService"

import { authToken } from "@/auth/authToken";

const state: State = {
    individuals: [],
    offset: 0,
    totalRecords: 0,
    pageSize: 10,
    personDetails: null,
    workplaces: [],
    activities: [],
}

const individualsStore = {
    state,
    getters: {
        getIndividuals(state: State): Individual[] {
            return state.individuals
        },
        getTotalRecords(state: State): number {
            return state.totalRecords;
        },
        getPersonDetails(state: State): Individual {
            return state.personDetails
        },
        getWorkplaces(state: State): Workplace[] {
            return state.workplaces
        },
        getActivities(state: State): Activity[] {
            return state.activities
        }
    },
    mutations: {
        setIndividuals(state: State, individuals: Individual[]) {
            state.individuals = individuals
        },
        setTotalRecords(state: State, totalRecords: number) {
            state.totalRecords = totalRecords
        },
        setOffset(state: State, offset: number) {
            state.offset = offset
        },
        setPageSize(state: State, pageSize: number) {
            state.pageSize = pageSize
        },
        setPersonDetails(state: State, person: Individual) {
            state.personDetails = person
        },
        setIndividualWorkplaces(state: State, workplaces: Workplace[]) {
            state.workplaces = workplaces
        },
        setActivities(state: State, activities: Activity[]) {
            state.activities = activities
        }
    },
    actions: {
        getIndividuals(context, { offset, pageSize }) {
            authToken().then(token => {
                individualsService.getIndividuals(offset, pageSize, token)
                    .then(response => {
                        context.commit('setIndividuals', response.individuals)
                        context.commit('setTotalRecords', response.totalRecords)
                        context.commit('setOffset', offset)
                    })
            })
        },
        getIndividual(context, id: string) {
            authToken().then(token => {
                individualsService.getIndividual(id, token)
                    .then(response => {
                        context.commit('setPersonDetails', response)
                    })
            })

        },
        getWorkplaces(context, id: string) {
            authToken().then(token => {
                individualsService.getWorkplaces(id, token)
                    .then(response => {
                        context.commit('setIndividualWorkplaces', response)
                    })
            })
        },
        getActivities(context, id: string) {
            authToken().then(token => {
                individualsService.getActivities(id, token)
                    .then(response => {
                        context.commit('setActivities', response)
                    })
            })
        }
    }
}
export default individualsStore

interface State {
    individuals: Individual[],
    totalRecords: number,
    offset: number,
    pageSize: number,
    personDetails: Individual,
    workplaces: Workplace[],
    activities: Activity[]
}