<template>
  <v-card class="pa-3 mt-2">
    <div class="journey-chart-title text-center">
      <span>Doctor Assigned</span>
      <v-data-table
        :items="users"
        :headers="headers"
        :custom-filter="customFilter"
        :items-per-page="10"
        :options.sync="options"
        item-key="name"
        class="elevation-0 mt-2"
        :footer-props="{
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right',
          'items-per-page-options': [10, 20, 30, 40, 50],
        }"
      >
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import usersData from "../../account-data.json";
export default Vue.extend({
  name: "FusionChartSvg2",
  data: function () {
    return {
      users: usersData,
      select: "",
      options:{}
    };
  },
  created() {
    this.getSegment();
    this.customFilter();
  },
  methods: {
    async getSegment() {
      var res = await axios.get(`${process.env.VUE_APP_API}/segment`);
      this.segments = res.data;
    },
    customFilter() {
      var idEva = this.$route.params;
        this.users = this.users.filter((item) => {
            return item.Organisation == idEva.id
        })
    },
  },
  computed: {
    headers() {
      return [
        { text: "Name", value: "Name" },
        { text: "Country", value: "CountryCode" },
        {
          text: "Specialty 1",
          value: "Specialty_1",
        },
        {
          text: "Specialty 2",
          value: "Specialty_2",
        },
        { text: "TA", value: "TA1" },
      ];
    },
  },
  watch: {
    options: {
      handler() {
        store.dispatch("getIndividuals", {
          offset: (this.options.page - 1) * this.options.itemsPerPage,
          pageSize: this.options.itemsPerPage,
        });
      },
    },
  },
});
</script>
<style scoped>
.sankeyChart div {
  padding: 1px;
  padding-left: 3px;
}

.indicator {
  display: flex;
  padding: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
}
.indicator div {
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}
.indicator div > span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: block;
  margin-right: 10px;
}
.sankeyContainer span {
  visibility: hidden;
}
.sankeyContainer:hover .sankeyContainer span {
  visibility: visible !important;
}
.journey-chart-title {
  position: relative;
}
.default {
  position: absolute;
  top: 50%;
  left: 35%;
  z-index: 1;
  color: #b0b2b8;
  text-transform: capitalize;
}
</style>
