


















































































































































































































































































































































































































































import Vue from "vue";
import axios from "axios";
import icons from "./../locales/iconList.json";
export default Vue.extend({
  name: "DashboardAdmin",
  data() {
    return {
      listOption: false,
      dialog: false,
      showListId: 0,
      saveMenuChild: false,
      menuType: ["Page", "Folder"],
      addmenuBox: false,
      menuShow: true,
      listshow: false,
      folderSheet: false,
      openBox: false,
      icons: icons,
      action: ["Tableau", "Power BI"],
      roleLIST: ["admin", "commercial", "medical"],
      modal: false,
      value: false,
      name: "",
      description: "",
      type: "",
      role: "",
      allData: [],
      url: "",
      menus: [],
      menuValue: "",
      path: "",
      imageUrl: "",
      edit: false,
      childID: "",
      save: true,
      parentID: "",
      dashboardID: "",
      lol: "",
      selected: "",
      icon: "",
      allObject: {},
      children: [],
    };
  },
  created() {
    this.getDashboard();
    this.getMenu();
  },
  computed: {
    getRole(): any {
      return this.$store.getters.getRole;
    },
  },
  methods: {
    openOPtion(id) {
      this.listOption = !this.listOption;
      this.showListId = id;
    },
    async addmenutoChildren() {
      let res;
      var childData = {
        parentID: this.allObject._id,
        title: this.name,
        path: "/dashboardviewPage/" + this.name,
        icon: this.icon,
        role: this.role,
      };
      res = await axios.put(
        `${process.env.VUE_APP_API}/child-menus/6328129c3425f4d7770bda04/` +
          this.allObject._id,
        childData
      );

      let data = {
        name: this.name,
        type: this.type,
        role: this.role,
        url: this.url,

        icon: this.icon,
        menuId: res.data._id,
        parentID: this.allObject._id,
      };
      await axios.post(`${process.env.VUE_APP_API}/dashboradUrl`, data);
      this.addmenuBox = false;
      window.location.reload();
    },

    async addChildrenbox(item) {
      this.saveMenuChild = true;
      this.folderSheet = false;
      this.allObject = item;
    },
    async saveMenu() {
      var menuData = {
        children: {
          title: this.name,
          path: "/dashboardviewPage/",
          icon: this.icon,
          role: this.role,
        },
      };
      var res;
      res = await axios.put(
        `${process.env.VUE_APP_API}/menus/6328129c3425f4d7770bda04`,
        menuData
      );
      let data = {
        name: this.name,
        description: this.description,
        type: this.type,
        role: this.role,
        url: `    <iframe
        title="Demo dashboard EM"
        style="
          display: block;
          width: 100%;
          height: 1068px;
          margin: 0px;
          padding: 0px;
          border: none;
        "
        src=${this.url}:showVizHome=no&:embed=true
        frameborder="0"
        allowFullScreen="true"
      ></iframe>`,
        menu: this.menuValue.title == undefined ? "" : this.menuValue.title,
        icon: this.icon,
        menuId: res.data._id,
        parentID: res.data.parentID,
      };
      await axios.post(`${process.env.VUE_APP_API}/dashboradUrl`, data);
      this.addmenuBox = false;
      window.location.reload();
    },
    addMenu() {
      this.folderSheet = false;
      this.addmenuBox = true;
    },
    backpage() {
      this.menuShow = true;
      this.listshow = false;
    },
    showChildrenList(item) {
      this.menuShow = false;
      this.listshow = true;
      this.children = item.children.map((i) => {
        return i;
      });
    },
    onButton(item) {
      this.openBox = false;
      this.icon = item;
    },



    async saveDashboard() {
      var menuData = {
        children: {
          title: this.name,
          path: "/dashboardviewPage/" + this.name,
          icon: this.icon,
          role: this.role,
        },
      };
      let response;
      if (this.menuValue.title) {
        var childData = {
          parentID: this.menuValue._id,
          title: this.name,
          path: "/dashboardviewPage/" + this.name,
          icon: this.icon,
          role: this.role,
        };
        response = await axios.put(
          `${process.env.VUE_APP_API}/child-menus/6328129c3425f4d7770bda04/` +
            this.menuValue._id,
          childData
        );
      } else {
        response = await axios.put(
          `${process.env.VUE_APP_API}/menus/6328129c3425f4d7770bda04`,
          menuData
        );
      }
      let data = {
        name: this.name,
        description: this.description,
        type: this.type,
        role: this.role,
        url: `    <iframe
        title="Demo dashboard EM"

        style="
          display: block;
          width: 100%;
          height: 1068px;
          margin: 0px;
          padding: 0px;
          border: none;
        "
        src=${this.url}:showVizHome=no&:embed=true
        frameborder="0"
        allowFullScreen="true"
      ></iframe>`,
        menu: this.menuValue.title == undefined ? "" : this.menuValue.title,
        icon: this.icon,
        menuId: response.data._id,
        parentID: response.data.parentID,
      };
      await axios.post(`${process.env.VUE_APP_API}/dashboradUrl`, data);
      this.modal = false;
      window.location.reload();
    },






    async getDashboard() {
      let res = await axios.get(`${process.env.VUE_APP_API}/dashboradUrl/get-all`);
      return (this.allData = res.data);
    },
    async getMenu() {
      var res = await axios.get(
        `${process.env.VUE_APP_API}/menus/6328129c3425f4d7770bda04`
      );
      return (this.menus = res.data.data.children);
    },
    openEditBox(data) {
      (this.listOption = false), (this.modal = true);
      this.edit = true;
      this.save = false;

      this.name = data.name;
      this.description = data.description;
      this.url = data.url;
      this.type = data.type;
      this.role = data.role;
      this.menuValue = data.menu;
      this.childID = data.menuId;
      this.parentID = data.parentID;
      this.dashboardID = data._id;
      this.icon = data.icon;
      this.allObject = data;
    },
    async EditDashboard() {
      var menuData = {
        title: this.name,
        role: this.role,
        path: "/dashboardviewPage/" + this.name,
        icon: this.icon,
      };

      if (this.menuValue) {
        if (this.allObject.menu === this.menuValue) {
          await axios.put(
            `${process.env.VUE_APP_API}/update-g-childern/6328129c3425f4d7770bda04/` +
              this.parentID +
              "/" +
              this.childID,
            menuData
          );
          
        } else {
          if (this.allObject.parentID) {
            await axios.put(
              `${process.env.VUE_APP_API}/delete-g-childern/6328129c3425f4d7770bda04/` +
                this.allObject.parentID +
                "/" +
                this.allObject.menuId
            );
          } else {
            await axios.delete(
              `${process.env.VUE_APP_API}/delete-childern/6328129c3425f4d7770bda04/` +
                this.allObject.menuId
            );
          }

          var reseditedData = await axios.put(
            `${process.env.VUE_APP_API}/child-menus/6328129c3425f4d7770bda04/` +
              this.menuValue._id,
            menuData
          );
          var data = {
            name: this.name,
            description: this.description,
            url: `    <iframe
          title="Demo dashboard EM"
          style="
            display: block;
            width: 100%;
            height: 1068px;
            margin: 0px;
            padding: 0px;
            border: none;
          "
          src=${this.url}:showVizHome=no&:embed=true
          frameborder="0"
          allowFullScreen="true"
        ></iframe>`,
            type: this.type,
            role: this.role,
            icon: this.icon,
            menu: this.menuValue.title,
            menuId: reseditedData.data._id,
            parentID: this.menuValue._id,
          };

          await axios.put(
            `${process.env.VUE_APP_API}/dashboradUrl/` + this.dashboardID,
            data
          );
        }
      } else {
        var resData = await axios.put(
          `${process.env.VUE_APP_API}/update-childern/6328129c3425f4d7770bda04/` +
            this.childID,
          menuData
        );
        var newData = {
          name: this.name,
          description: this.description,
          url: `    <iframe
          title="Demo dashboard EM"
          style="
            display: block;
            width: 100%;
            height: 1068px;
            margin: 0px;
            padding: 0px;
            border: none;
          "
          src=${this.url}:showVizHome=no&:embed=true
          frameborder="0"
          allowFullScreen="true"
        ></iframe>`,
          type: this.type,
          role: this.role,
          icon: this.icon,
          menu: this.menuValue == undefined ? "" : this.menuValue,
          menuId: resData.data._id,
          parentID: this.menuValue._id,
        };
        await axios.put(
          `${process.env.VUE_APP_API}/dashboradUrl/` + this.dashboardID,
          newData
        );
      }
      this.modal = false;
      this.edit = false;
      window.location.reload();
    },

    close() {
      this.edit = false;
      this.modal = false;
    },
    openCreationDashboard() {
      //   this.modal = true;
      this.folderSheet = true;
      this.save = true;
    },
    async deleteMenu(item) {
      if (!item.menu) {
        await axios.delete(
          `${process.env.VUE_APP_API}/delete-childern/6328129c3425f4d7770bda04/` +
            item.menuId
        );
      } else {
        await axios.put(
          `${process.env.VUE_APP_API}/delete-g-childern/6328129c3425f4d7770bda04/` +
            item.parentID +
            "/" +
            item.menuId
        );
      }

      await axios.delete(`${process.env.VUE_APP_API}/delete-dashboard/` + item._id);

      window.location.reload();
    },
  },
});
