






























































































import Vue from "vue";
import axios from "axios";
import EmailPopup from "../../components/emailPopup.vue";
import ViewPDF from "../../components/viewPdf.vue";
import GanttChart from "../../components/GanttChartPopup.vue";
import Tree from "../../components/Tree.vue";
import { nanoid } from "nanoid";
export default Vue.extend({
  name: "EmailTouchPoint2",
  components: {
    EmailPopup,
    ViewPDF,
    GanttChart,
    Tree,
  },
  data: () => ({
    masterData: {},
    journeyData: {},
    masterDataId: "",
    parentId: "",
    showNerve: {},
    openEmailpopup: false,
    openJourneyOverview: false,
    openPdf: false,
    openGanttChart: false,
    width: "1300px",
    toucpoint: [],
    journeyDataName: "",
    goBtn: true,
    breadCrumb: "",
    alert: true,
  }),
  computed: {
    emailtemplate() {
      return this.$store.getters.getEtemplate;
    },
  },
  methods: {
    showAlertBox() {
      this.alert = false;
      setTimeout(() => {
        this.alert = true;
      }, 2000);
    },
    onthisbredcumb(item) {
      var arrayData = JSON.parse(localStorage.getItem("YourItem"));
      this.$router.push(
        "/emailtouchpoint2?masterDataId=" +
          arrayData[item] +
          "&journeyName=" +
          item
      );
      var arrayKey = Object.keys(JSON.parse(localStorage.getItem("YourItem")));
      for (let i = 0; i < arrayKey.length; i++) {
        if (arrayKey.indexOf(item) >= i) {
          continue;
        }
        delete arrayData[arrayKey[i]];
      }

      localStorage.setItem("YourItem", JSON.stringify(arrayData));

      window.location.reload();
    },
    goBack() {
      this.$router.go(-1);
    },
    async webpage(websiteContent) {
      let data = {
        name: websiteContent.webpageName,
        masterDataId: this.masterDataId,
        Parent: this.parentId,
        status: "Published",
        type: "webpage link",
        displayType: "webpage link",
        history: [{ ...websiteContent }],
      };
      var dataForTouchpoint = {
        touchpointName: websiteContent.webpageName,
        masterDataId: this.masterDataId,
        Parent: this.parentId,
        type: "webpage link",
        tag: "Cerebrum",
        date: "25.01.2021",
        isActivate: true,
        category: "pharma",
        displayType: "webpage link",
        status: "Published",
        customTouchpoint: true,
      };
      await axios.post(
        `${process.env.VUE_APP_API}/touchpointTiles`,
        dataForTouchpoint
      );
      await axios.post(`${process.env.VUE_APP_API}/journeyData`, {
        ...data,
        ...websiteContent,
      });
      this.getData();
      this.getTouchpoint();
    },

    async emailContent(emailContent) {
        console.log('email hitted');
        
      let data = {
        masterDataId: this.masterDataId,
        Parent: this.parentId,
        type: "email",
        displayType: "email",
        history: [{ ...emailContent, historyId: nanoid() }],
      };
      var dataForTouchpoint = {
        touchpointName: emailContent.name,
        masterDataId: this.masterDataId,
        Parent: this.parentId,
        tag: "Cerebrum",
        date: "25.01.2021",
        isActivate: true,
        category: "pharma",
        type: "email",
        displayType: "email",
        status: "Published",
        customTouchpoint: true,
      };
      await axios.post(
        `${process.env.VUE_APP_API}/touchpointTiles`,
        dataForTouchpoint
      );
      await axios.post(`${process.env.VUE_APP_API}/journeyData`, {
        ...data,
        ...emailContent,
      });

      this.getData();
      this.getTouchpoint();
    },

    async survey(surveyContent) {
      let data = {
        masterDataId: this.masterDataId,
        Parent: this.parentId,
        status: "Published",
        type: "survey",
        displayType: "survey",
        history: [{ ...surveyContent }],
      };
      var dataForTouchpoint = {
        touchpointName: surveyContent.name,
        masterDataId: this.masterDataId,
        Parent: this.parentId,
        tag: "Cerebrum",
        date: "25.01.2021",
        isActivate: true,
        category: "pharma",
        type: "survey",
        displayType: "survey",
        status: "Published",
        customTouchpoint: true,
      };
      await axios.post(
        `${process.env.VUE_APP_API}/touchpointTiles`,
        dataForTouchpoint
      );
      await axios.post(`${process.env.VUE_APP_API}/journeyData`, {
        ...data,
        ...surveyContent,
      });
      this.getData();
      this.getTouchpoint();
    },
    convert(array) {
      var parameter = this.$route.query;
      var map = {};

      for (var i = 0; i < array.length; i++) {
        var obj = array[i];
        obj.children = [];

        map[obj._id] = obj;
        var parent = obj.Parent === this.masterDataId ? "-" : obj.Parent;

        if (!map[parent]) {
          map[parent] = {
            children: [],
          };
        }
        map[parent].children.push(obj);
      }

      return [
        {
          ...this.masterData.data,
          Parent: parameter.masterDataId,
          _id: parameter.masterDataId,
          children: map["-"]?.children,
        },
      ];
    },
    showJourney() {
      localStorage.setItem("Query", JSON.stringify(this.$route.query));
      var parameter = this.$route.query;
      this.$router.push(
        "/currentSegment?parent=0&journyId=" +
          this.journeyData[0].children[0]._id +
          "&masterDataId=" +
          parameter.masterDataId
      );
    },
    open(event) {
      this.parentId = event._id;
      this.openEmailpopup = true;
    },
    openGantt() {
      this.openGanttChart = true;
      setTimeout(() => {
        this.width = "1305px";
      }, 150);
    },
    getData() {
      axios
        .get(
          `${process.env.VUE_APP_API}/journeyData?masterDataId=` +
            this.masterDataId +
            "&_sort=id&_order=asc"
        )
        .then((response) => {
          
          this.journeyData = this.convert(response.data.data);
          console.log('XXX',this.journeyData[0].mainIndex);
          this.journeyData[0].mainIndex = true;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    getTouchpoint() {
      axios
        .get(`${process.env.VUE_APP_API}/touchpointTiles/get-all`)
        .then((response) => {
          this.toucpoint = response.data.filter((item) => {
            if (this.masterData.data.category === item.category) {
              return item;
            }
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    showPDF(data) {
      this.showNerve = data;
      this.openEmailpopup = false;
      this.openPdf = true;
    },

    async setjourney(item) {
      this.openPdf = false;
      var parameter = this.$route.query;
      let data = {
        masterDataId: this.masterDataId,
        name: item.touchpointName,
        displayType: item.displayType,
        type: item.type,
        AWSPinpointTemplateId: "",
        pageUrl: "",
        Parent: this.parentId,
        status: "Published",
        history: [],
        journeyName: parameter.journeyName,
      };
      await axios.post(`${process.env.VUE_APP_API}/journeyData`, data);

      this.getData();
      this.parentId = 0;
    },

    // //Delete single Journey tile
    // async deleteJourney(event) {
    //   console.log("id",event);

    //   await axios.delete("http://localhost:3000/journeyData/" + event.id);
    //   this.getData();
    // },

    //Delete All Journey tile
    deleteAllJourny() {
      this.journeyData.map((item) => {
        axios
          .delete(`${process.env.VUE_APP_API}/journeyData/` + item._id)
          .then((response) => {
            this.getData();
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          });
      });
    },
  },

  created() {
    var parameters = this.$route.query;
    this.masterDataId = parameters.masterDataId;
    this.journeyDataName = parameters.journeyName;
    var name = JSON.parse(localStorage.getItem("user"));
    axios
      .get(
        `${process.env.VUE_APP_API}/masterData/` +
          this.masterDataId +
          "?username=" +
          name.email
      )
      .then((response) => {
        this.masterData = response.data;
        this.getData();
      })
      .catch((error) => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
  },

  mounted() {
    this.getTouchpoint();
    this.$store.dispatch("getEtemplate");
    var oldData = localStorage.getItem("YourItem");
    // var data = "";

    // Object.keys(JSON.parse(oldData)).map((item) => {
    //   data = data + " / " + item;
    // });

    this.breadCrumb = Object.keys(JSON.parse(oldData));
    window.onpopstate = function () {
      var arrayData = Object.keys(JSON.parse(localStorage.getItem("YourItem")));

      if (arrayData) {
        arrayData.pop();
      }
      let obj = {};
      for (let x of arrayData) {
        obj[x] = JSON.parse(oldData)[x];
      }

      localStorage.setItem("YourItem", JSON.stringify(obj));
      window.location.reload();
    };
  },
});
