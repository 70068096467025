























































































































































































import Vue from "vue";
import store from "../store/index";
import usersData from "../../account-data.json";
import filterData from "../../src/Filter.json";
import axios from "axios";
export default Vue.extend({
  name: "Administration",
  data() {
    return {
      n: 0,
      options: {},
      users: usersData,
      allusers: usersData,
      filterDataa: filterData,
      select: "",
    };
  },
  methods: {
    nameClicked(item) {
      this.$router.push("/persondashboard/" + item.ID_Veeva);
    },
    viewDetails(item) {
      this.$router.push("/details2/" + item.ID_Veeva);
    },
    async addInChart(item, name1, name2, color, tooltext) {
      var data = {
        ID_Veeva: item.ID_Veeva,
        from: name1,
        to: name2,
        value: name1 == 'Aware' ? 20 : 10,
        color: color,
        tooltext: tooltext
      };
      await axios.post(`${process.env.VUE_APP_API}/node`, data);
    },
    customFilter(filtername) {
      if (this.select !== "") {
        this.users = this.allusers;
        this.users = this.users.filter(
          (item) => item[filtername] === this.select
        );
      }
    },
    resetFilter() {
      this.users = this.allusers;
      this.select = "";
    },
    async deleteAllNode(item) {
      await axios.delete(`${process.env.VUE_APP_API}/node/` + item.ID_Veeva);
    },
  },
  computed: {
    headers() {
      return [
        { text: "Name", value: "Name" },
        { text: "Action", value: "Nerveconnectemail" },
        { text: "", value: "Smpcdocument" },
        {
          text: "",
          value: "Samplerequest",
        },
        {
          text: "",
          value: "Survey",
        },
        { text: "", value: "Patientprogram" },
        { text: "", value: "Speaker" },
        { text: "", value: "EmailUnsubscribe" },
        { text: "", value: "Reset" },
      ];
    },
  },
  watch: {
    options: {
      handler() {
        store.dispatch("getIndividuals", {
          offset: (this.options.page - 1) * this.options.itemsPerPage,
          pageSize: this.options.itemsPerPage,
        });
      },
    },
  },
});
