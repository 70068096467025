























































































































































































































































import Vue from "vue";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
export default Vue.extend({
  name: "EmailTouchPoint",
  data: () => ({
    dialog: false,
    ganttDialog: false,
    width: "999px",
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
    series: [
      {
        data: [
          {
            x: "Code",
            y: [
              new Date("2019-03-02").getTime(),
              new Date("2019-03-04").getTime(),
            ],
          },
          {
            x: "Test",
            y: [
              new Date("2019-03-04").getTime(),
              new Date("2019-03-08").getTime(),
            ],
          },
          {
            x: "Validation",
            y: [
              new Date("2019-03-08").getTime(),
              new Date("2019-03-12").getTime(),
            ],
          },
          {
            x: "Deployment",
            y: [
              new Date("2019-03-12").getTime(),
              new Date("2019-03-18").getTime(),
            ],
          },
        ],
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "rangeBar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      xaxis: {
        type: "datetime",
      },
    },
  }),
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    emailtemplate() {
      return this.$store.getters.getEtemplate;
    },
  },
  methods: {
    nameClicked() {
      this.$router.push("/journeysubscribe");
    },
    journeyClicked() {
      this.$router.push("/emailtouchpoint2");
    },
    showtemplate() {
      this.dialog = true;
    },
    sendTemplate() {
      this.dialog = false;
      return axios.post("http://localhost:3000", {
        to_address: "cerebrum@h5f.net",
      });
    },
    openGantt() {
      this.ganttDialog = true;
      setTimeout(() => {
        this.width = "1000px";
      }, 100);
    },
  },
  mounted() {
    this.$store.dispatch("getEtemplate");
  },
});
