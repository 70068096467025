import { getInstance } from "./index";


export const authToken = async () => {
  const authService = await getInstance();

  const fn = async () => {
    const token = await  authService.getTokenSilently();
    return token;
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};