<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="User"
                type="text"
                v-model="user"
                required
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select label="Status" :items="status" v-model="suggestionType"></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="messageBody"
                auto-grow
                label="Message body"
                rows="3"
                row-height="20"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          min-width="150"
          color="#0d60a5"
          dark
          flat
          depressed
          class="btn"
          @click="surveySubmit()"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "SurveyTouchpointPopup",
  data() {
    return {
      user: "",
      suggestionType: "",
      messageBody: "",
      status: ["Suggestion/Recommendation", "Insight"],
    };
  },
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    surveySubmit() {
      var data = {
        name: this.user,
        suggestionType: this.suggestionType,
        messageBody: this.messageBody,
      };
      this.$emit("input", false);
      this.$emit("submitSurvey", data);
    },
  },
});
</script>
