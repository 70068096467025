<template>
    <div>
        <div class="extra-navbar" v-if="!info">
            <div class="extra-logo">J</div>
            <div class="extra-content d-flex align-items-center">
                    <h4>Admin</h4>
                    <i class="bi bi-power ml-3 pointer" style="font-size: 19px;" @click="goLogout"></i>
            </div>
        </div>
        <div class="container">
            <v-row style="margin-top:80px;">
                <v-col cols="7">
                    <v-card class="pa-3">
                        <v-list subheader>
                            <v-subheader>Requested Tenant</v-subheader>
    
                            <v-list-item v-for="chat in requestData.data" :key="chat._id">
                                <v-list-item-content>
                                    <!-- <v-list-item-title v-text="chat.requestedUser" small></v-list-item-title> -->
                                    <v-list-item-title v-text="chat.tenantName" small></v-list-item-title>
                                    <h5>{{ chat.email }}</h5>
                                </v-list-item-content>
                                <v-btn text :color="chat.status == 'Pending' ? 'orange' : 'green'" small>
                                    <v-icon small>mdi-check-circle</v-icon>
                                    {{ chat.status }}
                                </v-btn>
                                <v-btn class="ma-1" color="green" plain small @click="approveTenant(chat)"
                                    v-if="chat.status == 'Pending'">
                                    approve
                                </v-btn>
                                <v-btn class="ma-1" color="error" plain small @click="rejectTenant(chat)">
                                    Reject
                                </v-btn>
                            </v-list-item>
    
                        </v-list>
                    </v-card>
                </v-col>
           
                <v-col cols="5">
                    <v-card class="pa-3">
                        <v-list subheader>
                            <v-subheader>Recent Activity</v-subheader>
                            <v-list-item v-for="chat in recentRequestData.data" :key="chat._id">
                                <v-list-item-content>
                                    <v-list-item-title v-text="chat.username"></v-list-item-title>
                                    <h5>{{ chat.email }}</h5>
                                </v-list-item-content>
                                <v-btn text color="primary" small @click=openTenant()>
                                    Open Tenant
                                </v-btn>
                                <v-btn text :color="chat.status == 'Rejected' ? 'red' : 'green'" small>
                                    <v-icon small v-if="chat.status == 'Approved'">mdi-check-circle</v-icon>
                                    {{ chat.status }}
                                </v-btn>
    
                            </v-list-item>
    
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
    
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import axios from 'axios'
export default Vue.extend({
    name: "AdminDashboard",
    data() {
        return {
            requestData: [],
            recentRequestData: [],
        };
    },
    computed: {
        info(){
    
      return this.$store.getters.getInfo;
    }
    },
    methods: {
        openTenant(){
            localStorage.removeItem("isAdminLogged");
            this.$router.push('/tenantLogin');
        },
        async getRequestData() {
            this.requestData = await axios.get(`${process.env.VUE_APP_API}/alltenant`);
        },
        async getRecentRequestData() {
            this.recentRequestData = await axios.get(`${process.env.VUE_APP_API}/getrecentreqtenant`);
        },
        async approveTenant(item) {
            var data = {
                status: "Approved"
            }
            await axios.put(`${process.env.VUE_APP_API}/reqtenant/` + item._id, data);

            var recentData = {
                username: item.tenantName,
                email: item.email,
                status: "Approved"
            }
            await axios.post(`${process.env.VUE_APP_API}/recentreqtenant`, recentData);
            this.getRecentRequestData();
            this.getRequestData()
        },
        async rejectTenant(item) {
            await axios.delete(`${process.env.VUE_APP_API}/reqtenant/` + item._id);
            var recentData = {
                username: item.tenantName,
                email: item.email,
                status: "Rejected"
            }
            await axios.post(`${process.env.VUE_APP_API}/recentreqtenant`, recentData);
            this.getRequestData();
            this.getRecentRequestData();
        },
        goLogout(){
            localStorage.removeItem("isAdminLogged");
            this.$router.push('/adminLogin')
        }
    },
    mounted() {
        this.getRequestData();
        this.getRecentRequestData()
    }
});
</script>
<style>
.extra-navbar{
    width: 100%;
    padding: 20px;
    background: #fff;
    display: flex;
    justify-content: space-between;
}
.extra-logo{
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #517dcd;
    border-radius: 50%;
    color: #fff;
}

</style>