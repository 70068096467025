<template>
  <div>
    <HtmlPreview style="color: #000">
      <html
        xmlns="http://www.w3.org/1999/xhtml"
        xmlns:v="urn:schemas-microsoft-com:vml"
        xmlns:o="urn:schemas-microsoft-com:office:office"
      >
        <head>
          <title> </title>
        </head>
        <body style="background-color: #ffffff">
          <div style="background-color: #ffffff">
            <!--[if mso | IE]>
      <table
         align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
      >
        <tr>
          <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
      <![endif]-->

            <div style="margin: 0px auto; max-width: 600px">
              <table
                align="center"
                border="0"
                cellpadding="0"
                cellspacing="0"
                role="presentation"
                style="width: 100%"
              >
                <tbody>
                  <tr>
                    <td
                      style="
                        direction: ltr;
                        font-size: 0px;
                        padding: 9px 0px 9px 0px;
                        text-align: center;
                      "
                    >
                      <!--[if mso | IE]>
                  <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                
        <tr>
      
            <td
               class="" style="vertical-align:top;width:600px;"
            >
          <![endif]-->

                      <div
                        class="mj-column-per-100 outlook-group-fix"
                        style="
                          font-size: 0px;
                          text-align: left;
                          direction: ltr;
                          display: inline-block;
                          vertical-align: top;
                          width: 100%;
                        "
                      >
                        <table
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                          style="vertical-align: top"
                          width="100%"
                        ></table>
                      </div>

                      <!--[if mso | IE]>
            </td>
          
        </tr>
      
                  </table>
                <![endif]-->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!--[if mso | IE]>
          </td>
        </tr>
      </table>
      <![endif]-->
          </div>
        </body>
      </html>
    </HtmlPreview>
    <TopolEditor
      :options="customOptions"
      @onSave="handleOnSave($event)"
      @onSaveAndClose="handleOnClose"
    ></TopolEditor>
    <v-dialog width="400px" v-model="recepientPopup">
      <v-card class="pa-2 d-flex align-center">
        <v-text-field
          v-model="TemplateName"
          label="Type Template Name"
          type="text"
          solo
          required
          hide-details
        ></v-text-field>
        <v-btn
          color="#0d60a5"
          height="47px"
          dark
          depressed
          class="btn ml-3"
          @click="templateSubmit()"
        >
          Submit
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog width="350px" v-model="recepientPopupOnClose">
      <v-card class="pa-4">
        <v-text-field
          v-model="TemplateName"
          label="Type Template Name "
          type="text"
          solo
          required
        ></v-text-field>
        <v-text-field
          v-model="username"
          label="Type Email ID"
          type="text"
          solo
          required
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            min-width="150"
            color="#0d60a5"
            dark
            depressed
            class="btn"
            @click="sendTemplate()"
          >
            Send
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import TopolEditor from "@topol.io/editor-vue2";
import HtmlPreview from "vue-html-viewer";
export default Vue.extend({
  name: "Stripo",
  data() {
    return {
      recepientPopup: false,
      recepientPopupOnClose: false,
      TemplateName: "",
      username: "",
      name: "",
      customOptions: {
        authorize: {
          apiKey:
            "5KrSXt9AoT7HMM3NGOaLBjIsYuaULmegYFC5H8V1eKs6mWVP70ISPpY1PwMB",
          userId: "pankaj1234",
        },
        light: true,
        premadeBlocks: true,
      },
      html: "",
      templateHtml: "",
    };
  },
  components: {
    TopolEditor,
    HtmlPreview,
  },
  methods: {
    async templateSubmit() {
      var data = {
        name: this.TemplateName,
        html: this.templateHtml,
      };
      axios.post(`${process.env.VUE_APP_API}/templateSave`, data);
      this.recepientPopup = false;
      this.TemplateName = "";
    },
    handleOnSave(html) {
      this.recepientPopup = true;
      this.templateHtml = html.html;
    },
    handleOnClose(html) {
      this.recepientPopupOnClose = true;
      this.templateHtml = html.html;
    },
    sendTemplate() {
      var data = {
        name: this.TemplateName,
        username: this.username,
        html: this.templateHtml,
      };
      axios.post(`${process.env.VUE_APP_API}/send-mail/aws`, data);
      axios.post(`${process.env.VUE_APP_API}/templateSave`, data);
      this.recepientPopupOnClose = false;
      this.TemplateName = "";
      this.username = "";
    },
  },
  beforeDestroy() {
    TopolPlugin.destroy();
  },
});
</script>

<style>
#externalSystemContainer {
  background-color: rgb(255, 255, 255);
  padding: 5px 0 5px 20px;
  border-top: solid 1px #e7e7e7;
  border-bottom: solid 1px #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: end;
}
#undoButton,
#redoButton {
  display: none;
}
#stripoSettingsContainer {
  width: 400px;
  float: left;
}
#stripoPreviewContainer {
  width: calc(100% - 400px);
  float: left;
}
.notification-zone {
  position: fixed;
  width: 400px;
  z-index: 99999;
  right: 20px;
  bottom: 80px;
}
.control-button {
  border-radius: 17px;
  padding: 5px 10px;
  border-color: grey;
}
#changeHistoryLink {
  cursor: pointer;
}
#codeEditor {
  border: solid 1px #bdbdbd;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  font-weight: 500;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
}
.export {
  padding: 8px 15px;
  background: #33cb4b;
  border-radius: 20px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  width: 100px;
  height: 32px;
  display: flex;
  line-height: 17px;
  cursor: pointer;
}
.export img {
  width: 17px;
  line-height: 35px;
  margin-right: 5px;
}
</style>
