<template>
  <div>
    <div class="extra-navbar">
      <div class="extra-logo">A</div>
      <div class="extra-content d-flex align-items-center">
        <h4 v-if="getInfo">{{ getInfo.tenant }}</h4>
        <i class="bi bi-power ml-3 pointer" style="font-size: 19px;" @click="goLogout"></i>

      </div>
    </div>
    <v-container>
      <v-card style="margin-top:80px;">
        <v-list subheader>
          <div class="user-header-title mb-5">
            <v-subheader>Users</v-subheader>
            <v-btn depressed small color="primary" class="mx-5" @click="openaddUserModal()">
              Add user
            </v-btn>
          </div>

          <v-list-item v-for="user in users" :key="user._id">
            <v-list-item-content>
              <h5>{{ user.email }}</h5>
            </v-list-item-content>
            <v-btn class="ma-1" color="green" plain small @click="approveTenant()">
              <!-- {{ user.role }} -->
            </v-btn>
            <v-btn class="ma-1" color="primary" plain small @click="editUser(user)">
              edit
            </v-btn>
            <v-btn class="ma-1" color="error" plain small @click="deleteUser(user)">
              Delete
            </v-btn>
          </v-list-item>

        </v-list>
      </v-card>
      <v-dialog v-model="addUserModal" max-width="450px" transition="dialog-top-transition">
        <v-card class="pa-3">
          <v-form ref="form">
            <v-text-field label="Username" v-model="username" class="mb-4" required></v-text-field>
            <v-text-field label="Email" :rules="emailRules" v-model="email" class="mb-4" required></v-text-field>
            <!-- <v-text-field label="Password" v-model="password" class="mb-4" required></v-text-field> -->

            <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="passwordRules"
              :type="show1 ? 'text' : 'password'" name="input-10-1" label="Normal with hint text"
              hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>


            <v-card-actions class="pa-0 mt-5">
              <v-spacer></v-spacer>
              <v-btn color="#0d60a5" dark depressed @click="closeModal()">
                Close
              </v-btn>
              <v-btn color="#0d60a5" dark depressed class="btn" @click="addUser">
                Add
              </v-btn>
            </v-card-actions>
          </v-form>

        </v-card>
      </v-dialog>
      <v-dialog v-model="EditUserModal" max-width="450px" transition="dialog-top-transition">
        <v-card class="pa-3">
          <v-form ref="form">
            <v-text-field placeholder="Email" :rules="emailRules" type="email" v-model="email" outlined dense
              :append-icon="'mdi-send'" @click:append="emailChange('email')"></v-text-field>
          </v-form>
          <v-form ref="form1">
            <v-text-field placeholder="Password" v-model="password" :rules="passwordRules" type="password" outlined dense
              :append-icon="'mdi-send'" @click:append="passwordChange('password')"></v-text-field>
          </v-form>
          <v-form ref="form2">
            <v-text-field placeholder="Username" outlined dense :append-icon="'mdi-send'" v-model="username"
              @click:append="usernameChange('username')"></v-text-field>
          </v-form>
          <!-- <v-form ref="form3">
            <v-text-field placeholder="Phone" outlined dense :append-icon="'mdi-send'"
              @click:append="numberChange('number')"></v-text-field>
          </v-form> -->
          <v-select ref="role" v-model="role" :items="roles" label="Add Role" placeholder="Select..." required outlined
            dense :append-outer-icon="'mdi-send'" @click:append-outer="roleChange('role')" item-text="name"
            return-object></v-select>
          <v-row class="no-gutters">
            <v-col cols="auto" v-for="i in roleRemove" :key="i.name">
              <v-chip class="ma-2" close @click:close="deleteRole(i)">
                {{ i.name }}
              </v-chip>
            </v-col>
          </v-row>
          <v-card-actions class="pa-0 mt-5">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeEditUserModal">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
export default Vue.extend({
  name: "TenantDashboard",
  data() {
    return {
      users: "",
      addUserModal: false,
      roles: [],
      roleRemove: [],
      role: [],
      password: "",
      username: "",
      edit: false,
      id: "",
      EditUserModal: false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      email: "",
      token: "",
      userID: "",
      show1: false,
    };
  },
  computed: {
    getInfo() {
      return JSON.parse(localStorage.getItem("isTenant"));
    },
    passwordRules() {
      return [
        (value) => {
          if (value?.length < 8) {
            return "Password must be at least 8 characters long";
          }
          const regexes = [
            /[a-z]/,
            /[A-Z]/,
            /\d/,
            /[!@#$%^&*]/,
          ];
          const numTypesPresent = regexes.reduce(
            (count, regex) => (regex.test(value) ? count + 1 : count),
            0
          );
          if (numTypesPresent < 3) {
            return "Password must contain at least 3 of the following types of characters: lowercase letters, uppercase letters, numbers, and special characters";
          }
          return true;
        },
      ];
    }
  },
  methods: {
    openaddUserModal() {
      this.username = "",
        this.email = "",
        this.password = ""
      this.addUserModal = !this.addUserModal;
    },
    async addUser() {
      let data = {
        connection: process.env.VUE_APP_AUTH0_CONNECTION,
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        username: this.username,
        email: this.email,
        password: this.password,
        user_metadata:
          { user_type: 'cutom',}
      };
      if (this.$refs.form.validate()) {
        await axios.post(`https://djtechsol-dev.eu.auth0.com/dbconnections/signup`, data).then(() => {
          this.getUser();
          this.addUserModal = !this.addUserModal;
        })
      }
    },
    async deleteRole(data) {
      await axios.delete(`https://djtechsol-dev.eu.auth0.com/api/v2/users/${this.userID}/roles`, {
        headers: {
          Authorization: `Bearer ${this.token}`
        },
        data: {
          "roles": [
            data.id
          ]
        }
      });
      this.EditUserModal = !this.EditUserModal;
    },
    service() {
      axios.get('https://djtechsol-dev.eu.auth0.com/api/v2/users', {
        headers: {
          'Authorization': `token ${access_token}`
        }
      })
        .then((res) => {
          console.log(res.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },

    async emailChange(type) {
      if (this.$refs.form.validate()) {
        let body = {
          connection: process.env.VUE_APP_AUTH0_CONNECTION,
          email: this.email,
          client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        }

        await axios.patch(`https://djtechsol-dev.eu.auth0.com/api/v2/users/${this.userID}`, body, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).then(() => {
          this.EditUserModal = !this.EditUserModal;
          this.getUser();
        })
      }
    },
    async passwordChange(type) {
      if (this.$refs.form1.validate()) {
        let body = {
          connection: process.env.VUE_APP_AUTH0_CONNECTION,
          password: this.password,
          client_id: process.env.VUE_APP_AUTH0_CLIENT_ID
        }

        await axios.patch(`https://djtechsol-dev.eu.auth0.com/api/v2/users/${this.userID}`, body, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).then(() => {

          this.EditUserModal = !this.EditUserModal;
          this.getUser();
        })
      }
    },
    async usernameChange(type) {
      if (this.$refs.form2.validate()) {
        let body = {
          connection:process.env.VUE_APP_AUTH0_CONNECTION,
          nickname: this.username,
          client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        }

        await axios.patch(`https://djtechsol-dev.eu.auth0.com/api/v2/users/${this.userID}`, body, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          },

        }).then(() => {
          this.EditUserModal = !this.EditUserModal;
          this.getUser();
        })
      }
    },
    // numberChange(type) {
    //   console.log(type);
    // },
    async roleChange(type) {
      let data = {
        "roles": [
          this.role.id
        ]
      }
      await axios.post(`https://djtechsol-dev.eu.auth0.com/api/v2/users/${this.userID}/roles`, data, {
        headers: {
          'Authorization': `Bearer ${this.token}`
        },

      }).then(() => {
        this.EditUserModal = !this.EditUserModal;
        this.getUser();
      })
    },

    async getUser() {
      const body = {
        grant_type: "client_credentials",
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        client_secret: process.env.VUE_APP_AUTH0_SECRET_KEY,
        audience: process.env.VUE_APP_AUTH0_AUDIENCCE,
      }
      await axios.post('https://djtechsol-dev.eu.auth0.com/oauth/token', body)
        .then((res) => {
          this.token = res.data.access_token
          axios.get('https://djtechsol-dev.eu.auth0.com/api/v2/users', {
            headers: {
              'Authorization': `Bearer ${res.data.access_token}`
            }
          }).then((res) => {
            this.users = res.data;
          })
        })

    },
    async editUser(user) {
      this.edit = !this.edit;
      this.EditUserModal = !this.EditUserModal;
      this.email = user.email;
      this.username = user.nickname;
      this.userID = user.user_id;
      axios.get(`https://djtechsol-dev.eu.auth0.com/api/v2/users/${user.user_id}/roles`, {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).then((res) => {
        this.roleRemove = res.data
      });
      axios.get(`https://djtechsol-dev.eu.auth0.com/api/v2/roles`, {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).then((res) => {
        this.roles = res.data
      })

    },
    async postEditUser() {
      let data = {
        username: this.username,
        password: this.password,
        role: this.role
      }
      await axios.put(`${process.env.VUE_APP_API}/editAdduser/` + this.id, data)
      this.getUser();
      this.addUserModal = !this.addUserModal;
    },
    goLogout() {
      localStorage.removeItem("isTenant");
      this.$router.push('/tenantLogin')
    },
    async deleteUser(user) {
      await axios.delete(`https://djtechsol-dev.eu.auth0.com/api/v2/users/${user.user_id}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      })
      this.getUser()
    },
    closeModal() {
      this.addUserModal = false;
      this.edit = false;
      this.username = '',
        this.password = '',
        this.role = ''
    },
    closeEditUserModal() {
      this.EditUserModal = false
      this.$refs.form.resetValidation();
    }
  },
  mounted() {
    this.getUser();
  }
});
</script>
<style>
.user-header-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
</style>