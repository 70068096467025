import { AWS_api, AWS_api_ses } from "./api";
import axios from "axios";

class PinpointService {
  articleId: any;
  accoumtId: any;
  secretKey: any;
  secretAccessKey: any;

  async fetchKeys() {
    const response = await axios.get("http://localhost:3000/apiKeys");
    console.log("XXXXXX", response);
    this.accoumtId = window.atob(response.data[0].AwsAccountId);
    this.secretKey = window.atob(response.data[0].AwsAccessKey);
    this.secretAccessKey = window.atob(response.data[0].AwsSecretAccessKey);
  }

  async getSegments(id: string) {
    const url = `/v1/apps/${this.accoumtId}/segments/${id}`;
    return await AWS_api.get(url).then((response) => {
      return response;
    });
  }

  async getEmailTemplate() {
    const url = `${process.env.VUE_APP_AWS_ENDPOINT}v1/templates/NerveConnect/email`;
    return await AWS_api.get(url).then((response) => {
      return response;
    });
  }
  async getFollowUpTemplate() {
    const url = `${process.env.VUE_APP_AWS_ENDPOINT}v1/templates/visit-follow-up-cerebrum/email`;
    return await AWS_api.get(url).then((response) => {
      return response;
    });
  }

  async sendEmail(params: any) {
    const url = `https://email.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/v1/email/outbound-emails`;
    return await AWS_api_ses.post(url, params).then(async (response) => {
      return response;
    });
  }
}

export const pinpointService = new PinpointService();
