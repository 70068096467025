<template>
  <v-container>
    <v-row class="my-2">
      <v-col>
        <h2>Connection</h2>
      </v-col>
    </v-row>
    <v-row class="my-2">
      <v-col cols="4">
        <v-card class="pa-2 registration-box d-block" style="width: 100%">
          <v-row class="no-gutters">
            <v-spacer></v-spacer>
            <v-col
              cols="auto"
              class="box-icon-color"
              :class="[
                apiStatus == 'Active' ? 'backGreen' : '',
                apiStatus == 'Not Active' ? 'backYellow' : '',
              ]"
            >
              <span class="small">{{ apiStatus }}</span>
            </v-col>
          </v-row>
          <v-row class="no-gutters">
            <v-col cols="12">
              <h4 class="main-title mb-1">AWS Pinpoint</h4>
              <div class="mb-2" :class="{ credenArea: credenArea }">
                <span class="small bold">AWS Account ID</span>
                <v-text-field
                  solo
                  dense
                  hide-details
                  :value="show1 ? accoumtId : changeFormatData"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  type="text"
                  @click:append="changeFormat()"
                  class="mb-3 xx"
                  color="green"
                ></v-text-field>

                <span class="small bold">Secret Key</span>
                <v-text-field
                  solo
                  dense
                  hide-details
                  :value="show2 ? secretKey : changeFormatData2"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  type="text"
                  @click:append="changeFormat2()"
                  class="mb-3 xx"
                ></v-text-field>

                <span class="small bold">Secret Access Key</span>
                <v-text-field
                  solo
                  dense
                  hide-details
                  :value="show3 ? secretAccessKey : changeFormatData3"
                  @click:append="changeFormat3()"
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  type="text"
                  class="mb-3 xx"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-row class="no-gutters">
            <v-spacer></v-spacer>
            <v-col cols="auto" :class="{ editBtn: editBtn }">
              <v-btn
                small
                flat
                class="elevation-0 mr-2"
                dark
                color="green"
                @click="testApi()"
                >{{ isLoading ? "loading" : "Test" }}</v-btn
              >
              <v-btn small flat class="elevation-0" @click="showInput()"
                ><i class="bi bi-pencil-square"></i
              ></v-btn>
            </v-col>
          </v-row>
          <v-row class="no-gutters">
            <v-spacer></v-spacer>
            <v-col cols="auto" :class="{ connectionBtn: connectionBtn }">
              <v-btn small flat class="elevation-0 mr-2" @click="showEditBtn()"
                >Cancel</v-btn
              >
              <v-btn
                small
                flat
                class="elevation-0 mr-2"
                dark
                color="green"
                @click="replace()"
                >Connect</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-2 registration-box d-block" style="width: 100%">
          <v-row class="no-gutters">
            <v-spacer></v-spacer>
            <v-col cols="auto" class="box-icon-color">
              <span class="small"></span>
            </v-col>
          </v-row>
          <v-row class="no-gutters">
            <v-col>
              <h4 class="main-title">Salesforce</h4>
            </v-col>
            <v-col> </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-2 registration-box d-block" style="width: 100%">
          <v-row class="no-gutters">
            <v-spacer></v-spacer>
            <v-col cols="auto" class="box-icon-color">
              <span class="small"></span>
            </v-col>
          </v-row>
          <v-row class="no-gutters">
            <v-col>
              <h4 class="main-title">Veeva</h4>
            </v-col>
            <v-col> </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { aws4Interceptor } from "aws4-axios";
import Vue from "vue";
export default Vue.extend({
  name: "Connection",
  data() {
    return {
      credenArea: true,
      connectionBtn: true,
      editBtn: false,
      accoumtId: "",
      secretKey: "",
      secretAccessKey: "",
      show1: false,
      show2: false,
      show3: false,
      password: "Password",
      apiStatus: "",
      isLoading: false,
      changeFormatData: "",
      changeFormatData2: "",
      changeFormatData3: "",
    };
  },
  methods: {
    changeFormat(id = this.accoumtId, first = false) {
      if (first) {
        this.show1 = false;
      } else {
        this.show1 = !this.show1;
      }
      var a = id;
      if (!this.show1) {
        return (this.changeFormatData =
          "*".repeat(a.length - 4) + a.slice(a.length - 4));
      } else {
        this.changeFormatData = a;
      }
    },
    changeFormat2(id = this.secretKey, first = false) {
      if (first) {
        this.show2 = false;
      } else {
        this.show2 = !this.show2;
      }
      var a = id;
      if (!this.show2) {
        return (this.changeFormatData2 =
          "*".repeat(a.length - 4) + a.slice(a.length - 4));
      } else {
        this.changeFormatData2 = a;
      }
    },
    changeFormat3(id = this.secretAccessKey, first = false) {
      if (first) {
        this.show3 = false;
      } else {
        this.show3 = !this.show3;
      }
      var a = id;
      if (!this.show3) {
        return (this.changeFormatData3 =
          "*".repeat(a.length - 4) + a.slice(a.length - 4));
      } else {
        this.changeFormatData3 = a;
      }
    },
    switchVisibility1() {
      this.passwordFiledType1 =
        this.passwordFiledType1 === "password" ? "text" : "password";
    },
    switchVisibility2() {
      this.passwordFiledType2 =
        this.passwordFiledType2 === "password" ? "text" : "password";
    },
    switchVisibility3() {
      this.passwordFiledType3 =
        this.passwordFiledType3 === "password" ? "text" : "password";
    },
    showInput() {
      this.credenArea = false;
      this.connectionBtn = false;
      if (this.connectionBtn == false) {
        this.editBtn = true;
      }
    },
    showEditBtn() {
      this.editBtn = false;
      this.connectionBtn = true;
      this.credenArea = true;
    },
    async fetchKeys() {
      var response = await axios.get("http://localhost:3000/apiKeys/1");
      if (response && response.data) {
        this.accoumtId = window.atob(response?.data?.AwsAccountId);
        this.changeFormat(window.atob(response?.data?.AwsAccountId), true);

        this.secretKey = window.atob(response?.data?.AwsAccessKey);
        this.changeFormat2(window.atob(response?.data?.AwsAccessKey), true);

        this.secretAccessKey = window.atob(response?.data?.AwsSecretAccessKey);
        this.changeFormat3(
          window.atob(response?.data?.AwsSecretAccessKey),
          true
        );

        sessionStorage.removeItem("key");
        sessionStorage.setItem(
          "key",
          JSON.stringify({
            accoumtId: this.accoumtId,
            secretKey: this.secretKey,
            secretAccessKey: this.secretAccessKey,
          })
        );
        this.replace();
      }
    },
    async replace() {
      this.showEditBtn();
      if (this.accoumtId && this.secretKey && this.secretAccessKey) {
        let data = {
          AwsAccountId: window.btoa(this.accoumtId),
          AwsAccessKey: window.btoa(this.secretKey),
          AwsSecretAccessKey: window.btoa(this.secretAccessKey),
        };

        let response = await axios.patch(
          "http://localhost:3000/apiKeys/1",
          data
        );
        let accoumtId = response?.data?.AwsAccountId;
        let secretKey = response?.data?.AwsAccessKey;
        let secretAccessKey = response?.data?.AwsSecretAccessKey;

        sessionStorage.removeItem("key");
        sessionStorage.setItem(
          "key",
          JSON.stringify({
            accoumtId: accoumtId,
            secretKey: secretKey,
            secretAccessKey: secretAccessKey,
          })
        );
        this.testApi();
      } else {
        alert("Fill All Filed");
      }
    },
    async testApi() {
      if (sessionStorage.getItem("key") === JSON.stringify({})) {
        alert("Fill All Filed 2");
      } else {
        this.isLoading = true;
        const client = axios.create();

        const interceptor = aws4Interceptor(
          {
            region: process.env.VUE_APP_AWS_REGION,
            service: process.env.VUE_APP_AWS_SERVICE_NAME,
          },
          {
            accessKeyId: window.atob(
              JSON.parse(sessionStorage.getItem("key")).secretKey
            ),
            secretAccessKey: window.atob(
              JSON.parse(sessionStorage.getItem("key")).secretAccessKey
            ),
          }
        );
        client.interceptors.request.use(interceptor);
        client
          .get(
            `${process.env.VUE_APP_AWS_ENDPOINT}v1/templates/NerveConnect/email`
          )
          .then((res) => {
            this.apiStatus = "Active";
            this.isLoading = false;
          })
          .catch((res) => {
            this.apiStatus = "Not Active";
            this.isLoading = false;
          });
      }
    },
  },
  mounted() {
    this.fetchKeys();
  },
});
</script>
<style scoped>
.credenArea,
.connectionBtn,
.editBtn {
  display: none;
}
.xx {
  font-size: 13px !important;
}
</style>
