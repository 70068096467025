

































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import usersData from "../../account-data.json";
import FusionChartSvg from "../components/FusionChartSvg.vue";
import axios from "axios";
import moment from "moment";
export default Vue.extend({
  name: "PersonDashboard",
  components: {
    apexcharts: VueApexCharts,
    FusionChartSvg,
  },
  data: function () {
    return {
      id: "",
      insightList: [],
      toggleDisable: true,
      user: [
        "John Servaes (commercial@curapharmaceuticals.com)",
        "Philippe Meskens (medical@curapharmaceuticals)",
      ],
      action: ["Recommendation", "Insight"],
      massage: "",
      users: "",
      actionData: "",
      title: "",
      cerebrum: "",
      data: usersData,
      showContent: false,
      emptyContent: true,
      trialist: true,
      dialog: false,
      emailDialog: false,
      nerveDialog: false,
      followUpDialog: false,
      sendEmail: false,
      sendveevasuggetion: false,
      viaEmail: false,
      viaSuggestion: true,
      awaredata: false,
      newarr: {},
      series: [44, 55, 41, 17],

      options: {
        labels: ["Telephone", "Email", "Conference call", "Face To Face"],
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          fontSize: "12px",
          fontWeight: 400,
          markers: {
            width: 10,
            height: 10,
            radius: 12,
          },
          itemMargin: {
            horizontal: 8,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
      },
      segment: [{ name: "Aware" }, { name: "Trialist" }],
    };
  },
  created() {
    this.getCerebrumData();
    this.getAllInsights();
  },
  methods: {
    showViaEmail() {
      (this.viaEmail = false), (this.viaSuggestion = true);
    },
    showViaSuggestion() {
      (this.viaEmail = true), (this.viaSuggestion = false);
    },
    hideAware() {
      this.aware = true;
      this.trialist = false;
    },
    openNerveTemplate() {
      this.emailDialog = false;
      this.nerveDialog = true;
    },
    openFollowupTemplate() {
      this.emailDialog = false;
      this.followUpDialog = true;
    },
    sendTemplate() {
      this.nerveDialog = false;
      this.followUpDialog = false;
      return axios.post("http://localhost:3000", {
        to_address: "cerebrum@h5f.net",
      });
    },
    sendViaEmail() {
      this.sendEmail = true;
      this.toggleDisable = true;
      this.users = "";
      this.actionData = "";
      this.title = "";
      this.massage = "";
    },
    sendVeevasugg() {
      this.sendveevasuggetion = true;
    },
    async getCerebrumData() {
      var id = this.$route.params;

      var res = await axios.get(`${process.env.VUE_APP_API}/node/` + id.id);
      this.cerebrum = res?.data?.to;
    },
    async saveInsights() {
      var id = this.$route.params;
      var data = {
        ID_Veeva: id.id,
        username: this.users,
        type: this.actionData,
        title: this.title,
        massage: this.massage,
      };
      await axios.post(`${process.env.VUE_APP_API}/insights`, data);
      this.sendEmail = false;
      window.location.reload();
    },
    insightType(item) {
      this.sendEmail = true;
      this.toggleDisable = false;
      this.users = item.username;
      this.actionData = item.type;
      this.title = item.title;
      this.massage = item.massage;
      this.id = item._id;
    },
    async getAllInsights() {
      var res = await axios.get(`${process.env.VUE_APP_API}/insights/get-all`);
      this.insightList = res.data;
    },
    goCommercial() {
      this.$router.push("/commercial");
    },
    async complete(id) {
      var data = {
        date: new Date(),
      };
      await axios.put(`${process.env.VUE_APP_API}/insights/` + id, data);
      this.sendEmail = false;
      window.location.reload();
    },
    format(date) {
      return moment(String(date)).format("DD/MM/YYYY");
    },
  },
  computed: {
    emailtemplate() {
      return this.$store.getters.getEtemplate;
    },
    followUptemplate() {
      return this.$store.getters.getFollowUpTemplate;
    },
  },
  mounted() {
    this.idEva = this.$route.params;

    this.data.map((data) => {
      if (data.ID_Veeva == this.idEva.id) {
        return (this.newarr = data);
      }
    });
    this.$store.dispatch("getEtemplate");
    this.$store.dispatch("getFollowtemplate");

    if (this.newarr.Ranking == "N/A") {
      this.showContent = true;
      this.emptyContent = false;
    } else {
      this.emptyContent = true;
    }
  },
});
