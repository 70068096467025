<template>
  <v-container>
    <v-row class="my-2">
      <v-col>
        <h2>Marketing</h2>
      </v-col>
      <v-col cols="3" class="d-flex align-center">
        <p class="mr-2 mb-0 bold">Touchpoint</p>
        <v-select
          label="Nerve Connect Email"
          solo
          class="mx-1"
          dense
          loader-height="80"
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row class="d-flex justify-center">
          <v-col cols="3">
            <v-card class="pa-3">
              <v-row>
                <v-col cols="auto" class="text-center">
                  <div class="enquiry-icon-area d-flex align-center pa-5">
                      <v-icon class="display-3" color="#555767">
                      mdi-email-arrow-right
                    </v-icon>
                  </div>
                </v-col>
                <v-col>
                  <v-row class="no-gutters">
                    <v-col cols="12">
                      <div class="enquiry-content-text">
                        <h1>78</h1>
                        <span>Total Emails Sent</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="text-right">
                      <div class="right-arrow pointer mt-2">
                        <span @click="nameClicked()"
                          >View Details<i class="bi bi-chevron-right"></i
                        ></span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card class="pa-3">
              <v-row>
                <v-col cols="auto" class="text-center">
                  <div class="enquiry-icon-area d-flex align-center pa-5">
                    <v-icon class="display-3" color="#555767">
                      mdi-email-check
                    </v-icon>
                  </div>
                </v-col>
                <v-col>
                  <v-row class="no-gutters">
                    <v-col cols="12">
                      <div class="enquiry-content-text">
                        <h1>58</h1>
                        <span>Total Emails Delivered</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="text-right">
                      <div class="right-arrow pointer mt-2">
                        <span @click="nameClicked()"
                          >View Details<i class="bi bi-chevron-right"></i
                        ></span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card class="pa-3">
              <v-row>
                <v-col cols="auto" class="text-center">
                  <div class="enquiry-icon-area d-flex align-center pa-5">
                    <v-icon class="display-3" color="#555767">
                      mdi-email-open-multiple
                    </v-icon>
                  </div>
                </v-col>
                <v-col>
                  <v-row class="no-gutters">
                    <v-col cols="12">
                      <div class="enquiry-content-text">
                        <h1>58</h1>
                        <span>Total Emails Opened</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="text-right">
                      <div class="right-arrow pointer mt-2">
                        <span @click="nameClicked()"
                          >View Details<i class="bi bi-chevron-right"></i
                        ></span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center mt-8">
      <v-col cols="8" class="bg-white">
        <v-row class="no-gutters">
          <v-col cols="9">
            <div class="bar-control">
              <div class="bar-detail">
                <div class="bar-identity blue"></div>
                <p>Sent</p>
              </div>
              <div class="bar-detail">
                <div class="bar-identity green"></div>
                <p>Delivered</p>
              </div>
              <div class="bar-detail">
                <div class="bar-identity"></div>
                <p>Opened</p>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <v-select
              label="Filter Year"
              outlined
              class="mx-1"
              dense
              loader-height="80"
              hide-details
            ></v-select>
          </v-col>
        </v-row>
        <apexchart
          type="line"
          height="350"
          width="100%"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Vue from 'vue';
export default Vue.extend({
  name: "Marketing",
  components: {
    apexchart: VueApexCharts,
  },
  methods: {
    // nameClicked() {
    //   this.$router.push("/medicalinquery");
    // },
    // medicleSample() {
    //   this.$router.push("/medicalsample");
    // },
  },
  data() {
    return {
      series: [
        {
          name: "Medical Inquiry",
          data: [28, 29, 33, 36, 32, 32, 33],
        },
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#f08185"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: {
            text: "Month",
          },
        },
        yaxis: {
          title: {
            text: "",
          },
          min: 5,
          max: 40,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };
  },
});
</script>

<style scoped>
.enquiry-icon-area {
  height: 100%;
}
.enquiry-icon-area {
  background: #edf1f4;
  border-radius: 4px;
}
.enquiry-content-status {
  line-height: 10px;
  justify-content: end;
}
.enquiry-content-status i {
  font-size: 13px;
}
.enquiry-content-status span {
  font-size: 14px;
}
.enquiry-content-text h1 {
  line-height: 35px;
}
.enquiry-content-text span {
  font-size: 14px;
}
.right-arrow {
  padding: 5px 8px;
  background: #d3e3ef;
  color: #417b9d;
  border-radius: 3px;
  font-size: 14px;
  display: inline-block;
  text-align: right;
}
.bar-control {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  margin-right: 10px;
}
.bar-control p {
  margin: 0;
  font-size: 14px;
}
.bar-identity {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  background-color: #f08185;
  display: inline-block;
  margin-right: 5px;
}
.bar-detail {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.bg-white {
  background-color: #fff;
}
</style>