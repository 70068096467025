









































































import Vue from "vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import VueFullscreen from "vue-fullscreen";
import VueHtmlToPaper from "vue-html-to-paper";
Vue.use(VueFullscreen);
Vue.use(VueHtmlToPaper);
export default Vue.extend({
  name: "Slider",
  data() {
    return {
      slider: true,
      fullscreen: false,
      teleport: true,
      pageOnly: false,
      mb: false,
      sliders: [
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_1/index.html",
          src: "assets/thumbnails/Lonsurf_slide_1.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_18/index.html",
          src: "assets/thumbnails/Lonsurf_slide_18.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_19/index.html",
          src: "assets/thumbnails/Lonsurf_slide_19.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_20/index.html",
          src: "assets/thumbnails/Lonsurf_slide_20.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_2/index.html",
          src: "assets/thumbnails/Lonsurf_slide_2.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_3/index.html",
          src: "assets/thumbnails/Lonsurf_slide_3.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Onivyde_slide_1/index.html",
          src: "assets/thumbnails/Onyvide_slide_1.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Oncology_slide_2/index.html",
          src: "assets/thumbnails/Oncology_slide_2.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_1/index.html",
          src: "assets/thumbnails/Lonsurf_slide_1.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_18/index.html",
          src: "assets/thumbnails/Lonsurf_slide_18.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_19/index.html",
          src: "assets/thumbnails/Lonsurf_slide_19.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_20/index.html",
          src: "assets/thumbnails/Lonsurf_slide_20.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_2/index.html",
          src: "assets/thumbnails/Lonsurf_slide_2.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Lonsurf_slide_3/index.html",
          src: "assets/thumbnails/Lonsurf_slide_3.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Onivyde_slide_1/index.html",
          src: "assets/thumbnails/Onyvide_slide_1.png",
        },
        {
          link: "https://demo.eye-4-pharma.com/assets/demo/Oncology_slide_2/index.html",
          src: "assets/thumbnails/Oncology_slide_2.png",
        },
      ],
      counterNumber: "1",
    };
  },
  methods: {
    fulscreen() {
      this.fullscreen = !this.fullscreen;
      this.slider = false;
      this.mb = true;
    },
    closeFullscreen() {
      this.fullscreen = false;
      this.slider = true;
      this.mb = false;
    },
    printIt() {
      this.$htmlToPaper("printMe");
    },
    counter(index) {
      this.counterNumber = index + 1;
    },
  },
  components: {
    VueSlickCarousel,
  },
});
