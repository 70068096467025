















































































































import Vue from "vue";
import store from "../store/index";
import usersData from "../../account-data.json";
export default Vue.extend({
  name: "MedicalSample",
  data() {
    return {
      n: 0,
      options: {},
      users: usersData,
    };
  },

  methods: {
    nameClicked(item) {
      this.$router.push("/persondashboard/" + item.ID_Veeva);
    },
    viewDetails(item) {
      this.$router.push("/details2/" + item.ID_Veeva);
    },
  },
  computed: {
    headers() {
      return [
        { text: "Doctor Name", value: "Name" },
        { text: "ProDuct", value: "Product" },
        { text: "Quantity", value: "Quantity" },
        { text: "Assigned To", value: "assigned" },
        { text: "Status", value: "Ranking" },
        {
          text: "Last Update",
          value: "Specialty_2",
        },
      ];
    },
    // individuals(): Array<Individual> {
    //   return this.$store.getters.getIndividuals;
    // },
    // totalRecords(): number {
    //   console.log('totalRecords called');

    //   return this.$store.getters.getTotalRecords;
    // },
  },
  watch: {
    options: {
      handler() {
        store.dispatch("getIndividuals", {
          offset: (this.options.page - 1) * this.options.itemsPerPage,
          pageSize: this.options.itemsPerPage,
        });
      },
    },
  } /* 
  beforeRouteEnter(to, from, next) {
    store.dispatch("getIndividuals", 0)
    next()
  } */,
});
