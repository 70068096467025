







































































































// import { Individual } from "@/classes/individual/Individual";
import Vue from "vue";
import store from "../store/index";
import usersData from "../../account-data.json";
import { Organisation } from "../../src/organization.json";
export default Vue.extend({
  name: "Organization",
  data() {
    return {
      n: 0,
      options: {},
      users: usersData,
      allusers: usersData,
      filterDataa: Organisation,
      select: "",
      json_fields: {
        Organisation: "Organisation",
        CountryCode: "CountryCode",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  methods: {
    nameClicked(item) {
      this.$router.push(
        "/OrganizationDashboard?name=" +
          item.Account +
          "&parenID=" +
          item.ParentAccountID +
          "&id=" +
          item.ID
      );
    },
    viewDetails(item) {
      this.$router.push("/details2/" + item.Account);
    },
    customFilter(filtername) {
      if (this.select !== "") {
        this.users = this.allusers;
        this.users = this.users.filter(
          (item) => item[filtername] === this.select
        );
      }
    },
    resetFilter() {
      this.users = this.allusers;
      this.select = "";
    },
    downloadFile() {
      var newdata = this.filterDataa.map((item) => {
        return {
          Organisation: item.Account,
          CountryCode: item.CountryCode,
        };
      });
      this.json_data = newdata;
    },
  },
  computed: {
    headers() {
      return [
        { text: "Organisation", value: "Account" },
        { text: "Type ", value: "" },
        { text: "Tier ", value: "Tier" },
        { text: "Top Level Organisation ", value: "ParentAccountID" },
      ];
    },
  },
  watch: {
    options: {
      handler() {
        store.dispatch("getIndividuals", {
          offset: (this.options.page - 1) * this.options.itemsPerPage,
          pageSize: this.options.itemsPerPage,
        });
      },
    },
  },
});
