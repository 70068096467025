




































































































































































































































































































































































import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import usersData from "../../account-data.json";
import FusionChartSvg2 from "../components/FusionChartSvg2.vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
export default Vue.extend({
  name: "PersonDashboard",
  components: {
    apexcharts: VueApexCharts,
    FusionChartSvg2,
  },
  data: function () {
    return {
      id: "",
      insightList: [],
      toggleDisable: true,
      user: [
        "John Servaes (commercial@curapharmaceuticals.com)",
        "Philippe Meskens (medical@curapharmaceuticals)",
      ],
      action: ["Recommendation", "Insight"],
      massage: "",
      users: "",
      actionData: "",
      title: "",
      cerebrum: "",
      data: usersData,
      showContent: false,
      emptyContent: true,
      trialist: true,
      dialog: false,
      emailDialog: false,
      nerveDialog: false,
      followUpDialog: false,
      sendEmail: false,
      sendveevasuggetion: false,
      viaEmail: false,
      viaSuggestion: true,
      awaredata: false,
      newarr: {},
      series: [44, 55, 41, 17],

      options: {
        labels: ["Telephone", "Email", "Conference call", "Face To Face"],
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          fontSize: "12px",
          fontWeight: 400,
          markers: {
            width: 10,
            height: 10,
            radius: 12,
          },
          itemMargin: {
            horizontal: 8,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
      },
      segment: [{ name: "Aware" }, { name: "Trialist" }],
    };
  },
  created() {
    this.getName();
  },
  computed: {
    getName() {
      return this.$route.query.name;
    },
  },
});
