import vuetify from '@/plugins/vuetify';

const themeManager = {
    state: {
        theme: 'default'
    },
    mutations: {
        setTheme(state, theme) {
            state.theme = theme
            console.log('New theme: ' + theme)
            vuetify.framework.theme.themes.dark = vuetify.userPreset.theme.themes[theme];
            vuetify.framework.theme.themes.light = vuetify.userPreset.theme.themes[theme];
          }
    },
    action: {

    },
    modules: {

    },
};
export default themeManager;