
































































































import Vue from 'vue'
export default Vue.extend({
    props: {
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      recommendationColor: "blue",
      emailSelected: true,
      types: ["Recommendation", "Insight"],
    };
  },
  computed: {
    emailColor() {
      if (this.emailSelected) {
        return "iconmenu";
      } else {
        return "chip";
      }
    },
    veevaColor() {
      if (!this.emailSelected) {
        return "iconmenu";
      } else {
        return "chip";
      }
    },
  },
})
