




























import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  name: "SegmentPopup",
  data: () => ({
    segmentName: "",
    segmentCount: "",
    mycolor: '#'+(Math.random()*0xFFFFFF<<0).toString(16),
  }),
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        (this as any).$emit("input", value);
      },
    },
  },
  methods: {
    async createTouchpoint() {
      var data = {
        segmentName: this.segmentName,
        segmentCount: this.segmentCount,
        color: this.mycolor
      };
      await axios.post(`${process.env.VUE_APP_API}/segment`, data);
      this.$emit("input", false);
      this.$emit("fetchSegment");

    },
  },
});
