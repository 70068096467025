import Vue from 'vue';
import Vuex from 'vuex';

import permissionManager from './permissionManager';
import themeManager from './themeManager';
import individualsStore from './individualsStore';
import dataUpdatesStore from './dataUpdatesStore';
import pinpointstore from './pinpointStore';
import UserInfo from './Userinfo';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    permissisionManager: permissionManager,
    themeManager: themeManager,
    individualsStore: individualsStore,
    pinpointstore: pinpointstore,
    dataUpdatesStore: dataUpdatesStore,
    UserInfo: UserInfo,
  },
  state: {
  },
  getters: {
  },
  mutations: {

  },
  actions: {
  },
});
