










import Vue from "vue";

export default Vue.extend({
  name: "SendEmail",
  computed: {
    emailtemplate(){
      return this.$store.getters.getEtemplate;
    }
    
  },
  mounted() {
    this.$store.dispatch("getEtemplate");
  },
  
});
