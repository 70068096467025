































































































































































































































































































import Vue from "vue";

export default Vue.extend({
  name: "Subscribe",
  data: () => ({
    dialog: false,
  }),
});
