import navigationItem from "@/classes/NavigationItem";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { authGuard } from "@/auth/authGuard";

import Home from "../views/Home.vue";
import AccountsOverview from "../views/AccountsOverview.vue";
import TouchPoint from "../views/TouchPoint.vue";
import DataUpdates from "../views/DataUpdates.vue";
import PersonDetails from "../views/PersonDetails.vue";
import OrganizationDashboard from "../views/OrganizationDashboard.vue";
import EmailTouchPoint from "../views/EmailTouchPoint.vue";
import Subscribe from "../views/Subscribe.vue";
import Segment from "../views/Segment.vue";
import SendEmail from "../views/SendEmail.vue";
import PersonDashboard from "../views/PersonDashboard.vue";
import Medical from "../views/Medical.vue";
import JourneySubscribe from "../views/JourneySubscribe.vue";
import UserAccount from "../views/UserAccount.vue";
import Organization from "../views/Organization.vue";
import PersonDetails2 from "../views/PersonDetails2.vue";
import JourneyOverview from "../views/JourneyOverview.vue";
import MedicalInquiry from "../views/MedicalInquiry.vue";
import EmailTouchPoint2 from "../views/backup/EmailTouchPoint2.vue";
import JourneySubscribe2 from "../views/backup/JourneySubscribe2.vue";
import MedicalSample from "../views/MedicalSample.vue";
import Switch from "../views/Switch.vue";
import Commercial from "../views/Commercial.vue";
import Marketing from "../views/Marketing.vue";
import CurrentSegment from "../views/CurrentSegment.vue";
import Connection from "../views/Connection.vue";
import Stripo from "../components/Stripo.vue";
import Slider from "../views/Slider.vue";
import Administration from "../views/Administration.vue";
import DashboardAdmin from "../views/DashboardAdmin.vue";
import DashboardViewPage from "../views/DashboardViewPage.vue";
import Map from "../views/Map.vue";
import customeractivity from "../views/NewMap.vue";
import MapPointer from "../views/MapPointer.vue";
import AdminDashboard from "../views/AdminDashboard.vue";
import NewCom from '../views/NewCom.vue';
import TenantDashboard from '../views/TenantDashboard.vue';
import AdminLogin from '../views/AdminLogin.vue';
import UserDashboard from '../views/UserDashboard.vue';

Vue.use(VueRouter);

function guardMyroute(to, from, next) {
  let isAuthenticated = false;
  if (localStorage.getItem('loggedUser'))
    isAuthenticated = true;
  else
    isAuthenticated = false;
  if (isAuthenticated) {
    next();
  }
  else {
    next('/tenantLogin');
  }
}
function IsTenant(to, from, next) {
  let isAuthenticated = false;
  if (localStorage.getItem('isTenant'))
    isAuthenticated = true;
  else
    isAuthenticated = false;
  if (isAuthenticated) {
    next();
  }
  else {
    next('/tenantLogin');
  }
}
function IsAdmin(to, from, next) {
  let isAuthenticated = false;
  if (localStorage.getItem('isAdminLogged'))
    isAuthenticated = true;
  else
    isAuthenticated = false;
  if (isAuthenticated) {
    next();
  }
  else {
    next('/adminLogin');
  }
}
const routes: Array<RouteConfig> = [

  {
    path: "/",
    name: "UserAccount",
    component: UserAccount,
    beforeEnter: authGuard,
  },
  {
    path: "/Organization",
    name: "Organization",
    component: Organization,
    beforeEnter: authGuard,
  },
  {
    path: "/details2/:id",
    name: "PersonDetails2",
    component: PersonDetails2,
    beforeEnter: authGuard,
  },
  // {
  //   path: '/dashboard',
  //   name: 'DataUpdates',
  //   component: DataUpdates,
  //   beforeEnter: authGuard
  // },
  {
    path: "/administration",
    name: "Home",
    component: Home,
    beforeEnter: authGuard,
  },
  {
    path: "/touchpoint",
    name: "TouchPoint",
    component: TouchPoint,
    beforeEnter: authGuard
  },
  {
    path: "/emailtouchpoint",
    name: "EmailTouchPoint",
    component: EmailTouchPoint,
    beforeEnter: authGuard
  },
  {
    path: "/subscribe",
    name: "Subscribe",
    component: Subscribe,
    beforeEnter: authGuard
  },
  {
    path: "/segment",
    name: "Segment",
    component: Segment,
    beforeEnter: authGuard
  },
  {
    path: "/sendemail",
    name: "SendEmail",
    component: SendEmail,
    beforeEnter: authGuard
  },
  {
    path: "/journeyoverview",
    name: "JourneyOverview",
    component: JourneyOverview,
    beforeEnter: authGuard
  },
  {
    path: "/persondashboard/:id",
    name: "PersonDashboard",
    component: PersonDashboard,
    beforeEnter: authGuard
  },
  {
    path: "/OrganizationDashboard",
    name: "OrganizationDashboard",
    component: OrganizationDashboard,
    beforeEnter: authGuard
  },
  {
    path: "/medical",
    name: "Medical",
    component: Medical,
    beforeEnter: authGuard
  },
  {
    path: "/journeysubscribe",
    name: "JourneySubscribe",
    component: JourneySubscribe,
    beforeEnter: authGuard
  },
  {
    path: "/medicalinquery",
    name: "MedicalInquiry",
    component: MedicalInquiry,
    beforeEnter: authGuard
  },
  {
    path: "/medicalsample",
    name: "MedicalSample",
    component: MedicalSample,
    beforeEnter: authGuard
  },
  {
    path: "/emailtouchpoint2",
    name: "EmailTouchPoint2",
    component: EmailTouchPoint2,
    beforeEnter: authGuard
  },
  {
    path: "/journeysubscribe2",
    name: "JourneySubscribe2",
    component: JourneySubscribe2,
    beforeEnter: authGuard
  },
  {
    path: "/switch",
    name: "Switch",
    component: Switch,
    beforeEnter: authGuard
  },
  {
    path: "/commercial",
    name: "Commercial",
    component: Commercial,
    beforeEnter: authGuard
  },
  {
    path: "/marketing",
    name: "Marketing",
    component: Marketing,
    beforeEnter: authGuard
  },
  {
    path: "/currentSegment",
    name: "CurrentSegment",
    component: CurrentSegment,
    beforeEnter: authGuard
  },
  {
    path: "/Connection",
    name: "Connection",
    component: Connection,
    beforeEnter: authGuard
  },
  {
    path: "/Stripo",
    name: "Stripo",
    component: Stripo,
    beforeEnter: authGuard
  },
  {
    path: "/slider",
    name: "slider",
    component: Slider,
    beforeEnter: authGuard
  },
  {
    path: "/personal",
    name: "Administration",
    component: Administration,
    beforeEnter: authGuard
  },
  {
    path: "/dashboardadmin",
    name: "DashboardAdmin",
    component: DashboardAdmin,
    beforeEnter: authGuard
  },
  {
    path: "/dashboardviewPage/:id",
    name: "DashboardViewPage",
    component: DashboardViewPage,
    beforeEnter: authGuard
  },
  {
    path: "/customeractivity",
    name: "customeractivity",
    component: customeractivity,
    beforeEnter: authGuard
  },
  {
    path: "/mappointer",
    name: "MapPointer",
    component: MapPointer,
    beforeEnter: authGuard
  },
  {
    path: "/map",
    name: "Map",
    component: Map,
    beforeEnter: authGuard
  },
  {
    path: "/admindashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
    beforeEnter: IsAdmin
  },
  {
    path: "/tenantLogin",
    name: "NewCom",
    component: NewCom,
  },
  {
    path: "/tenantDashboard",
    name: "TenantDashboard",
    component: TenantDashboard,
    beforeEnter: IsTenant
  },
  {
    path: "/adminLogin",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/userDashboard",
    component: UserDashboard,
    name: "UserDashboard",
    beforeEnter: guardMyroute,
  },
];
const route2: Array<RouteConfig> = [
  {
    path: "/",
    name: "customeractivity",
    component: customeractivity,
    beforeEnter: authGuard
  },
];

const route3: Array<RouteConfig> = [
  {
    path: "/",
    name: "NewCom",
    component: NewCom,
  },
];


const userdata = JSON.parse(localStorage.getItem("user"));
const firstKey = userdata ? userdata["https://engagementmanager.digital/user_authorization"] : "";

const data = () => {
  if (userdata?.email == "natasja@splintt.nl.dj") {
    return route2
  } else if (firstKey?.user_metadata?.user_type == 'cutom') {
    return route3
  } else {
    return routes
  }
}

const router = new VueRouter({
  mode: 'history',
  routes: data(),
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
});



export default router;
