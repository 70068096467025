
































































import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
export default Vue.extend({
  name: "GanttChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    value: Boolean,
    width: String,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "rangeBar",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          type: "datetime",
        },
      },
      toucpoint: [],
      date: "",
      masterDataId: "",
    };
  },
  computed: {
    show: {
      get() {
        this.getJourneyData();
        return this.value;
      },
      set(value: boolean) {
        (this as any).$emit("input", value);
      },
    },
  },
  methods: {
    async getJourneyData() {
      axios
        .get(`${process.env.VUE_APP_API}/journeyData?masterDataId=` + this.masterDataId)
        .then((response) => {

          this.toucpoint = response.data.data;

          var data = response.data.data.map((item: any) => {
            return {
              x: item.name,
              y: [
                new Date(item.startDate).getTime(),
                new Date(item.expectedCompletionDate).getTime(),
              ],
              fillColor: "#008FFB",
            };
          });
          this.series = [{ data: data }];
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
        
    },
    convertDate(date) {
      var startDate = date.split("-");
      startDate = startDate.reverse();
      startDate = startDate.join(".");
      return startDate;
    },
  },
  created() {
    var parameters = this.$route.query;
    this.masterDataId = parameters.masterDataId;
  },
});
