
import { pinpointService } from "@/remote/PinpointService"

const state: State = {
    unaware: [],
    aware:[],
    brandaverse:[],
    firsttimeprescriber:[],
    frequentprescriber:[],
    brandadvocate:[],
    html:[],
    followup:[],
}

const pinpointstore = {
    state,
    getters: {
        getUnaware(state: State): unknown {
            return state.unaware
        },
        getAware(state: State): unknown {
            return state.aware
        },
        getBrandAverse(state: State): unknown {
            return state.brandaverse
        },
        getFirstTimePrescriber(state: State): unknown {
            return state.firsttimeprescriber
        },
        getFrequentPrescriber(state: State): unknown {
            return state.frequentprescriber
        },
        getBrandAdvocate(state: State): unknown {
            return state.brandadvocate
        },
        getEtemplate(state: State): unknown {
            return state.html
        },
        getFollowUpTemplate(state: State): unknown{
            return state.followup
        }

    },
    mutations: {
        setUnaware(state: State, unaware) {
            state.unaware = unaware
        },
        setAware(state: State, aware) {
            state.aware = aware
        },
        setBrandAverse(state: State, brandaverse) {
            state.brandaverse = brandaverse
        },
        setFirstTimePrescriber(state: State, firsttimeprescriber) {
            state.firsttimeprescriber = firsttimeprescriber
        },
        setFrequentPrescriber(state: State, frequentprescriber) {
            state.frequentprescriber = frequentprescriber
        },
        setBrandAdvocate(state: State, brandadvocate) {
            state.brandadvocate = brandadvocate
        },
        setEmailTemplate(state: State, html) {
            state.html = html
        },
        setFollowUpTemplate(state: State, followup) {
            state.followup = followup
        },
    },
    actions: {
        getUnaware(context, { id }) {
            pinpointService.getSegments(id)
                .then(response => {
                    context.commit('setUnaware', response);
                }
                )
        },
        getAware(context, { id }) {
            pinpointService.getSegments(id)
                .then(response => {
                    context.commit('setAware', response);
                }
                )
        },
        getBrandAverse(context, { id }) {
            pinpointService.getSegments(id)
                .then(response => {
                    context.commit('setBrandAverse', response);
                }
                )
        },
        getFirstTimePrescriber(context, { id }) {
            pinpointService.getSegments(id)
                .then(response => {
                    context.commit('setFirstTimePrescriber', response);
                }
                )
        },
        getFrequentPrescriber(context, { id }) {
            pinpointService.getSegments(id)
                .then(response => {
                    context.commit('setFrequentPrescriber', response);
                }
                )
        },
        getBrandAdvocate(context, { id }) {
            pinpointService.getSegments(id)
                .then(response => {
                    context.commit('setBrandAdvocate', response);
                }
                )
        },
        getEtemplate(context) {
            pinpointService.getEmailTemplate()
                .then(response => {
                    context.commit('setEmailTemplate', response);
                }
                )
        },
        getFollowtemplate(context) {
            pinpointService.getFollowUpTemplate()
                .then(response => {
                    context.commit('setFollowUpTemplate', response);
                }
                )
        },
        
    }
}
export default pinpointstore

interface State {
    unaware: any;
    aware:any;
    brandaverse:any;
    firsttimeprescriber:any;
    frequentprescriber:any;
    brandadvocate:any;
    html:any;
    followup:any;
}