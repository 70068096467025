












































































































import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  name: "Header",
  data: () => ({
    showProfileCard: false,
    showCard: false,
    categoryId: "",
    profile: {
      email: "steve.harvey@company.com",
      picture: "@/assets/user.jpg",
      logo: "@/assets/siteLogo.svg",
      name: "",
      firstletter: "",
      pharma: "",
      travel: "",
      finance: "",
    },
  }),
  methods: {
    adminClicked() {
      return "";
    },
    clickOutsideWrokplace() {
      this.showCard = false;
    },
    onClickOutside() {
      this.showProfileCard = false;
    },
    profileCliked() {
      this.showProfileCard = !this.showProfileCard;
    },
    logout() {
      this.$auth.logout();
      localStorage.removeItem("user");
      localStorage.removeItem("tokenInfo");
      this.$router.push({ path: "/" });
    },
    showThemeCard() {
      this.showCard = true;
      this.showProfileCard = false;
    },
    async fetchWorkplaceValue() {
      var res = await axios.get(`${process.env.VUE_APP_API}/category`);
      this.categoryId = res.data[0]._id;
      this.pharma = res.data[0].pharma;
      this.travel = res.data[0].travel;
      this.finance = res.data[0].finance;
    },
    async changeSwitch() {
      var data = {
        pharma: this.pharma,
        travel: this.travel,
        finance: this.finance,
      };
      await axios.put(
        `${process.env.VUE_APP_API}/category/` + this.categoryId,
        data
      );
      localStorage.setItem("themeChanger", JSON.stringify(data));
      window.location.reload();
    },
    goAdministration() {
      this.$router.push("/personal");
      this.showProfileCard = false;
    },
  },
  created() {
    this.fetchWorkplaceValue();
  },
  mounted() {
    setTimeout(() => {
      var data = this.getInfo;
      this.profile.name = data?.name;
      this.profile.firstletter = data?.name
        ? data.name.charAt(0).toUpperCase()
        : "A";
    }, 2500);
  },
  computed: {
    getRole(): any {
      return this.$store.getters.getRole;
    },
    getInfo(): any {
      return this.$store.getters.getInfo;
    },
    getCountry() {
      return this.$store.getters.getCountry;
    },
  },
});
