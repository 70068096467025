<template>
  <v-container>
    <v-row class="my-2">
      <v-col>
        <h2>Commercial</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card class="mb-5 text-center" v-if="getRole == 'commercial'">
            <h2 class="py-5">My Recommendation / Insight</h2>
          <v-data-table
            :items="users"
            :headers="headers"
            :items-per-page="10"
            item-key="name"
            :footer-props="{
              prevIcon: 'chevron_left',
              nextIcon: 'chevron_right',
              'items-per-page-options': [10, 20, 30, 40, 50],
            }"
          >
          <template v-slot:[`item.date`]="{ item }">
              
            <span>{{formatDate(item.date)}}</span>
        </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="pa-5">
        <v-row class="mb-5">
            <v-col class="text-center">
                <h2>My Team Performance</h2>
            </v-col>
        </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="3">
          <v-card class="pa-3">
            <v-row>
              <v-col cols="auto" class="text-center">
                <div class="enquiry-icon-area d-flex align-center pa-5">
                  <img src="../assets/cyclePlan.png" alt="" />
                </div>
              </v-col>
              <v-col class="d-flex align-center">
                <v-row class="no-gutters">
                  <v-col cols="12">
                    <div class="enquiry-content-text text-center">
                      <h2>58</h2>
                      <span>Cycle Plans</span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card class="pa-3">
            <v-row>
              <v-col cols="auto" class="text-center">
                <div class="enquiry-icon-area d-flex align-center pa-5">
                  <img src="../assets/interactions.png" alt="" />
                </div>
              </v-col>
              <v-col class="d-flex align-center">
                <v-row class="no-gutters">
                  <v-col cols="12">
                    <div class="enquiry-content-text text-center">
                      <h2>1000</h2>
                      <span>Total Interactions</span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card class="pa-3">
            <v-row>
              <v-col cols="auto" class="text-center">
                <div class="enquiry-icon-area d-flex align-center pa-5">
                  <v-icon class="display-3" color="#555767">
                    mdi-test-tube
                  </v-icon>
                </div>
              </v-col>
              <v-col class="d-flex align-center">
                <v-row class="no-gutters">
                  <v-col cols="12">
                    <div class="enquiry-content-text text-center">
                      <h2>753</h2>
                      <span>Interactions in target</span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mb-5">
        <v-card>
          <v-col>
            <v-row class="no-gutters">
              <v-col cols="auto" class="text-center first-nougut">
                <span>In Target</span>
                <apexcharts
                  width="100%"
                  type="donut"
                  :options="options"
                  :series="series"
                ></apexcharts>
              </v-col>
              <v-col cols="auto" class="text-center">
                <span>Out of Target </span>
                <apexcharts
                  width="100%"
                  type="donut"
                  :options="options"
                  :series="series"
                ></apexcharts>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card class="pa-3">
            <p class="text-center m-0">Sander De Groot</p>
            <v-row>
              <v-col cols="12" class="bar-area">
                <p>Progress current period</p>
                <v-row class="no-gutters d-flex align-center">
                  <v-col>
                    <div class="bar">
                      <div class="bar-radius bar1"></div>
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <span class="ml-2">68%</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="bar-area">
                <p>Progress 12 month goal</p>
                <v-row class="no-gutters d-flex align-center">
                  <v-col>
                    <div class="bar">
                      <div class="bar-radius bar2"></div>
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <span class="ml-2">23%</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="no-gutters mt-2">
              <v-col class="text-center first-nougut">
                <span>In Target</span>
                <apexcharts
                  width="100%"
                  type="donut"
                  :options="options"
                  :series="series"
                ></apexcharts>
              </v-col>
              <v-col class="text-center first-nougut">
                <span>Out of Target </span>
                <apexcharts
                  width="100%"
                  type="donut"
                  :options="options"
                  :series="series"
                ></apexcharts>
              </v-col>
            </v-row>
            <div class="details-indicator mt-8">
              <div class="indicato">
                <span>View Details </span
                ><i data-v-09ce7e72="" class="bi bi-chevron-right"></i>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="pa-3">
            <p class="text-center m-0">Sabine Lecroix</p>
            <v-row>
              <v-col cols="12" class="bar-area">
                <p>Progress current period</p>
                <v-row class="no-gutters d-flex align-center">
                  <v-col>
                    <div class="bar">
                      <div class="bar-radius bar1"></div>
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <span class="ml-2">68%</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="bar-area">
                <p>Progress 12 month goal</p>
                <v-row class="no-gutters d-flex align-center">
                  <v-col>
                    <div class="bar">
                      <div class="bar-radius bar2"></div>
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <span class="ml-2">23%</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="no-gutters mt-2">
              <v-col class="text-center first-nougut">
                <span>In Target</span>
                <apexcharts
                  width="100%"
                  type="donut"
                  :options="options"
                  :series="series"
                ></apexcharts>
              </v-col>
              <v-col class="text-center first-nougut">
                <span>Out of Target</span>
                <apexcharts
                  width="100%"
                  type="donut"
                  :options="options"
                  :series="series"
                ></apexcharts>
              </v-col>
            </v-row>
            <div class="details-indicator mt-8">
              <div class="indicato">
                <span>View Details </span
                ><i data-v-09ce7e72="" class="bi bi-chevron-right"></i>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-card class="pa-3 d-flex align-center flex-column">
                <p class="text-center m-0">
                  Email consents for HCP in cycle plan
                </p>
                <apexchart
                  type="bar"
                  height="150"
                  width="400"
                  :options="chartOptionsBar"
                  :series="seriesBar"
                ></apexchart>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card class="pa-3 d-flex align-center flex-column">
                <p class="text-center m-0">
                  Email consents for HCP in cycle plan
                </p>
                <apexchart
                  type="bar"
                  height="150"
                  width="400"
                  :options="chartOptionsBar"
                  :series="seriesBar2"
                ></apexchart>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Vue from "vue";
import axios from "axios";
import moment from "moment";
export default Vue.extend({
  name: "Commercial",
  components: {
    apexchart: VueApexCharts,
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      users: [],
      series: [44, 55, 41, 17],
      options: {
        labels: ["Telephone", "Email", "Conference call", "Face To Face"],
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          fontSize: "12px",
          fontWeight: 400,
          markers: {
            width: 10,
            height: 10,
            radius: 12,
          },
          itemMargin: {
            horizontal: 8,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
      },

      seriesBar: [
        {
          data: [7, 10, 4, 13],
        },
      ],
      seriesBar2: [
        {
          data: [16, 12, 12, 12],
        },
      ],
      chartOptionsBar: {
        chart: {
          type: "bar",
          height: 10,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        xaxis: {
          categories: ["P1", "P2", "P3", "No Tier"],
          labels: {
            formatter: function () {
              return null;
            },
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    async getAllInsights() {
      var res = await axios.get(`${process.env.VUE_APP_API}/insights/get-all`);
      this.users = res.data;
    },
   formatDate(date) {
      return moment(String(date)).format("DD/MM/YYYY");
    },
  },
  computed: {
    headers() {
      return [
        { text: "Account", value: "username" },
        { text: "Type", value: "type" },
        { text: "massage", value: "massage" },
        { text: "Data Completed", value: "date" },
      ];
    },
    getRole() {
      return this.$store.getters.getRole;
    },
  },
  created() {
    this.getAllInsights();
  },
});
</script>

<style scoped>
.enquiry-icon-area {
  height: 100%;
}
.enquiry-icon-area {
  background: #edf1f4;
  border-radius: 4px;
}
.enquiry-icon-area img {
  max-width: 60px;
}
.enquiry-content-status {
  line-height: 10px;
  justify-content: end;
}
.enquiry-content-status i {
  font-size: 13px;
}
.enquiry-content-status span {
  font-size: 14px;
}
.enquiry-content-text h1 {
  line-height: 35px;
}
.enquiry-content-text span {
  font-size: 14px;
}
.right-arrow {
  padding: 5px 8px;
  background: #d3e3ef;
  color: #417b9d;
  border-radius: 3px;
  font-size: 14px;
  display: inline-block;
  text-align: right;
}
.bar-control {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 10px;
  margin-right: 10px;
}
.bar-control p {
  margin: 0;
  font-size: 14px;
}
.bar-identity {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  background-color: #f08185;
  display: inline-block;
  margin-right: 5px;
}
.first-nougut {
  height: 100%;
}
.bar-area span {
  font-size: 14px;
}
.bar-area p {
  margin: 0px;
  color: #a5999e;
  font-size: 13px;
}
.bar {
  width: 100%;
  height: 4px;
  background: #c8cde6;
}
.bar-radius {
  height: 4px;
  background: #5e35b1;
}
.bar1 {
  width: 68%;
}
.bar2 {
  width: 23%;
}
.details-indicator {
  display: flex;
  justify-content: end;
}
.indicato {
  padding: 5px 8px;
  background: #d3e3ef;
  color: #417b9d;
  border-radius: 3px;
  font-size: 14px;
  display: inline-block;
  text-align: right;
}
</style>