<template>
  <v-dialog v-model="show" max-width="300px">
    <v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-icon @click="show = false" class="pointer">mdi-window-close</v-icon>
      </v-card-actions>
      <div class="journeyTitle mb-1 pointer">
        <span>Aws Pinpoint</span>
        <label class="switch">
          <input type="checkbox" v-model="AwsSwicth" @change="changeSwitch()" />
          <span class="slider"></span>
        </label>
      </div>
      <div class="journeyTitle mb-1 pointer">
        <span>SaleForce</span>
        <label class="switch">
          <input type="checkbox" v-model="salesForce" @change="changeSwitch()" />
          <span class="slider"></span>
        </label>
      </div>
      <div class="journeyTitle mb-1 pointer">
        <span>Veeva</span>
        <label class="switch">
          <input type="checkbox" v-model="veeva" @change="changeSwitch()" />
          <span class="slider"></span>
        </label>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  name: "SwtichconnectionPopup",
  props: {
    value: Boolean,
    touchpointdata: [],
  },
  computed: {
    show: {
      get() {
        this.fecthSwitchValue();
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      AwsSwicth: false,
      salesForce: "",
      veeva: "",
    };
  },
  methods: {
    async changeSwitch() {
      var data = {
        isActivate: this.AwsSwicth,
      };
      await axios.put(`${process.env.VUE_APP_API}/touchpointTiles/` + this.touchpointdata._id, data);
      if (this.AwsSwicth) {
        var data = {
          status: "Published",
        };
        await axios.put(`${process.env.VUE_APP_API}/journeyData/update/` + this.touchpointdata.touchpointName, data);
      } else if (!this.AwsSwicth) {
        var data = {
          status: "Error",
        };
        await axios.put(`${process.env.VUE_APP_API}/journeyData/update/` + this.touchpointdata.touchpointName, data);
      }
    },
    fecthSwitchValue() {
      this.AwsSwicth = this.touchpointdata.isActivate;
      
    },
  },
});
</script>
<style scoped>
.journeyTitle {
  display: flex;
  justify-content: space-between;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dbdbdb;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 2px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 2px;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
