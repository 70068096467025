
















































































































































import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  name: "CurrentSegment",
  data: () => ({
    journeyData: "",
    journyId: "",
    AlljourneyData: "",
    parentData: "",
    parentId: "",
    journeyName: "",
    journeyDetails: "",
  }),
  computed: {
    emailtemplate() {
      return this.$store.getters.getEtemplate;
    },
  },
  mounted() {
    var oldData = localStorage.getItem("Query");
    this.journeyDetails = JSON.parse(oldData);
    window.onpopstate = function () {
      window.location.reload();
    };
  },
  created() {
    this.$store.dispatch("getEtemplate");
    this.getData();
  },
  methods: {
    backTojourney() {
      this.$router.push(
        "/emailtouchpoint2?masterDataId=" +
          this.journeyDetails.masterDataId +
          "&journeyName=" +
          this.journeyDetails.journeyName
      );
      // localStorage.removeItem("Query");
    },
    findData(jsonArr) {
      var journy = this.journyId;
      var currentItem = this.journeyData.Parent;
      var newdata = [];
      var parentData = [];
      function recurse(node) {
        for (var i = 0, count = node.children.length; i < count; i++) {
          if (journy == node.children[i].Parent) {
            newdata.push(node.children[i]);
          }
          if (currentItem == node.children[i].Parent) {
            parentData.push(node.children[i]);
          }
          recurse(node.children[i]);
        }
      }
      recurse({ children: jsonArr });
      return { newdata, parentData };
    },

    convert(array) {
      var map = {};

      for (var i = 0; i < array.length; i++) {
        var obj = array[i];
        obj.children = [];

        map[obj.id] = obj;

        var parent = obj.Parent || "-";
        if (!map[parent]) {
          map[parent] = {
            children: [],
          };
        }
        map[parent].children.push(obj);
      }

      return this.findData(map["-"].children);
    },
    getData() {
      var parameters = this.$route.query;
      this.journyId = parameters.journyId;
      axios
        .get(`${process.env.VUE_APP_API}/journeyData/` + this.journyId)
        .then((response) => {
          this.journeyData = response.data;
          this.getAllData(response.data);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    getAllData(data) {
      axios
        .get(
          `${process.env.VUE_APP_API}/journeyData?masterDataId=` +
            data.masterDataId +
            "&_sort=id&_order=asc"
        )
        .then((response) => {
          var parameters = this.$route.query;
          this.parentId = parameters.parent;

          this.AlljourneyData = this.convert(response.data).newdata;
          this.parentData = this.convert(response.data).parentData;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    showJourney(id) {
      this.$router.push(
        "/currentSegment?parent=" + this.parentId + "&journyId=" + id
      );
      location.reload();
    },
    nextJourney() {
      var newdata = parseInt(this.parentId) + 1;
      this.$router.push(
        "/currentSegment?parent=" +
          newdata +
          "&journyId=" +
          this.parentData[newdata].id
      );
      location.reload();
    },
    prevJourney() {
      var newdata = parseInt(this.parentId) - 1;
      this.$router.push(
        "/currentSegment?parent=" +
          newdata +
          "&journyId=" +
          this.parentData[newdata].id
      );
      location.reload();
    },
    goTouchpoint() {
      this.$router.push("/TouchPoint");
    },
  },
});
