<template>
  <v-card>
    <v-row class="no-gutters">
      <v-card class="d-flex flex-wrap justify-space-around py-2">
        <div class="navigation-top mb-1">
          <i class="bi bi-envelope"></i>
          <span @click="dialogClick()">Email</span>
        </div>
        <v-dialog v-model="dialog" max-width="500">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Email To"
                      type="text"
                      required
                      hide-details
                      v-model="email"
                    >
                      <template #append>
                        <v-btn
                          color="#dae7f2"
                          class="blue--text lighten-2 mb-1 mr-2"
                          value="Bcc"
                          small
                          depressed
                        >
                          BCC
                        </v-btn>
                        <v-btn
                          color="#dae7f2"
                          class="blue--text lighten-2 mb-1"
                          value="Bcc"
                          small
                          depressed
                        >
                          CC
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="subject"
                      label="Subject"
                      type="text"
                      required
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="content"
                      auto-grow
                      label="Type Content...."
                      rows="3"
                      row-height="20"
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                min-width="150"
                color="#0d60a5"
                dark
                flat
                depressed
                class="btn"
                @click="sendEmail()"
              >
                Send Now
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <div class="navigation-top mb-1">
          <i class="bi bi-clipboard-data"></i><span>Survey</span>
        </div>
        <div class="navigation-top mb-1">
          <i class="bi bi-journal-richtext"></i><span>Webpage</span>
        </div>
        <div class="navigation-top mb-1">
          <i class="bi bi-person-square"></i><span>Customer Journey</span>
        </div>
        <div class="navigation-top mb-1">
          <i class="bi bi-chat-left-text-fill"></i><span>Veeva Suggestion</span>
        </div>
        <div class="navigation-top mb-1">
          <i class="bi bi-chat-left-text-fill"></i><span>Document Viewer</span>
        </div>
        <div class="navigation-top mb-1"><i class="bi bi-headset"></i><span>Call center</span></div>
      </v-card>
    </v-row>
    <v-row class="no-gutters px-3">
      <v-col cols="12">
        <div class="segment-list py-2">
          <i class="bi bi-three-dots mr-1"></i><span>Segment :</span>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="bold">
                Brand Averse<i class="bi bi-chevron-down ml-1 ex-small"></i>
              </span>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in items" :key="index">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-row class="no-gutters px-3 pb-3">
      <v-col>
        <v-form>
          <v-text-field
            placeholder="Search..."
            :elevation="n - 1"
            solo
            light
            append-icon="mdi-magnify"
            dense
            class="serch-filed"
            flat
            outlined
            height="35"
            hide-details
          ></v-text-field>
        </v-form>
      </v-col>
      <v-col cols="auto">
        <v-btn depressed outlined height="100%" small class="ml-2">
          <v-icon color="#cccccc"> mdi-filter-outline </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="no-gutters">
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Status</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Nerve Connect Email</td>
                <td class="green--text">Published</td>
                <td>
                  <v-dialog transition="dialog-bottom-transition" max-width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn outlined small fab color="blue" v-bind="attrs" v-on="on">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                      <v-card class="pa-4">
                        <v-card-text>
                          <h3>Cerebrum New Advocate</h3>
                        </v-card-text>
                        <v-divider></v-divider>
                        <div id="template" v-html="emailtemplate.data.HtmlPart"></div>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            min-width="150"
                            color="#d0e4f2"
                            dark
                            flat
                            depressed
                            class="btn blue--text darken-1"
                            @click="dialog.value = false + showNerve()"
                          >
                            Send
                            <v-icon> mdi-chevron-right </v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </td>
              </tr>
              <tr>
                <td>Visit Follow up - Cerebrum</td>
                <td class="green--text">Published</td>
                <td>
                  <v-btn outlined small fab color="blue">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>Sample Request Form</td>
                <td class="green--text">Published</td>
                <td>
                  <v-btn outlined small fab color="blue">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>Medical Enquiry form</td>
                <td class="green--text">Published</td>
                <td>
                  <v-btn outlined small fab color="blue">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>Presentation Viewer</td>
                <td class="green--text">Published</td>
                <td>
                  <v-btn outlined small fab color="blue">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>Landing Page Nerve Connect</td>
                <td class="green--text">Published</td>
                <td>
                  <v-btn outlined small fab color="blue">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Vue from 'vue'; 
export default Vue.extend({
  name: "JourneyPopup",
  methods: {
    dialogClick() {
      this.$emit("showDialog");
    },
    showNerve() {
      this.action = !this.action;
      this.nervehide = !this.nervehide;
    },
  },
  computed: {
    emailtemplate() {
      return this.$store.getters.getEtemplate;
    },
  },
  mounted() {
    this.$store.dispatch("getEtemplate");
  },
});
</script>
