




































































import mapboxgl from "mapbox-gl";
import data from "../../pointer.json";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
export default {
  name: "customeractivity",
  data() {
    return {
      fruits: 0,
      ticksLabels: [".2s", ".4s", ".6s", ".8s", "1s"],
      time: 2000,
      map: "",
      pointerData: data,
      showframe: true,
      interval: 0,
      name: "",
      totalUniqueUsers: "",
      totalLogins: "",
      timeframe: [],

      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Total Logins Of Users",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {},
      },
    };
  },
  components: {
    apexchart: VueApexCharts,
  },
  methods: {
    sliderInput(e) {
      if (e == 0) {
        e = 200;
      } else if (e == 1) {
        e = 400;
      } else if (e == 2) {
        e = 600;
      } else if (e == 3) {
        e = 800;
      } else {
        e = 1000;
      }
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        this.createPointer();
      }, e);
    },
    showTimeframe() {
      this.showframe = !this.showframe;
    },
    createPointer() {
      if (this.map) {
        // const element = document.getElementsByClassName("marker");
        // for (let item of element) {
        //   item.innerHTML = "";
        //   item.removeAttribute("style");
        // }
        const elements = document.getElementsByClassName("marker");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
        for (const marker of data.features) {
          const el = document.createElement("div");
          const randomNumber = Math.floor(Math.random() * 23);
          const htmlNumbers = Number(
            marker.customData.timeframe[randomNumber].usersOnline
          );
          //   const htmlNumbers = Math.floor(1000 + Math.random() * 3500);
          //    const htmlNumbers = Math.floor(Math.random() * marker.customData.timeframe[].usersOnline);
          el.className = "marker";
          el.style.borderRadius = "50%";
          el.style.color = "#fff";
          el.style.textAlign = "center";
          if (htmlNumbers >= 1 && htmlNumbers <= 100) {
            el.style.lineHeight = "40px";
            el.style.backgroundColor = "#ff6aa0";
            el.style.width = "40px";
            el.style.height = "40px";
          } else if (htmlNumbers >= 100 && htmlNumbers <= 200) {
            el.style.backgroundColor = "#298be7";
            el.style.lineHeight = "50px";
            el.style.width = "50px";
            el.style.height = "50px";
          } else if (htmlNumbers >= 200 && htmlNumbers <= 400) {
            el.style.backgroundColor = "#8a69e6";
            el.style.width = "60px";
            el.style.height = "60px";
            el.style.lineHeight = "60px";
          } else {
            el.style.backgroundColor = "#8a69e6";
            el.style.width = "70px";
            el.style.height = "70px";
            el.style.lineHeight = "70px";
          }
          el.style.backgroundSize = "100%";
          el.innerHTML = String(htmlNumbers);
          el.addEventListener("click", () => {
            this.name = marker?.customData?.name;
            this.totalUniqueUsers = marker?.customData?.totalUniqueUsers;
            this.totalLogins = htmlNumbers;
            this.timeframe = marker?.customData?.timeframe;
            this.lineChart();
          });

          // Add markers to the map.
          new mapboxgl.Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .addTo(this.map);
        }
      }
    },
    formatDate(date) {
      return moment(String(date)).format("YYYY-MM-DD hh:mm");
    },
    lineChart() {
      let arr = [];
      var data = this.timeframe.map((item) => {
        return arr.push(Number(item.usersOnline));
      });
      var data2 = this.timeframe.map((item) => {
        return item.usersOnline;
      });
      this.series = [
        {
          name: "Desktop",
          data: arr,
        },
      ];
      this.chartOptions.xaxis = {
        categories: data2,
      };
      this.formatDate();
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
  mounted() {
    this.sliderInput(1000);

    mapboxgl.accessToken =
      "pk.eyJ1IjoianNtYjEyMSIsImEiOiJjbGFrdmxjdXkwNDM3M3ZvMDhpZnV6cG9oIn0.vKBIP9qrTUV2esADVho1Hw";
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v12",
      center: [5.0252925, 51.5868055],
      zoom: 8,
    });

    this.createPointer();

    // Fullscreen
    this.map.addControl(new mapboxgl.FullscreenControl());
  },
};
