




















import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  name: "AlljourneyList",
  data: () => ({
    journeys: "",
    journeyDataName: "",
    masterDataId: "",
  }),
  props: {
    value: Boolean,
    parentId: String,
    getFun: { type: Function },
    masterData:Object
  },
  computed: {
    show: {
      get() {
        this.getJourneys()
        return this.value;
      },
      set(value:boolean) {
        (this as any).$emit("input", value);
      },
    },
  },
  methods: {
    async getJourneys() {
      var response = await axios.get(`${process.env.VUE_APP_API}/masterData/get-all`);
      this.journeys = response.data.filter((item) => {
        if (item.journeyName != this.journeyDataName) {
          if(this.masterData.data.category == item.category){
            return item;
           }
        }
      });
     
    },
    closeJourneylistpopup() {
      this.$emit("input", false);
    },
    async journeytileCreate(item) {
      var data = {
        name: item.journeyName,
        masterDataId: this.masterDataId.toString(),
        Parent: this.parentId,
        displayType: "Journey",
        type: "email",
        status: "Published",
        journeyId:item._id,
        journeyName:item.journeyName,
        journeyCreated :true
      };
      await axios.post(`${process.env.VUE_APP_API}/journeyData`, data);
      this.$emit("input", false);
      window.location.reload();
    },
  },
  created() {
    var parameters = this.$route.query;
    this.journeyDataName = parameters.journeyName;
    this.masterDataId = parameters.masterDataId;
  },
});
