<template>
  <div :class="' relative'">
    <div class="child-box" v-for="(child, index) in items" :key="index">
      <!-- {{ child.mainIndex ? child.mainIndex = child[index - 1]+ 1 : child.mainIndex = 0}} -->
      <Tree
        :class="'other_box'"
        v-if="child.children"
        :item="child"
        @showEmailpopup="clickitempopup($event)"
        :getData="getData"
        :access="access"
      />
      <v-card
        :class="'pa-1 mb-1 registration-box progresss first_' + index"
        v-else
      >
        <v-row>
          <v-col class="box-icon-color box-back dark-blue">
            <span v-if="item && item.displayType" class="small text-muted">{{
              item.displayType
            }}</span>
          </v-col>
        </v-row>
        <v-row class="no-gutters">
          <div class="plus" @click="showEmailpopup(item)" v-if="access.canEdit">
            <i class="bi bi-plus"></i>
          </div>
          <div class="plus" v-if="!access.canEdit">
            <i class="bi bi-plus"></i>
          </div>

          <div class="arrow-nerve">
            <i class="bi bi-play-fill"></i>
          </div>
          <v-col>
            <h4 class="main-title">
              {{ item.name ? item.name : "" }}
            </h4>
          </v-col>
        </v-row>
        <v-row class="no-gutters box-Content">
          <v-col class="box-icon-color dark-blue">
            <span v-if="item && item.displayType" class="small text-muted">{{
              item.displayType
            }}</span>
          </v-col>
          <v-col cols="auto">
            <span
              class="action-btn action-btn1 mr-1 pointer"
              @click="deleteJourny(item)"
            >
              <i class="bi bi-trash"></i>
            </span>
            <span class="action-btn action-btn2 pointer">
              <i class="bi bi-pencil-square"></i>
            </span>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "TreeItem",
  components: {
    Tree: () => import("./Tree.vue"),
  },
  props: {
    items: { type: Array },
    getData: { type: Function },
    access: { type: Object },
  },
  data: () => ({
    showProfileCard: false,
  }),
  methods: {
    showEmailpopup(event) {
      this.$emit("showEmailpopup", event);
    },
    clickitempopup(event) {
      this.$emit("showEmailpopup", event);
      this.$emit("clickitempopup", event);
    },
    async deleteJourny(event) {
      await axios.delete(`${process.env.VUE_APP_API}/journeyData/` + event._id);
      this.getData();
    },
  },
});
</script>
<style></style>
